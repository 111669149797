import React, {useEffect} from 'react';
import TitleDetailComponent from "../../components/title_detail/TitleDetailComponent";
import {
    en0,
    en1,
    en2,
    en3,
    en4,
    en5,
    en6,
    en7,
    en8,
    en9,
    en10,
    en11,
    en12,
    en13,
    en14,
    en15,
    en16 } from "./constant";


const Term_And_Condition_en: React.FC = () => {

    useEffect(() => {
        document.title = 'AT Points Term and Condition'
    }, []);

    return (            
        <>
            {[
                en0,
                en1,
                en2,
                en3,
                en4,
                en5,
                en6,
                en7,
                en8,
                en9,
                en10,
                en11,
                en12,
                en13,
                en14,
                en15,
                en16,
            ].map((it, index)=> <TitleDetailComponent key={index} title={it.title} details={it.details} style_index={it.style_index} />)}
        </>
    )
}

export default Term_And_Condition_en