import React from 'react';

const Loading: React.FC = () => {

    const screenWidth = window.innerWidth 

    return (
        <>
            {screenWidth > 450 && 
                <div style={{textAlign: "center", margin: "50px 0 80px 0"}} >
                    <img src={require('../images/atp_logo.png').default} alt="atp_logo" width="40%" height="40%" />
                </div>
            }
            {screenWidth < 450 && 
                <div style={{textAlign: "center", margin: "50px 0 80px 0"}} >
                    <img src={require('../images/atp_logo.png').default} alt="atp_logo" width="85%" height="85%" />
                </div>
            }
            {screenWidth > 450 && 
                <>
                    <p style={{fontSize: "35px", fontWeight: 'bolder', textAlign: "center", margin: "0 0 10px 0", color: "#314084"}}> Page is Loading... </p>
                    <p style={{fontSize: "32px", fontWeight: 'bolder', textAlign: "center", margin: "0 0 10px 0", color: "#314084"}}> 頁面現正跳轉... </p>
                    <p style={{fontSize: "32px", fontWeight: 'bolder', textAlign: "center", margin: "0 0 10px 0", color: "#314084"}}> 页面现正跳转... </p>
                </>
            }
            {screenWidth < 450 && 
                <>
                    <p style={{fontSize: "28px", fontWeight: 'bolder', textAlign: "center", margin: "0 0 10px 0", color: "#314084"}}> Page is Loading... </p>
                    <p style={{fontSize: "26px", fontWeight: 'bolder', textAlign: "center", margin: "0 0 10px 0", color: "#314084"}}> 頁面現正跳轉... </p>
                    <p style={{fontSize: "26px", fontWeight: 'bolder', textAlign: "center", margin: "0 0 10px 0", color: "#314084"}}> 页面现正跳转... </p>
                </>
            }
            
        </>
    )
}

export default Loading
