import React, {useEffect} from 'react';
import TitleDetailComponent from "../../components/title_detail/TitleDetailComponent";
import {haagen_dazs_tc} from "../third_party_page/constant";


const Event_Page_roadshow_atp_member_2021_06: React.FC = () => {

    useEffect(() => {
        document.title = "AT Points Day"
    }, []);

    return (            
        <div style={{margin: "25px 25px 25px 25px"}} >
            <div style={{textAlign: "center"}}><b>AT Points 会员庆第1赏：分享爱摄影比赛</b></div>
            <div><b>参与方法：</b></div>

            <ol style={{ padding: "0px 16px 0px 16px"}}>
                {
                    [
                        '下载AT Points 手机应用程式及登记成为会员',
                        '追踪及赞好 𝐀𝐓𝐏𝐨𝐢𝐧𝐭𝐬 Facebook @at.points，上载相片及标签@at.points',
                        '相片及帖子撰写符合「Share Love 分ㆍ享爱 」主题',
                        '于帖子加上主题标签 #ATPoints #手機 APP #coupon #會員積分 #ATPoints 會員慶 #分享愛的時刻 #1 分鐘瘋狂掃 #FRESH 新鮮生活 #培養消費好習慣 #給你不一樣的消費體驗',
                        '帖子及相片设定为公开，并发布到个人Facebook'
                    ].map(
                        (it, index) => <li>{it}</li>
                    )
                }
            </ol>

            <dl>
                <dd style={{ margin: 0 }}>* 参加者发布作品数量不限，但每个AT Points 帐户只有一次得奖机会。</dd>
            </dl>


            <div>AT Points 将选出合资格而最能传达比赛主题「分ㆍ享爱」 的作品为得奖作品。</div>
            <div>最佳作品：得奖者可参与FRESH新鲜生活日出康城店指定范围内免费疯狂扫货一分钟之活动 （名额 2 位）。</div>
            <div>优秀作品：可获 100 AT Points积分 （名额 8 位）</div>
            <div>优异作品：可获 20 AT Points积分（名额 10 位）。</div>

            <br/>
            <br/>

            <div style={{textAlign: "center"}}>【 AT Points 会员庆第 1 赏：分享爱摄影比赛 】条款及细则</div>

            <ol style={{ padding: "0px 16px 0px 16px"}}>
                {
                    [
                        '亚洲卓盛有限公司（下称「AT Points」）为是次摄影比赛（下称「本活动」的主办单位）；参加者于其个人Facebook所上传之帖子及相片为参赛材料（下称「作品」）。',
                        '本活动截止时间为2021年6月27日晚上11时59分（香港时间），逾期参加将被取消参加资格。',
                        '参加活动前，请细阅以下条款及细则。参加者参与该活动，即代表已确认阅读、明白，并同意遵守所有条款及细则。',
                        '参加者必须现居香港及持有香港身份证，以及年满 18 岁或以上的 Facebook 用户。',
                        '参加者须以 Facebook 个人帐户参加比赛，其个人 Facebook 帖子须设定为公开分享。',
                        '参加者须完成指定参加步骤，方为合资格作品：'
                    ].map(
                        (it, index) => <li>{it}</li>
                    )
                }

                <dl>
                    <dd style={{ margin: 0 }}>a. 下载 AT Points 手机应用程式及登记成为会员</dd>
                    <dd style={{ margin: 0 }}>b. 追踪及赞好 <b>ATPoints</b> Facebook @at.points，上载相片及标签@at.points</dd>
                    <dd style={{ margin: 0 }}>c. 相片及帖子撰写符合「Share Love 分ㆍ享爱 」主题</dd>
                    <dd style={{ margin: 0 }}>d. 于帖子加上主题标签 #ATPoints #手機 APP #coupon #會員積分 #ATPoints 會員慶 #分享愛的時刻 #1 分鐘瘋狂掃 #FRESH 新鮮生活 #培養消費好習慣 #給你不一樣的消費體驗</dd>
                    <dd style={{ margin: 0 }}>e. 帖子及相片设定为公开，并发布到个人 Facebook</dd>
                </dl>

                {
                    [
                        '参加者发布作品数量不限，但每个 AT Points 帐户只有一次得奖机会。',
                        'AT Points 将选出合资格而最能传达比赛主题「分ㆍ享爱」的作品为得奖作品。'
                    ].map(
                        (it, index) => <li>{it}</li>
                    )
                }

                <dl>
                    <dd style={{ margin: 0 }}>a. 最佳作品：得奖者可参与 FRESH 新鲜生活日出康城店指定范围内免费疯狂扫货一分钟之活动（名额 2 位）；</dd>
                    <dd style={{ margin: 0 }}>b. 优秀作品：可获 100 AT Points 积分 （名额 8 位）；</dd>
                    <dd style={{ margin: 0 }}>c. 优异作品：可获 20 AT Points 积分（名额 10 位）。</dd>
                </dl>

                {
                    [
                        '比赛结果以主办单位决定为准，参加者不得异议，比赛亦不设上诉机制。',
                        '最佳作品得奖者，可参与 2021 年 8 月 27 日于 FRESH 新鲜生活日出康城店指定 范围内免费疯狂扫货 1 分钟之活动。',
                        '作品须为参加者专属拥有的原创作品及版权所有人，并无侵犯任何第三方的版权或任何其他权利，包括（但不限于）版权、商标、专利、商业秘密、私隐或其他个人或专利权，否则参加者会被取消参加资格。',
                        '参加者应保证已获作品中出现的所有人士批准，并确保该人物亦同意 AT Points 在全球范围内有权使用该影象。',
                        '如因参加者作品引起任何版权纠纷或其它第三方侵权纠纷，一切后果及赔偿由参 赛者承担，AT Points 对此不承担任何责任。',
                        '凡发布作品至个人 Facebook 及标签@at.points 的所有人士即被视为申请参加本活 动，并同意接受本条款及细则约束。参加者乃自愿参与本活动并同意自行承担所有风险及责任。',
                        '所有参加者的作品一经提交，即视为同意授权AT Points 及其合作商户对参加作品作编辑之权利及以任何方式、任何时间、任何媒体复制、出版或刊登所有参加照片之内容、拍摄资料及参加者Facebook 用户名称的权利（包括但不限于上载互联网、供公众欣赏及下载及作推广或展览之权利），而无须另征参加者同意或向其支付版权费用。参加者确认及保证其已获得在其作品中出现的可辨认人士的许可。如上述的保证不完整及不正确，参加者须承担所有责任并向本会弥偿其所有损失。',
                        '如发现参加者上载之参赛作品违反以下任何一项细则，AT Points 保留要求删除作品之权利，同时参加者将会被取消参加资格而不作另行通知，包括：',

                    ].map(
                        (it, index) => <li>{it}</li>
                    )
                }

                <dl>
                    <dd style={{ margin: 0 }}>a. 作品不可违反可适用的法律规定，不含有个人政治内容；不含有任何个人隐私内容；不含有淫秽、色情、赌博、迷信、恐怖、暴力、侮辱、诋毁、诽谤、挑衅、攻击性、造谣中伤的内容；不含有涉及民族、种族、宗教、性别歧视的内容；不含有虚假或者引人误解的内容；不含影响其他品牌的不适当内容</dd>
                    <dd style={{ margin: 0 }}>b. 作品不符合 AT Points 品牌形象的内容</dd>

                </dl>

                {
                    [
                        '结果公布：AT Points 会透过 Facebook Inbox 通知得奖者，得奖者须于 24 小时内回覆及提供所需领奖资料，否则当弃权论。得奖资格将由其他参赛者补上。',
                        '得奖者须于指定时间内出席活动才可获得免费购物一分钟的资格。得奖者必须为本人，并于出席活动时出示有效的身份证明文件以作核实。如得奖者无法于指定日期及时间内到指定地点参加活动；或因任何未能控制之因素或所递交之个人资料有错漏或不正确，以导致AT Points 未能联络或核实得奖者身份，即视为放弃得奖资格，其得奖资格将被取消并由其他参赛者补上，而恕不作另行通知。得奖者亦不得要求补发或任何形式的补偿。对于任何未能于期限内领取的奖品，AT Points 保留最终处理的权利。',
                        '如发现参加者以空号或假帐户、外挂程式或其他非正式途径参加此活动，或以任何程式扰乱或操控活动，AT Points 将取消该参赛者之参加资格，而不作另行通知。',
                        'AT Points 员工均不能参与本活动，以示公允。',
                        '有关征集个人资料，AT Points 所收集之个人资料，将有机会用作品牌推广活动或等其他用途，不论得奖与否。',
                        '换领者需带同领奖通知，亲自到指定地点领取奖品。换领者必须出示有效香港身份证，于 AT Points 指定日期及地点换领。对于任何未能于期限内领取的奖品，AT Points 保留最终处理的权利。',
                        'AT Points 积分将直接转到得奖者的 AT Points 钱包中，所有奖品均不可退换或兑换现金，亦不能更换、退款、转让及转售、更换其他奖品及/或款式替代。',
                        '1 AT Points 等于港币 $1.2 ，兑换 AT Points 后，不可兑换现金及不设退款。',
                        '奖品不得转让，亦不可兑换其他礼品。本公司有权以其他礼品代替而不作事先通知。',
                        '本活动与 Facebook 不相关，并非由 Facebook 赞助、支持或管理。',
                        '如有任何因电脑、网路、电话、技术或不可归责于AT Points 之事由，而使参加者所寄出、登录之资料或使AT Points 寄出之通知，有迟延、遗失、错误、无法辨识或毁损之情况，AT Points 均不负任何法律责任。',
                        '所有与活动有关之日期及时间（包括但不限于参加活动之日期及时间、参加者回覆资料之日期及时间）均以 AT Points Facebook 专页系统报告为准及受制于本公司的最终决定。',
                        'AT Points 有权暂停、修订或更改本活动内容并可随时修订或更改此条款及细则，而恕不另行通知，亦毋须为此承担任何责任。',
                        '本活动当中及其后因而产生之任何知识产权及其相关精神权利及邻接权，均由 AT Points 于所有媒介全球绝对永久独家拥有。',
                        '凡参加本活动者，即被视为已细阅并同意接受此条款及细则约束。如有违反，AT Points 有权取消其参加或得奖资格，并对于任何破坏本活动之行为保留追究权利。',
                        '如有任何争议，AT Points 保留取消参加者参加活动的资格及/或向参加者追究法律责任之权利，AT Points 保留一切有关行使之最终决定权。'
                    ].map(
                        (it, index) => <li>{it}</li>
                    )
                }
            </ol>

        </div>
    )
}

export default Event_Page_roadshow_atp_member_2021_06