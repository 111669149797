/**
 * Path?d={distributor}&m={merchant}&i={info}&c={code}
 */
import React, {useEffect, useState} from 'react';
import '../../css/Template3.css';
import TitleDetail from "../title_detail/TitleDetailProps";
import TitleDetailComponent from "../title_detail/TitleDetailComponent";
import CopyToClipboard from 'react-copy-to-clipboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'reactstrap';
import {toast} from "../ToastManager";
import STHRiceDumpling from '../title_detail/STHRiceDumpling';
import RGRiceDumpling from '../title_detail/RGRiceDumpling';
import ICGSFRiceDumpling from '../title_detail/ICGSFRiceDumpling';

const QRCode = require('qrcode.react');
const BarCode = require('react-barcode');

const Template3: React.FC<Template3Props> = (props) => {

    const screenSize = window.innerWidth

    const [state, setState] = useState<boolean>(false);

    useEffect(() => {
        document.title = props.productTitle
    })

    return (
        <>
            {
                props.isShowAtpLogo &&
                    <>
                        <img src={require('../../images/coupon_logo.png').default} alt="en_version_image" className="logoImg"
                             width={screenSize < 450 ? "80px" : "125px"} style={{margin: "20px 0 0 0"}}/>
                        <img src={require('../../images/background.png').default} alt="en_version_image" className="bgImg"
                             width={screenSize < 450 ? "82px" : "150px"}/>
                    </>
            }

            {props.merchantLogoSrc &&
            <img src={props.merchantLogoSrc}
                 alt="en_version_image"
                 height={screenSize < 450 ? "100px" : "180px"}/>}
            {props.distributerLogoSrc &&
            <img src={props.distributerLogoSrc}
                 alt="en_version_image"
                 height={screenSize < 450 ? "100px" : "180px"}/>}

            {
                props.qrcode && <div className="qrCode-box">
                    <QRCode value={props.qrcode} style={screenSize < 450 ? { width: "250px", height: "250px"} : { width: "450px", height: "450px"}}/>
                    <div>{props.qrcode}</div>
                </div>
            }

            {
                props.qrcode2 && <div className="qrCode-box">
                    <QRCode value={props.qrcode2} style={screenSize < 450 ? { width: "250px", height: "250px"} : { width: "450px", height: "450px"}}/>
                    <div>{props.qrcode2}</div>
                </div>
            }
            {
                props.barcode && <div className="qrCode-box">
                    <BarCode value={props.barcode} style={screenSize < 450 ? { width: "250px", height: "150"} : { width: "450px", height: "250"}}/>
                </div>
            }

            {
                props.plainText&& <div className="qrCode-box">
                    <h3> 券碼 Voucher Code</h3>
                    <h1>{ props.plainText }</h1>
                    <CopyToClipboard
                        text={props.plainText}
                        onCopy={() =>{
                            toast.show({
                                title: '',
                                content: "成功複製 Copy successfully",
                                duration: 1600,
                            })
                            setState(true)
                        }}>
                        <Button variant="info">點擊以複製券碼 Click me to copy</Button>
                    </CopyToClipboard>
                </div>
            }

            <div className="text-box">
                <div className="text"> <b>產品名稱: </b> {props.productTitle} </div>
                {
                    props.moneyValue != "" &&
                    <div className="text"> <b>價值: </b> HKD{props.moneyValue} </div>
                }
                
                {
                    props.redemptionPeriod != "" && props.redemptionPeriod &&
                        <div>
                            <div className="text"> <b>兌換日期: </b> {props.redemptionPeriod} </div>
                            <div className="text"> <b>Redemption Period</b> {props.redemptionPeriodEn}</div>   
                        </div>
                }
                
                {
                    props.expiryDate != "" &&
                    <div className="text"> <b>到期日: </b> {props.expiryDate} </div>
                }
                
            </div>
            {props.showGodiva24Pcs && 
                <div>
                    <div>
                        <div className="text">親愛的顧客，</div>
                        <div className="text">聖誕快樂和新年快樂! 我們衷心感謝您對星展的支持。我們特意送上Godiva片裝巧克力禮盒24片裝電子換領禮券。請小心妥善保存及保管你的電子禮券，如有遺失將不獲補發。</div>
                    </div>
                    <div>
                        <div className="text">Dear Customer,</div>
                        <div className="text">Merry Christmas and Happy New Year! Thank you for your continued support to DBS. We are pleased to enclose herewith a Godiva Assorted Chocolate Carré Collection 24 pcs e-Gift voucher. Please keep your voucher properly. This eGift Voucher is invalid and not replaceable if lost or expired.</div>
                    </div>
                </div>
            }

            <div className="text-box">
                <div className="text"> 親愛的顧客， </div>
                <div className="text"> 請小心妥善保存及保管你的電子禮券，如有遺失將不獲補發。 </div>
            </div>
            <div className="text-box">
                <div className="text"> Dear Customer, </div>
                <div className="text"> Please keep your eGift Voucher properly and confidential. This eGift Voucher is invalid and not replaceable if lost. </div>
            </div>
            {props.showAtpContact && <div>
                <div className="text-box">
                    <div className="text"> 此優惠券由AsiaTop Loyalty Limited提供。 </div>
                    <div className="text"> 如有任何查詢，請聯絡AsiaTop Loyalty Limited (Tel: 2322 8350) </div>
                </div>
                <div className="text-box">
                    <div className="text"> The voucher is provided by AsiaTop Loyalty Limited. </div>
                    <div className="text"> For any enquiries, please contact AsiaTop Loyalty Limited (Tel: 2322 8350) </div>
                </div>
            </div>}

            {
                props.isRgRiceDumpling &&
                < RGRiceDumpling />
            }

            {
                props.isIcgsfRiceDumpling &&
                <ICGSFRiceDumpling />
            }

            {
                props.isShowSTHDescription &&
                <>
                    {/* Product details TC Version */}
                    <div className="sth-product"> 
                        <text className="sth-product-title"> 產品介紹 </text>
                        <text className="sth-product-content"> 以「日本北海道元貝」製作而成，配合浙江金華火腿、油潤鹹蛋黃、厚肉花菇、明爐叉燒及湖南寸三蓮等，鮮香味濃。 </text>
                    </div>

                    {/* Product details TC Version */}
                    <div className="sth-product"> 
                        <text className="sth-product-title"> Product Description </text>
                        <text className="sth-product-content"> The dumpling is richly stuffed with whole conpoy from Hokkaido and traditional festive delicacies, including Jinhua ham, salted egg yolk, mushroom, roast pork and Hunan lotus seed. </text>
                    </div>
                </>
            }

            {props.description?.map((it, index)=> <TitleDetailComponent key={index} title={it.title} details={it.details} style_index={it.style_index} />)}

            {props.locations &&
                props.locations?.map((it, index)=> <TitleDetailComponent key={index} title={it.title} details={it.details} style_index={it.style_index}/>)
            }

            { 
                props.isRiceDumplingDescription && <STHRiceDumpling />
            }

            {/* {props.showImg && props.showImg.map((image: string) => <img src={require('../../images/atp_orix_userguide/chi_1.png').default} />)} */}

            {props.showOrixUserGuide && 
            <div className="userguide-div">
                <img src={require('../../images/atp_orix_userguide/chi_1.jpg').default} className='userguide' width={screenSize < 450 ? "60%" : "30%"}/>
                <img src={require('../../images/atp_orix_userguide/chi_2.jpg').default} className='userguide' width={screenSize < 450 ? "60%" : "30%"}/>
                <img src={require('../../images/atp_orix_userguide/chi_3.jpg').default} className='userguide' width={screenSize < 450 ? "60%" : "30%"}/>
                <img src={require('../../images/atp_orix_userguide/chi_4.jpg').default} className='userguide' width={screenSize < 450 ? "60%" : "30%"}/>
                <img src={require('../../images/atp_orix_userguide/chi_5.jpg').default} className='userguide' width={screenSize < 450 ? "60%" : "30%"}/>
                <img src={require('../../images/atp_orix_userguide/chi_6.jpg').default} className='userguide' width={screenSize < 450 ? "60%" : "30%"}/>
                
                <img src={require('../../images/atp_orix_userguide/eng_1.jpg').default} className='userguide' width={screenSize < 450 ? "60%" : "30%"}/>
                <img src={require('../../images/atp_orix_userguide/eng_2.jpg').default} className='userguide' width={screenSize < 450 ? "60%" : "30%"}/>
                <img src={require('../../images/atp_orix_userguide/eng_3.jpg').default} className='userguide' width={screenSize < 450 ? "60%" : "30%"}/>
                <img src={require('../../images/atp_orix_userguide/eng_4.jpg').default} className='userguide' width={screenSize < 450 ? "60%" : "30%"}/>
                <img src={require('../../images/atp_orix_userguide/eng_5.jpg').default} className='userguide' width={screenSize < 450 ? "60%" : "30%"}/>
                <img src={require('../../images/atp_orix_userguide/eng_6.jpg').default} className='userguide' width={screenSize < 450 ? "60%" : "30%"}/>
            
            
            </div>}

            {
                props.showOrixUserGuide2 &&
                <div className="userguide-div">
                    <img src={require('../../images/atp_orix_userguide2/chi_1.png').default} className='userguide' width={screenSize < 450 ? "60%" : "30%"}/>
                    <img src={require('../../images/atp_orix_userguide2/chi_2.png').default} className='userguide' width={screenSize < 450 ? "60%" : "30%"}/>
                    <img src={require('../../images/atp_orix_userguide2/chi_3.png').default} className='userguide' width={screenSize < 450 ? "60%" : "30%"}/>
                    <img src={require('../../images/atp_orix_userguide2/chi_4.png').default} className='userguide' width={screenSize < 450 ? "60%" : "30%"}/>
                    <img src={require('../../images/atp_orix_userguide2/chi_5.png').default} className='userguide' width={screenSize < 450 ? "60%" : "30%"}/>
                    <img src={require('../../images/atp_orix_userguide2/chi_6.png').default} className='userguide' width={screenSize < 450 ? "60%" : "30%"}/>

                </div>
            }

            {props.showHktvmallManual &&
            <div>
                <div className="text"> 換領及使用電子購物禮券步驟:</div>
                <div className="text">How to redeem and use an e-Gift Voucher:</div>
                <img src={require('../../images/hktvmall_manual.png').default} />
            </div>
                
            
            
            }
        </>
    )
};

interface Template3Props {
    productTitle: string,
    qrcode?: string,
    qrcode2?: string,
    barcode?: string,
    plainText?: string,
    distributerLogoSrc?: string,
    merchantLogoSrc?: string,
    isShowAtpLogo: boolean,
    moneyValue?: string,
    expiryDate?: string,
    description?: TitleDetail[],
    isShowSTHDescription: boolean,
    isRiceDumplingDescription: boolean,
    // showImg?: string[]
    showOrixUserGuide?: boolean,
    showOrixUserGuide2?: boolean,
    showAtpContact?: boolean,
    showHktvmallManual?: boolean,
    redemptionPeriod?: string,
    redemptionPeriodEn?: string,
    isRgRiceDumpling?: boolean,
    isIcgsfRiceDumpling?: boolean,
    showGodiva24Pcs?: boolean,
    locations?: TitleDetail[]
};

export default Template3;