import React, {useEffect, useState} from 'react';
import '../../css/BroadwayCoupon.css';
import TitleDetail from "../title_detail/TitleDetailProps";
import TitleDetailComponent from "../title_detail/TitleDetailComponent";
import CopyToClipboard from 'react-copy-to-clipboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'reactstrap';
import {toast} from "../ToastManager";

const QRCode = require('qrcode.react');

const BroadwayCoupon: React.FC<BroadwayCouponProps> = (props) => {

    const screenSize = window.innerWidth

    const [state, setState] = useState<boolean>(false);

    useEffect(() => {
        document.title = props.productTitle
    })

    return (
        <div className="bg">
            <div>
                <img src={require('../../images/broadway_header.jpg').default} className="header"></img>
            </div>

            <div className="instructions-and-qr-row">
                <div className="left-instructions">
                    <div className="instructions medium-words">
                        <p className="bold">How to use e-Gift Card</p>
                        <p>1. Print out your e-Gift Card</p>
                        <p>2. Present your e-Gift Card printout to store for redemption</p>
                    </div>
                    <div className="instructions medium-words">
                        <p className="bold">如何使用e-Gift Card</p>
                        <p>1. 列印此e-Gift Card</p>
                        <p>2. 在店內購物時出示此e-Gift Card 作兌換</p>
                    </div>
                </div>
                <div className="right-qr">
                    <p className="bold large-words">{props.moneyValue}</p>
                    <div className="qr-box">
                        <QRCode value={props.qrcode} style={screenSize < 450 ? { width: "180px", height: "180px"} : { width: "200px", height: "200px"}}/>
                    </div>
                    <p className="bold medium-words">卡號Card No: {props.cardNo}</p>
                    <p className="bold medium-words">PIN: {props.pin}</p>
                    <p className="bold medium-words">到期日 Expiry Date: {props.expiryDate}</p>
                </div>
            </div>

            <div className="tnc">
                <p className="bold small-words">Terms and conditions 條款及細則</p>
                <p className="small-words">1) This card will be immediately activated when it is purchased, and it will expire after 12 months, any remaining balance shall be automatically forfeited upon the expiry date. 2) The gift card value can be used to purchase ticket and food & beverages of Broadway cinemas only. 3) No change or cash refunds will be given. 4) Broadway Circuit will not replace or reimburse the value of your card if it is lost, stolen or damaged. 5) Broadway Circuit reserve the right to amend these terms and conditions from time to time.</p>
                <p className="small-words">(1) 此卡將於購買時立即啟動，並於12個月後失效，期滿後所有餘額將會自動註銷 (2) 此卡只適用於購買百老匯戲院戲票及食品 (3) 此卡不得退換及兌換現金 (4) 此卡如被遺失、被竊取或損壞，百老匯院線將不會作出更換或任何賠償 (5) 百老匯院線保留隨時更改本條款及細則之權利。</p>
            </div>

            <div className="footer">
                <div className="bold medium-words footer-words">Card Balance &#38; expiry call 查詢餘額及到期日: (852) 2898 8611</div>
            </div>
        </div>
    )
};

interface BroadwayCouponProps {
    productTitle: string,
    qrcode: string,
    moneyValue: string,
    expiryDate: string,
    cardNo: string,
    pin: string
};

export default BroadwayCoupon;