import React from "react";
import TitleDetail, {IndexStyle} from "./TitleDetailProps";
import {Switch} from "react-router-dom";

const TitleDetailComponent: React.FC<TitleDetail> = (props) => {
    return (
        <>
            <div className="text-box">
                {props.title}
            </div>

            <div className="text-box">
                {
                    props.details?.map(
                        (it, index) =>
                            it === '\n' ? <br /> : 
                            <div key={index} className="text"> {getIndex(index, props.style_index)}   {it}</div>
                    )
                }
            </div>
        </>
    )
}

function getIndex(index: number, mIndexStyle: IndexStyle = IndexStyle.numbering): string {
    switch (mIndexStyle) {
        case IndexStyle.bullets:
            return "•"
        case IndexStyle.numbering:
            return `${index+1}.`
        default:
            return ""
    }
}

export default TitleDetailComponent;
