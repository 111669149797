import React from 'react';
import '../../css/STHRiceDumpling.css';

const STHRiceDumping: React.FC = () => {
    return (
        <>  
            {/* CN Version Product Description */}
            <div className="riceDumpling-productDescriptionBox"> 
                <text className="riceDumpling-productDescription-title"> 產品介紹 </text>
                <text className="riceDumpling-productDescription-content"> 以「日本北海道元貝」製作而成，配合浙江金華火腿、油潤鹹蛋黃、厚肉花菇、明爐叉燒及湖南寸三蓮等，鮮香味濃。 </text>
            </div>

            {/* EN Version Product Description */}
            <div className="riceDumpling-productDescriptionBox"> 
                <text className="riceDumpling-productDescription-title"> Product Description </text>
                <text className="riceDumpling-productDescription-content"> The dumpling is richly stuffed with whole conpoy from Hokkaido and traditional festive delicacies, including Jinhua ham, salted egg yolk, mushroom, roast pork and Hunan lotus seed. </text>
            </div>

            {/* CN Version T&C */}
            <div className="riceDumpling-headerBox">
                <div className="riceDumpling-header"> 特級極品糭套裝/尊貴極品糭套裝/極品家鄉糭/桂花紅荳糭/極品元貝金腿裹蒸糉/江南極品糭 </div>
                <div className="riceDumpling-header"> 顧客憑券可於 2022 年 5 月 19 日至 5 月 29 日期間 (農曆四月十九日至四月廿九日) </div>
                <div className="riceDumpling-header"> 到全線香港聖安娜餅屋換領指定極品糭 </div>
            </div>
            
            <div className="riceDumpling-contentBox-1">
                <div className="riceDumpling-content-text-1"> 2022年5月30日至6月1日期間 (農曆五月初一至五月初三) </div>
                <div className="riceDumpling-content-text-1"> 顧客請到下列指定 27 間聖安娜餅屋換領指定極品糭: </div>
            </div>
            
            <div className="riceDumpling-branch">
                <div className='riceDumpling-branchBox'> 
                    <div className='riceDumpling-branchList-1'> 
                        <text className="riceDumpling-items-1"> 香港區 </text>
                    </div>
                    <div className='riceDumpling-branchList-2'> 
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 中環 </text>
                            <text className="riceDumpling-items-3"> 皇后大道中 138 號威享大廈地下 1 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 灣仔 </text>
                            <text className="riceDumpling-items-3"> 灣仔道 87 號地下 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 銅鑼灣 </text>
                            <text className="riceDumpling-items-3"> 蘭芳道 15 號地下 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 柴灣 </text>
                            <text className="riceDumpling-items-3"> 新翠商場 1 樓 113B 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 香港仔 </text>
                            <text className="riceDumpling-items-3"> 東勝道 11 號地下 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 北角 </text>
                            <text className="riceDumpling-items-3"> 皇冠大廈地下 22 號舖 </text>
                        </div>
                    </div>
                </div>

                <div className='riceDumpling-branchBox'> 
                    <div className='riceDumpling-branchList-1'> 
                        <text className="riceDumpling-items-1"> 九龍區 </text>
                    </div>
                    <div className='riceDumpling-branchList-2'> 
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 紅磡 </text>
                            <text className="riceDumpling-items-3"> 黃埔花園第9期 G16 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 佐敦 </text>
                            <text className="riceDumpling-items-3"> 佐敦道百誠大廈地下 A1 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 旺角 </text>
                            <text className="riceDumpling-items-3"> 登打士街鴻輝大廈地下 10 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 深水埗 </text>
                            <text className="riceDumpling-items-3"> 寶熙苑商場 10 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 荔枝角 </text>
                            <text className="riceDumpling-items-3"> 長沙灣道 796 號地舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 樂富 </text>
                            <text className="riceDumpling-items-3"> 樂富廣場 2 樓 2118 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 觀塘 </text>
                            <text className="riceDumpling-items-3"> 輔仁街 10-24 號輔成樓地下 4 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 藍田 </text>
                            <text className="riceDumpling-items-3"> 匯景廣場 3 樓 301 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 牛頭角 </text>
                            <text className="riceDumpling-items-3"> 淘大商場地下 G3B 號舖 </text>
                        </div>
                    </div>
                </div>

                <div className='riceDumpling-branchBox'> 
                    <div className='riceDumpling-branchList-1'> 
                        <text className="riceDumpling-items-1"> 新界區 </text>
                    </div>
                    <div className='riceDumpling-branchList-2'> 
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 將軍澳 </text>
                            <text className="riceDumpling-items-3"> 新都城中心二期 1 樓 1144 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 將軍澳 </text>
                            <text className="riceDumpling-items-3"> 將軍澳港鐵站 20 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 荃灣 </text>
                            <text className="riceDumpling-items-3"> 綠楊坊 P5 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 荃灣 </text>
                            <text className="riceDumpling-items-3"> 愉景新城第二層 2024 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 大圍 </text>
                            <text className="riceDumpling-items-3"> 大圍港鐵站 TAW33 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 沙田 </text>
                            <text className="riceDumpling-items-3"> 沙田廣場3樓26A號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 大埔 </text>
                            <text className="riceDumpling-items-3"> 大埔超級城 D 區 1 樓 610-611 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 上水 </text>
                            <text className="riceDumpling-items-3"> 上水港鐵站 SHS 14 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 屯門 </text>
                            <text className="riceDumpling-items-3"> 屯隆街 1 號錦薈坊 1 樓 103B 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 元朗 </text>
                            <text className="riceDumpling-items-3"> 裕景坊 14-32 號地下 8 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 天水圍 </text>
                            <text className="riceDumpling-items-3"> 天澤商場地下 12 號舖 </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> 馬鞍山 </text>
                            <text className="riceDumpling-items-3"> 新港城中心 2 樓 2002-3 號舖 </text>
                        </div>
                    </div>
                </div>
            </div>

            <div className="riceDumpling-usedTermsHeader"> 使用細則: </div>
            <div className="riceDumpling-usedTermsContent">
                <div className="riceDumpling-usedTermsContent-text"> - 此券不可兌換現金、其他產品或禮券，如有任何爭議。聖安娜餅屋保留此券之最後解釋權 </div>
                <div className="riceDumpling-usedTermsContent-text"> - 查詢熱線: 29916020 (辦公時間: 星期一至五上午 9:00 至下午 12:30 及下午 2:00 至下午 6:00, 星期六、星期日及公眾假涓休息 </div>
            </div>

            {/* EN Version T&C */}
            <div className="riceDumpling-headerBox">
                <div className="riceDumpling-header"> Premium Rice Dumpling Gift Set/Supreme Rice Dumpling Gift Set/Deluxe Rice Dumpling With Scallop & Chinese Ham/ </div>
                <div className="riceDumpling-header"> Jiang Nan Deluxe Rice Dumpling/Deluxe Rice Dumpling With Roasted Pork & Salty Egg Yolk/Cassia Flower Red Bean Paste Rice Dumpling </div>
                <div className="riceDumpling-header"> This coupon can redeem the corresponding item from May-19-2022 until May-29-2022 at all stores of Saint Honore in Hong Kong. </div>
            </div>
            
            <div className="riceDumpling-contentBox-1">
                <div className="riceDumpling-content-text-1"> From May-30-2022 to Jun-1-2022, please redeem at the following stores of Saint Honore: </div>
            </div>
            
            <div className="riceDumpling-branch">
                <div className='riceDumpling-branchBox'> 
                    <div className='riceDumpling-branchList-1'> 
                        <text className="riceDumpling-items-1"> HK Island </text>
                    </div>
                    <div className='riceDumpling-branchList-2'> 
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Central </text>
                            <text className="riceDumpling-items-3"> Shop 1, G/F, V.Heun Building 138 Queen’s Road C. </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Wanchai </text>
                            <text className="riceDumpling-items-3"> G/F, 87 Wanchai Road </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Causeway Bay </text>
                            <text className="riceDumpling-items-3"> G/F, 15 Lan Fong Road </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Chai Wan </text>
                            <text className="riceDumpling-items-3"> Shop 113B, 1/F, New Jade Shopping Arcade </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Aberdeen </text>
                            <text className="riceDumpling-items-3"> G/F, 11 Tung Sing Road </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> North Point </text>
                            <text className="riceDumpling-items-3"> Shop 22, G/F, Coronet Court </text>
                        </div>
                    </div>
                </div>

                <div className='riceDumpling-branchBox'> 
                    <div className='riceDumpling-branchList-1'> 
                        <text className="riceDumpling-items-1"> Kowloon </text>
                    </div>
                    <div className='riceDumpling-branchList-2'> 
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Hunghom </text>
                            <text className="riceDumpling-items-3"> Shop G16, Ph 9 Whampoa Garden </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Jordan </text>
                            <text className="riceDumpling-items-3"> G/F, 41 Pilkem Street </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Mongkok </text>
                            <text className="riceDumpling-items-3"> G/F, Hung Fai Building, 10 Dundas Street </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Shamshuipo </text>
                            <text className="riceDumpling-items-3"> Shop 10, Po Hei Court Shopping Arcade </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Lai Chi Kok </text>
                            <text className="riceDumpling-items-3"> 796 Cheung Sha Wan Road </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Lok Fu </text>
                            <text className="riceDumpling-items-3"> Shop 2118, 2/F, Lok Fu Place </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Kwun Tong </text>
                            <text className="riceDumpling-items-3"> Shop No. 4, 10-24 Fu Yan Street </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Lam Tin </text>
                            <text className="riceDumpling-items-3"> Shop 301, 3/F, Sceneway Plaza </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Ngau Tau Kok </text>
                            <text className="riceDumpling-items-3"> Shop G3B, G/F, Amoy Plaza </text>
                        </div>
                    </div>
                </div>

                <div className='riceDumpling-branchBox'> 
                    <div className='riceDumpling-branchList-1'> 
                        <text className="riceDumpling-items-1"> N.T. </text>
                    </div>
                    <div className='riceDumpling-branchList-2'> 
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Tseung Kwan O </text>
                            <text className="riceDumpling-items-3"> Shop 1114, 1/F, Ph II, Metrocity Plaza </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Tseung Kwan O </text>
                            <text className="riceDumpling-items-3"> Shop 20, Tseung Kwan O MTR Station </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Tsuen Wan </text>
                            <text className="riceDumpling-items-3"> Shop P5, Luk Yeung Galleria </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Tsuen Wan </text>
                            <text className="riceDumpling-items-3"> Shop 2024, Level 2, Discovery Park </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Tai Wai </text>
                            <text className="riceDumpling-items-3"> Shop 33, TAW Tai Wai MTR Station </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Shatin </text>
                            <text className="riceDumpling-items-3"> Shop 26A, 3/F, Shatin Plaza </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Tai Po </text>
                            <text className="riceDumpling-items-3"> Shop 610-611, 1/F, Zone D, Tai Po Mega Mall </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Sheung Shui </text>
                            <text className="riceDumpling-items-3"> Shop 14, Sheung Shui MTR Station </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Tuen Mun </text>
                            <text className="riceDumpling-items-3"> Shop 103B, 1/F, K-Point, 1 Tuen Lung Street </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Yuen Long </text>
                            <text className="riceDumpling-items-3"> Shop 8, G/F, 14-32 Yu King Square </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Tin Shui Wai </text>
                            <text className="riceDumpling-items-3"> Shop 12, G /F, Tin Chak Shopping Centre </text>
                        </div>
                        <div className="riceDumpling-branchAddList">
                            <text className="riceDumpling-items-2"> Ma On Shan </text>
                            <text className="riceDumpling-items-3"> Shop 2002-3, 2/F, MOS Town </text>
                        </div>
                    </div>
                </div>
            </div>

            <div className="riceDumpling-usedTermsHeader"> Terms and Condition: </div>
            <div className="riceDumpling-usedTermsContent">
                <div className="riceDumpling-usedTermsContent-text"> - This coupon cannot be exchanged for cash, other products or coupon. For any argument, Saint Honore Cake Shop Ltd. reserves the right to explain. </div>
                <div className="riceDumpling-usedTermsContent-text"> - Enquiry hotline: 29916020 (Office Hour: Monday to Friday 9:00 am to 12:30 pm & 2:00 pm to 6:00 pm, Saturday, Sunday and public holiday: off) </div>
            </div>        
        </>
    )
}

export default STHRiceDumping