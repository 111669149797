import TitleDetail, {IndexStyle} from "../../components/title_detail/TitleDetailProps";

export const haagen_dazs_tc: TitleDetail = {

    title: "條款及細則:",
    details: [
        "憑券可於Häagen-Dazs™香港各分店購買Häagen-Dazs™產品作HK$50現金使用。換領分店地址如有任何更改，恕不另行通知。",
        "本券只適用於購買正價貨品。",
        "本券不適用於購買現金券、結婚禮券及任何禮券。並不適用於購買月餅、火鍋及所有節日性或推廣產品。",
        "如兌換產品之價值高於此券，差額需由顧客支付。",
        "此電子券僅限兌換一次，一經兌換即不可重複使用。",
        "消費者務必善盡保管責任，若轉讓本券予他人使用，即表示同意本券所載之注意事項。 若本券因已被使用而造成消費者本人無法兌換，由消費者自行負責。",
        "此電子券具有效使用限期，請于指定日期前進行換領，逾期恕無法使用。",
        "此電子券不能重複使用，如有企圖欺詐者，將從刑事起訴。",
        "無需列印此劵,商戶只接受電子版本。",
        "此電子券不可轉售。",
        "如此電子券遺失或過期，General Mills Hong Kong Limited恕不負責及恕不補發。",
        "如有任何爭議，General Mills Hong Kong Limited保留最終決議權。",
        "如有任何查詢，請與AT Points聯絡:",
        "聯絡電話: 2322 8350",
        "WhatsApp: +852 9311 6084",
        "辦公時間: 星期一至五:  10am - 6pm (公眾假期例外)"
    ]
}

export const haagen_dazs_en: TitleDetail = {

    title: "Terms & Conditions",
    details: [
        "This gift e-voucher entitles the holder to purchase Häagen-Dazs™ products valued at HK$50 at all Häagen-Dazs™ Shops in Hong Kong. No prior notice will be given for any changes to the redemption outlet addresses.",
        "This eVoucher is only valid for regular priced items.",
        "This eVoucher is not valid for purchasing cash vouchers, wedding vouchers and any other vouchers. It is also not valid for Mooncake, Fondue, all festive and promotional items.",
        "Should there be any difference in value, customer needs to pay the difference.",
        "This eVoucher cannot be reused once redemption has been done.",
        "Consumers should take the responsibility to keep this eVoucher properly. By transferring this eVoucher to others, you are agreeing to all terms and conditions as set forth herein.",
        "This eVoucher must be used on or before the expiry date indicated on the eVoucher or the eVoucher will be forfeited.",
        "eVoucher are invalid for repeat redemption. Any attempt to fraud will bring forth criminal prosecution.",
        "Printed version is not acceptable, only electronic format is accepted.",
        "This eVoucher is not for resale.",
        "General Mill Hong Kong Limited and bears no responsibility and no compensation for loss and expiry of this eVoucher.",
        "In case of any dispute, the decision of General Mills Hong Kong Limited shall be final.",
        "The voucher is provided by AT Points by AsiaTop Loyalty Limited.",
        "For any enquiries, please contact AT Points.",
        "Contact number: 2322 8350",
        "WhatsApp: +852 9311 6084"
    ]
}

export const foodpanda_instructions_tc: TitleDetail = {
    style_index: IndexStyle.none,
    title: "使用方法:",
    details: [
        "請複製優惠劵卡號, 並粘貼到foodpanda的應用程式或網站上使用。",
    ]
}

export const foodpanda_instructions_en: TitleDetail = {
    style_index: IndexStyle.none,
    title: "Redemption Method:",
    details: [
        "Please copy the promo code and use it on foodpanda app or foodpanda website.",
    ]
}

export const foodpanda_tandc_04_09_2021_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則:",
    details: [
        "不設最低訂單金額。",
        "優惠券適用於在foodpanda應用程式或foodpanda網站上兌換（https://www.foodpanda.hk和 http://corporate.foodpanda.hk）",
        "此現金券不適用於送貨費。",
        "不允許將優惠券兌換成其他形式的商品，服務，現金或任何等價貨幣。",
        "每個優惠券代碼只能使用一次，並且每個交易只能使用一個優惠券。",
        "現金券有效期至04-09-2021，逾期不設退款或更換。",
        "如有任何爭議，foodpanda保留一切有關行使之最終決定。",
    ]
}

export const foodpanda_tandc_04_09_2021_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "No minimum order value is required.",
        "The voucher is applicable for redemption in-app or on foodpanda website (https://www.foodpanda.hk & http://corporate.foodpanda.hk)",
        "The voucher is not applicable to the delivery fee.",
        "The voucher is not allowed to be exchanged for other forms of goods, services, cash or any monetary equivalent.",
        "Each voucher code can only be used once, and only one voucher can be used for each transaction.",
        "The voucher will be valid till 04-09-2021 (or less depending on the agreement with customer) and is not refundable or exchangeable.",
        "foodpanda reserves the right of final decision and interpretation in the case of any dispute."
    ]
}

export const deliveroo_instructions_tc: TitleDetail = {
    style_index: IndexStyle.none,
    title: "使用方法:",
    details: [
        "步驟1: 需於戶戶送網站或手機應用程式註冊帳戶, 填入所需資料與設定密碼。",
        "步驟2: 請複製優惠劵卡號, 並粘貼到戶戶送網站或手機應用程式(安卓系統或蘋果系統)上使用。",
    ]
}

export const deliveroo_instructions_en: TitleDetail = {
    style_index: IndexStyle.none,
    title: "Redemption Method:",
    details: [
        "Step 1: Register an account on the Deliveroo website or mobile application, fill in the required information and set a password.",
        "Step 2: Please copy the coupon number and paste it on the Deliveroo website or mobile application (Android or Apple) to use.",
    ]
}

export const deliveroo_tandc_24_02_2022_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則:",
    details: [
        "於 www.deliveroo.hk 或戶戶送應用程式註冊並在結賬前輸入戶戶送電子優惠碼。",
        "你只需輸入優惠碼一次，其面值或餘額（如有）便會被存入你的戶戶送帳戶。",
        "有效日期至2022年2月24日。",
        "優惠碼不能兌換現金及不設退款。",
        "詳情請參閱 deliveroo.hk/legal 條款及細則。"
    ]
}

export const deliveroo_tandc_24_02_2022_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "Enter the Deliveroo voucher code before checkout on the Deliveroo app or on www.deliveroo.hk.",
        "You only need to enter the voucher code once, its value or balance (if any) will be credited and saved to your account.",
        "Deliveroo voucher code is valid till 24/2/2022.",
        "Vouchers are not exchangeable or refundable.",
        "See deliveroo.hk/legal for full terms."
    ]
}

export const deliveroo_tandc_02_08_2023_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則:",
    details: [
        "於 www.deliveroo.hk 或戶戶送應用程式註冊並在結賬前輸入戶戶送電子優惠碼。",
        "你只需輸入優惠碼一次，其面值或餘額（如有）便會被存入你的戶戶送帳戶。",
        "有效日期至2023年8月2日。",
        "優惠碼不能兌換現金及不設退款。",
        "詳情請參閱 deliveroo.hk/legal 條款及細則。"
    ]
}

export const deliveroo_tandc_02_08_2023_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "Enter the Deliveroo voucher code before checkout on the Deliveroo app or on www.deliveroo.hk.",
        "You only need to enter the voucher code once, its value or balance (if any) will be credited and saved to your account.",
        "Deliveroo voucher code is valid till 02/08/2023.",
        "Vouchers are not exchangeable or refundable.",
        "See deliveroo.hk/legal for full terms."
    ]
}

// 鴻福堂
export const hft_tandc_28_02_2022_tc: TitleDetail = {

    details: [
        // "憑券到鴻福堂惠顧可當作港幣HKD$20使用",
        // "此優惠不可兌換現金或其他產品。",
        // "如有任何爭議，公司保留一切有關行使之最終決定。",
        // "此優惠不可與其他折扣及優惠同時使用。",
        // "優惠需要於2022年2月28日前使用，逾期不設退款。",
        // "每次只限使用優惠一次。"
        "憑券到香港鴻福堂分店可免費換取同等面值之正價產品(特價或優惠產品除外)。",
        "憑券適用於換領保健自家湯系列、正品龜苓膏系列、自家涼茶系列、滋潤甘露系列、自家喜慶系列、自家節慶系列、自家小食系列、自家粥品列、自家甜品系列及鮮磨豆漿系列等產品；產品以正價計算。",
        "換取正價產品之總值如超過此券之面值，則需以現金支付餘數；若少於此券之金額則作棄權論；餘額不可兌換現金、找贖、退款或轉換至「自家CLUB」會員的備用儲值額，亦不能用作補回使用其他套票時之差額。",
        "此券不能兌換現金、不設找贖、亦不能與其他鴻福堂優惠同時使用。",
        "此券不可用作(包括但不限於)換領鴻福堂自家套票、現金券、增值自家CLUB卡或八達通卡服務。",
        "請於付款前出示此券，此券可累積使用。如有塗改或破損即屬無效，影印本恕不接受。",
        "此券須於有效日期內使用，逾期作廢。",
        "鴻福堂產品價格或產品款式會不時調整或更改，請聯絡顧客服務部熱線2920-2206以取得最新產品資訊。",
        "如有任何爭議，一概以「QUALITY OF LIFE PRODUCTS COMPANY LIMITED生活良方產品有限公司」的最終決定為準。"
    ]
}

export const hft_tandc_28_02_2022_en: TitleDetail = {

    details: [
        "This coupon can be used as $20 at any Hung Fook Tong stores.",
        "This offer cannot be redeemed for cash.",
        "The merchant reserves the final right of decision on all matters concerning the use of this offer.",
        "This offer cannot be used in conjunction with other discounts or promotional offers.",
        "This offer is valid until the expiry date stated and is refundable upon expiry.",
        "Only one offer can be used on each purchase."
    ]
}

export const hft_tandc_08_02_2022_50_tc: TitleDetail = {

    details: [
        "憑券到鴻福堂惠顧可當作港幣HKD$50使用",
        "此優惠不可兌換現金或其他產品。",
        "如有任何爭議，公司保留一切有關行使之最終決定。",
        "此優惠不可與其他折扣及優惠同時使用。",
        "優惠需要於2022年2月8日前使用，逾期不設退款。",
        "每次只限使用優惠一次。"
    ]
}

export const hft_tandc_08_02_2022_50_en: TitleDetail = {

    details: [
        "This coupon can be used as $50 at any Hung Fook Tong stores.",
        "This offer cannot be redeemed for cash.",
        "The merchant reserves the final right of decision on all matters concerning the use of this offer.",
        "This offer cannot be used in conjunction with other discounts or promotional offers.",
        "This offer is valid until the expiry date stated and is refundable upon expiry.",
        "Only one offer can be used on each purchase."
    ]
}

// 鴻福堂
export const hft_tandc_header_tc: TitleDetail = {
    style_index: IndexStyle.none,
    details: [
        "HK$20 禮券"

    ]
}
export const hft_tandc_tc: TitleDetail = {
    title: "使用細則 :",
    details: [
        "憑券到香港鴻福堂分店可免費換取同等面值之正價產品(特價或優惠產品除外)。",
        "憑券適用於換領保健自家湯系列、正品龜苓膏系列、自家涼茶系列、滋潤甘露系列、自家喜慶系列、自家節慶系列、自家小食系列、自家粥品飯麵系列、自家甜品系列及鮮磨豆漿系列等產品；產品以正價計算。",
        "換取正價產品之總值如超過此券之面值，則需以現金支付餘數；若少於此券之金額則作棄權論；餘額不可兌換現金、找贖、退款或轉換至「自家CLUB」會員的備用儲值額，亦不能用作補回使用其他套票時之差額。",
        "此券不能兌換現金、不設找贖、亦不能與其他鴻福堂優惠同時使用。",
        "此券不可用作(包括但不限於)換領鴻福堂自家套票、現金券、增值自家CLUB 卡或八達通卡服務。",
        "請於付款前出示此券，此券可累積使用。如有塗改或破損即屬無效，影印本恕不接受。",
        "此券須於有效日期內使用，逾期作廢",
        "鴻福堂產品價格或產品款式會不時調整或更改，請聯絡顧客服務部熱線2920-2206 以取得最新產品資訊。",
        "如有任何爭議，一概以「QUALITY OF LIFE PRODUCTS COMPANY LIMITED 生活良方產品有限公司」的最終決定為準。",
    ]
}

export const hft_tandc_header_en: TitleDetail = {
    style_index: IndexStyle.none,
    details: [
        "HK$20 Gift Voucher"

    ]
}
export const hft_tandc_en: TitleDetail = {
    title: "Terms& Conditions",
    details: [
        "Gift voucher can be redeemed at any Hung Fook Tong stores in Hong Kong at its face value for the original priced products only (discounted and promotional products are excluded).",
        "This gift voucher is eligible to redeem our Healthy Home-Made Soup Series, Herbal Jelly Series, Healthy Herbal Tea Series, Nourishing Deluxe Drink Series, Home-Made Joyous Series, Home-Made Festive Series, Home-Made Snacks, Home-Made Congee．Rice．Noodle Series, Home-Made Dessert Series and Fresh Soybean Milk Series products etc. at their original prices.",
        "If the purchase exceeds the amount of the voucher's face value, the remaining balance must be paid by cash. The remaining value of the voucher is not refundable, exchangeable, redeemable for cash or transferred to JIKA Club member's account balance.  No change will be given either in cash or in other form. The voucher cannot be used in conjunction with any other coupon.",
        "This voucher is not exchangeable for cash and/or used in conjunction with other Hung Fook Tong discount offers. No change will be given either in cash or in other form.",
        "This voucher is not applicable for purchase of (including but not limited to) Hung Fook Tong Home-Made selected coupons, gift vouchers, or adding value service for JIKA Club Card nor Octopus Card.",
        "Please present this voucher before payment. This voucher can be accumulated. This voucher will become invalid if it has been defaced or damaged in anyway. Photocopies will not be accepted.",
        "This voucher is only valid on or before the expiry date.",
        "Hung Fook Tong may adjust the product prices or change products from time to time. Please contact the Customer Services Hotline at 2920-2206 for more details.",
        "QUALITY OF LIFE PRODUCTS COMPANY LIMITED生活良方產品有限公司 reserves its right to make the final decision in the event of any dispute."
    ]
}

export const tenren_tandc_28_02_2022_tc: TitleDetail = {
    style_index: IndexStyle.bullets,
    details: [
        "此券恕不適用於外送、外賣平台服務。",
        "此券恕不適用於茶食、茶點及茶葉商品。",
        "此券不適用於機場、一田超市、誠品書店、UNY、LAB Concept。",
        "此券不可與其他優惠/現金券同時使用；恕不得兌換現金。",
        "請於結帳前出示此券，如發票已開立，請於下次消費使用。",
        "超過券面金額部分需要補回差價；不足券面部分不得找零。",
        "如有任何爭議，香港天仁茗茶保留最終決定權。"
    ]
}

export const tenren_tandc_28_02_2022_en: TitleDetail = {
    style_index: IndexStyle.bullets,
    details: [
        "This coupon is not applicable to delivery and takeaway platform services.",
        "This voucher is not applicable for Tea leaves and Tea refreshment (eg. Pancakes or Shaved Ice or Egg Roll or Tea flavour peanuts or Tea flavours popcorn)",
        "This voucher cannot be redeemed at any stores in Hong Kong International Airport, Yata Supermarket, Eslite Bookstore, UNY and Lab Concept.",
        "This coupon cannot be used in conjunction with other discounts/cash coupons; it cannot be redeemed for cash.",
        "Please present this coupon before payment. If the invoice has been issued, please use it for next purchase.",
        "If the purchase amount has exceeded the coupon amount. Customers will need to pay the price different. No change shall be provided if the purchase amount is less than the coupon amount.",
        "In case of any dispute, the decision of TenRen's Tea shall be final."
    ]
}

export const pacific_coffee_tandc_01_08_2021_tc: TitleDetail = {
    title: "條款及細則",
    details: [
        "此電子券持有人可於購買任何菜單上之手調飲品 之交易中作現金HK$25付款，餘款將不予退還 (如欲升級選用燕麥奶，每杯飲品需額外加$4)",
        "此電子券有效日期由即日起至2021年8月1日。請於有效日期前使用、逾期作廢",
        "此電子券適用於全線香港Pacific Coffee分店",
        "此電子券不可兌換現金，不適用於網站或應用程式內購物及外賣遞送服務",
        "於購買兩杯手調飲品或以上之交易，顧客可使用多於一張電子現金券，惟每張電子現金券只可於最多兩杯手調飲品之總值提供現金扣減",
        "於購買一杯手調飲品之交易，顧客只可使用一張電子現金券",
        "此電子券不可與其他優惠同時使用 （自攜盛器優惠除外）",
        "影印本恕不接受",
        "此電子券之二維碼只可使用一次",
        "此電子券如有遺失或損壞，恕不補發",
        "Pacific Coffee Co. Ltd. 將保留此優惠之最終決定權"
    ]
}

export const pacific_coffee_tandc_01_08_2021_en: TitleDetail = {
    title: "Terms & Conditions:",
    details: [
        "This e-voucher entitles the holder to settle payment as HK$25 upon any purchase of handcrafted beverage on our menu and unused value will be forfeited (an additional $4 will be charged per beverage to upgrade to oat milk).",
        "This e-voucher is valid until August 1, 2021, and expiry date is non-extendable.",
        "This e-voucher is redeemable at all Pacific Coffee in Hong Kong.",
        "This e-voucher cannot be exchanged for cash or its equivalent and is not applicable to in-app purchase and delivery services.",
        "For the transaction of two or above beverages, more than one e-vouchers can be used and each e-voucher can be used to settle payment for a maximum of two beverages",
        "For the transaction of one beverage, only one e-voucher can be used.",
        "This e-voucher cannot be used in conjunction with any other promotions or privileges (except bring-your-own-mug discount)",
        "Photocopies of this voucher will not be accepted.",
        "The QR code on this e-voucher is valid for one-time use only.",
        "This e-voucher will not be re-issued in case of loss or damage.",
        "Pacific Coffee Co. Ltd. reserves the right of final decision in case of disputes."
    ]
}

export const pacific_coffee_tandc_18_04_2023_tc: TitleDetail = {
    title: "條款及細則",
    details: [
        "1. 此電子券有效期由即日起至2023年4月18日, 逾期作廢",
        "2. 此電子券的持有人可於任何交易中享有HK$25折扣優惠 （購買/增值Perfect Cup卡或Pacific Coffee禮蜜卡除外），餘款將不予退還",
        "3. 此電子券適用於Pacific Coffee各香港分店",
        "4. 此電子券不可兌換現金",
        "5. 此電子券只可使用一次",
        "6. Pacific Coffee Company Limited將保留此優惠之最終決定權"
    ]
}

export const pacific_coffee_tandc_22_02_2023_en: TitleDetail = {
    details:[
        "1. This e-voucher is valid from August 23, 2022 to February 22, 2023, expiry date is non-extendable",
        "2. This e-voucher entitles the holder HK$25 discount off on any purchase (except the purchase or top-up of the Perfect Cup Card and Pacific Coffee Gift Card), unused value will be forfeited'",
        "3. This e-voucher is redeemable at all Pacific Coffee in Hong Kong",
        "4. This e-voucher cannot be exchanged for cash or its equivalent",
        "5. This e-voucher is valid for one-time use only",
        "6. Pacific Coffee Company Limited reserves the right of final decision in case of disputes"
    ]
}

export const pacific_coffee_tandc_22_02_2023_tc: TitleDetail = {
    details:[
        "1. 此電子券有效期由2022年8月23日至2023年2月22日, 逾期作廢",
        "2. 此電子券的持有人可於任何交易中享有HK$25折扣優惠 （購買/增值Perfect Cup卡或Pacific Coffee禮蜜卡除外），餘款將不予退還",
        "3. 此電子券適用於Pacific Coffee各香港分店",
        "4. 此電子券不可兌換現金",
        "5. 此電子券只可使用一次",
        "6. Pacific Coffee Company Limited將保留此優惠之最終決定權"
    ]
}

export const pacific_coffee_tandc_18_04_2023_en: TitleDetail = {
    title: "Terms & Conditions:",
    details: [
        "1. This e-voucher is valid until 18 April 2023, expiry date is non-extendable",
		"2. This e-voucher entitles the holder HK$25 discount off on any purchase (except the purchase or top-up of the Perfect Cup Card and Pacific Coffee Gift Card), unused value will be forfeited",
		"3. This e-voucher is redeemable at all Pacific Coffee in Hong Kong",
		"4. This e-voucher cannot be exchanged for cash or its equivalent",
		"5. This e-voucher is valid for one-time use only 6. Pacific Coffee Company Limited reserves the right of final decision in case of disputes"
    ]
}

export const pacific_coffee_tandc_10_08_2023_en: TitleDetail = {
    details:[
        "1. This e-voucher is valid from February 28, 2023 to August 10, 2023, expiry date is non-extendable",
        "2. This e-voucher entitles the holder HK$25 discount off on any purchase (except the purchase or top-up of the Perfect Cup Card and Pacific Coffee Gift Card), unused value will be forfeited'",
        "3. This e-voucher is redeemable at all Pacific Coffee in Hong Kong",
        "4. This e-voucher cannot be exchanged for cash or its equivalent",
        "5. This e-voucher is valid for one-time use only",
        "6. Pacific Coffee Company Limited reserves the right of final decision in case of disputes"
    ]
}

export const pacific_coffee_tandc_10_08_2023_tc: TitleDetail = {
    details:[
        "1. 此電子券有效期由2023年2月28日至2023年8月10日, 逾期作廢",
        "2. 此電子券的持有人可於任何交易中享有HK$25折扣優惠 （購買/增值Perfect Cup卡或Pacific Coffee禮蜜卡除外），餘款將不予退還",
        "3. 此電子券適用於Pacific Coffee各香港分店",
        "4. 此電子券不可兌換現金",
        "5. 此電子券只可使用一次",
        "6. Pacific Coffee Company Limited將保留此優惠之最終決定權"
    ]
}

export const TOASTBOX_tandc_07_08_2021_tc: TitleDetail = {
    style_index: IndexStyle.bullets,
    details: [
        "此券不可兌換現金或現金找贖",
        "現金券只適用於土司工坊Toast Box 香港分店(尖沙咀新港中心、旺角MOKO、奧海城2期分店除外)",
        "現金券只適用於土司工坊 Toast Box其下品牌產品，咖央醬、花生醬、咖啡產品及會員卡套裝等除外",
        "此券不適用於推廣折扣、減價產品，及不可與其他優惠同時使用",
        "如有任何爭議，BreadTalk Concept Hong Kong Limited保留最終決定權",
        "有效期至2021年8月7日",
    ]
}

export const TOASTBOX_tandc_07_08_2021_en: TitleDetail = {
    style_index: IndexStyle.bullets,
    details: [
        "This coupon cannot be redeemed for cash.",
        "The coupon is only applicable to Toast Box branch in Hong Kong. (except Tsim Sha Tsui-Silvercord, Mong Kok-MOKO, Tai Kok Tsui-Olympian City 2)",
        "Coupons are only applicable to Toast Box's product except for Kayang Butter, Peanut Butter, Coffee Products and membership card sets.",
        "This coupon cannot be used in conjunction with other discounts or promotional offers.",
        "BreadTalk Concept Hong Kong Limited reserves the final right of decision on all matters concerning the use of this offer.",
        "This coupon offer is valid until August 7, 2021",

    ]
}

export const BreadTalk_tandc_31_12_2021_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    details: [
        "此券不可兌換現金或現金找贖",
        "現金券只適用於麵包新語 BreadTalk 香港分店",
        "現金券只適用於面包新語其下品牌產品，肉鬆、咖央醬、包裝飲品及會員卡套裝等除外",
        "此券不適用於推廣折扣、減價產品，及不可與其他優惠同時使用",
        "如有任何爭議，BreadTalk Concept Hong Kong Limited保留最終決定權",
        "有效期至2021年12月31日",
    ]
}

export const BreadTalk_tandc_31_12_2021_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    details: [
        "This coupon cannot be redeemed for cash.",
        "This coupon can be used as $20 at any BreadTalk stores in Hong Kong.",
        "The cash vouchers are only applicable to BreadTalk's product, excluding pork floss, kaya sauce, packaged drinks and membership card sets.",
        "This coupon is not applicable to promotional discounts, discounted products, and cannot be used in conjunction with other discounts.",
        "BreadTalk Concept Hong Kong Limited reserves the final right of decision on all matters concerning the use of this offer.",
        "This coupon offer is valid until December 31, 2021",

    ]
}

export const TOASTBOX_tandc_31_12_2021_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    details: [
        "此券不可兌換現金或現金找贖",
        "現金券只適用於土司工坊Toast Box 香港分店(尖沙咀新港中心、旺角MOKO、奧海城2期分店除外)",
        "現金券只適用於土司工坊 Toast Box其下品牌產品，咖央醬、花生醬、咖啡產品及會員卡套裝等除外",
        "此券不適用於推廣折扣、減價產品，及不可與其他優惠同時使用",
        "如有任何爭議，BreadTalk Concept Hong Kong Limited保留最終決定權",
        "有效期至2021年12月31日",
    ]
}

export const TOASTBOX_tandc_31_12_2021_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    details: [
        "This coupon cannot be redeemed for cash.",
        "The coupon is only applicable to Toast Box branch in Hong Kong. (except Tsim Sha Tsui-Silvercord, Mong Kok-MOKO, Tai Kok Tsui-Olympian City 2)",
        "Coupons are only applicable to Toast Box's product except for Kayang Butter, Peanut Butter, Coffee Products and membership card sets.",
        "This coupon cannot be used in conjunction with other discounts or promotional offers.",
        "BreadTalk Concept Hong Kong Limited reserves the final right of decision on all matters concerning the use of this offer.",
        "This coupon offer is valid until December 31, 2021",

    ]
}

export const LoHongKa_tandc_31_12_2021_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    details: [
        "此優惠不可兌換現金或其他產品。",
        "此優惠只適用於門市。",
        "如有任何爭議，老行家(香港)有限公司保留一切有關行使之最終決定。",
        "此優惠不可與其他折扣及優惠同時使用。",
        "優惠需要於指定有效日期前使用，逾期不設退款。"
    ]
}

export const LoHongKa_tandc_31_12_2021_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    details: [
        "This offer cannot be redeemed for cash.",
        "Lo Hong Ka (Hong Kong) Limited reserves the final right of decision on all matters concerning the use of this offer.",
        "This offer cannot be used in conjunction with other discounts or promotional offers.",
        "This offer is valid until the expiry date stated and is refundable upon expiry.",
        "Cash Voucher cannot combine with other promotion."

    ]
}

export const OTW_tandc_50_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    details: [
        "此券可購買任何產品(不可購買任何套券)",
        "如購買價值高於$50之產品, 需補回產價。差價將不獲提供任何折扣優惠。",
        "不適用於網上外賣",
        "電子現金券限用一次, 如電子現金券逾期, 已使用, 丟失或被盜用, 不作補發。",
        "於有效期前使用, 逾期無效。",
        "可與其他優惠同時使用(不可使用會員卡折扣)",
        "不設找續及不可兌換現金或會員卡儲值",
        "此券可於各原味家作分店使用",
        "如有任何爭議, 原味家作保留最終決定權"
    ]
}

export const OTW_tandc_50_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    details: [
        "This coupon can be used upon any purchase (except vouchers)",
        "If the purchase amount has exceeded $50. Customers need to pay the price different. No discount shall be provided if the purchase amount is less than the coupon amount.",
        "This offer is not valid for online food delivery.",
        "This offer is valid for one-time use only. This coupon will not be refunded if the coupon is expired, used, or stolen.",
        "The cash voucher shall be invalid after the expiry date.",
        "This offer cannot be used in conjunction with other discounts or promotional offers. (Cannot be used in member discount)",
        "This offer is no charge and cannot be redeem as cash and value added of Membership card",
        "This coupon is valid for use in Original Taste Workshop Limited.",
        "The merchant reserves the final right of decision on all matters concerning the use of this offer.",
    ]
}

export const fairwood_tandc_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    details: [
        "憑此電子現金卷可於各大快活快餐香港全線分店內使用，不適用於網上點餐、訂購到會美食及盆菜服務。",
        "請於付款時出示此電子現金卷及/或其二維碼(\"QR code\")，此電子現金卷及其二維碼只可使用一次。",
        "每次惠顧可使用多張大快活之電子現金卷。",
        "此電子現金卷不可兌換現金或退款，並不獲現金或其他形式找續。",
        "此電子現金卷不可作增值八達通或作任何儲值或增值服務之用。",
        "此電子現金卷及/或其二維碼如有遺失、塗污、塗改或損毀，即屬無效，恕不補發。",
        "不論任何原因，若未能成功掃瞄電子現金卷內之二維碼，此電子現金卷將不獲接受。",
        "此電子現金卷只限於有效日期內使用，逾期作廢。",
        "以上圖片只供参考。",
        "如有任何爭議，一概以大快活快餐有限公司之最後決定權為準。"
    ]
}

export const fairwood_tandc_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    details: [
        "This e-cash voucher can be used in all Fairwood branches in Hong Kong. It is not applicable to online food ordering, party food ordering and Poon Choi services.",
        "Please present this electronic cash voucher and/or QR code when making payment. This electronic cash voucher and its QR code can only be used once.",
        "More than one electronic cash coupons can be used in single transaction.",
        "This electronic cash voucher cannot be exchanged for cash or refunded, and it will not be renewed in cash or other forms.",
        "This electronic cash voucher cannot be used for value-added Octopus or for any value-added services.",
        "If this electronic cash voucher and/or its QR code is lost, defaced, altered or damaged, it is invalid and will not be reissued.",
        "For any reason, if the QR code in the electronic cash voucher is not scanned successfully, the electronic cash voucher will not be accepted.",
        "This electronic cash voucher can only be used within the effective date and will be invalid after the expiry date.",
        "The above picture is for reference only.",
        "In case of any disputes, Fairwood Fast Food Limited reserves the right of the final decision.",
    ]
}

export const st_honore_tandc_31_12_2021_tc: TitleDetail = {
    style_index: IndexStyle.bullets,
    title: "條款及細則",
    details: [
        "憑券到聖安娜惠顧可當作港幣HKD$50使用",
        '此券只適用於聖安娜餅屋香港分店',
        "此優惠不可兌換現金或其他產品。",
        "此券不適用於選購飲品、非聖安娜產品及現金券(包括禮餅券及麵包券)",
        "此優惠不可與其他折扣及優惠同時使用。",
        "優惠需要於指定有效日期內(29-10-2020 至31-12-2021)使用，逾期不設退款。",
        "每券限用一次"
    ]
}

export const st_honore_tandc_31_12_2021_en: TitleDetail = {
    style_index: IndexStyle.bullets,
    title: "Terms & Conditions:",
    details: [
        "This coupon can be used as $50 at any Saint Honore Cake Shop stores.",
        "This coupon is vaild for use in Toast Box stores in Hong Kong only。",
        "This offer cannot be redeemed for cash.",
        "This coupon is not applicable to drinks, non-Saint Honore products and vouchers (including Gift Voucher and bread Coupon)",
        "This offer cannot be used in conjunction with other discounts or promotional offers.",
        "This offer is valid from October 29, 2020 to December 31,2021, and is refundable upon expiry.",
        "This offer is valid for one-time use only."
    ]
}

export const king_bakery_tandc_15_03_2022_tc: TitleDetail = {

    details: [
        "電子現金券適用於蛋撻王全線分店。（機場分店、共食薈分店除外）",
        "電子現金券有效日期至2022年3月15日止。",
        "每次交易可使用之電子現金券數量不限，惟每張電子現金券只可使用一次。",
        "請於付款前出示電子現金券，截圖無效。",
        "電子現金券只適用於購買自家製產品。",
        "電子現金券不可兌換現金、亦不設找贖。",
        "電子現金券不適用於購買時節產品及「Don’t 撈散水撻」。",
        "電子現金券不可與任何禮券、現金券、推廣價同時使用。",
        "電子現金券不適用於外送服務。",
        "蛋撻王保留使用此電子現金券之最終決定權。"
    ]
}

export const king_bakery_tandc_15_03_2022_en: TitleDetail = {

    details: [
        "This e-voucher is redeemable at all King Bakery Shops in Hong Kong (except HK International Airport and CO:EAT Space branches)",
        "This e-voucher is valid until March 15, 2022, and expiry date is on-extendable.",
        "More than one e-voucher can be used in a single transaction and this e-voucher is valid for one-time use only.",
        "Please present the e-voucher before payment, the screenshot is invalid.",
        "This e-voucher is only applicable to the purchase of own brand products.",
        "This e-voucher cannot be redeemed for cash or other vouchers. The total value of an e-voucher will be deducted in full when used. Any unused balance will be forfeited.",
        "This e-voucher is not applicable to purchase seasonal products and thank you tart.",
        "This e-voucher cannot be used in conjunction with other promotions.",
        "This e-voucher is not applicable to delivery service.",
        "King Bakery Holdings Limited reserves the right to amend the terms and conditions of this e-voucher unilaterally without prior notice"

    ]
}

export const jhc_tandc_tc: TitleDetail = {
    style_index: IndexStyle.none,
    details: [
        "此電子購物禮券適用於香港日本城及生活提案分店。",
        "此電子購物禮券不適用於JHCeshop、電話及傳真訂購。",
        "此電子購物禮券只可使用一次。",
        "此電子購物禮券不能兌換現金及不設找贖。",
        "此電子購物禮券不適用於惠顧任何禮券或現金券",
        "此電子購物禮券必須於指定之有效日期內使用，逾期作廢，不可退換。",
        "本公司不會因人為錯誤(如丟失、損壞或被盜用)而重發禮券。",
        "如有任何爭議，本公司保留此電子購物禮券之最終使用權。",

    ]
}

export const jhc_tandc_en: TitleDetail = {
    style_index: IndexStyle.none,
    details: [
        "This E-Gift Voucher is applicable for any JHC and City Life shops in Hong Kong.",
        "This E-Gift Voucher is not applicable to JHCeshop, telephone and fax orders.",
        "Each E-Gift Voucher can be used once only.",
        "This E-Gift Voucher cannot be exchanged for cash nor given for the unused amount.",
        "This E-Gift Voucher cannot be used for purchasing any other Gift Voucher.",
        "This E-Gift voucher must be used before the expiry date, failing which the coupon shall expire with no refund or exchange.",
        "JHC is not responsible for lost, stolen, or damaged conditions of the E-Gift voucher.",
        "We reserve the final decision of this privilege application.",
    ]
}

export const jhc_tandc_number_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    details: [
        "此電子購物禮券適用於香港日本城及生活提案分店。",
        "此電子購物禮券不適用於JHCeshop、電話及傳真訂購。",
        "此電子購物禮券只可使用一次。",
        "此電子購物禮券不能兌換現金及不設找贖。",
        "此電子購物禮券不適用於惠顧任何禮券或現金券",
        "此電子購物禮券必須於指定之有效日期內使用，逾期作廢，不可退換。",
        "本公司不會因人為錯誤(如丟失、損壞或被盜用)而重發禮券。",
        "如有任何爭議，本公司保留此電子購物禮券之最終使用權。",

    ]
}

export const jhc_tandc_number_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    details: [
        "This E-Gift Voucher is applicable for any JHC and City Life shops in Hong Kong.",
        "This E-Gift Voucher is not applicable to JHCeshop, telephone and fax orders.",
        "Each E-Gift Voucher can be used once only.",
        "This E-Gift Voucher cannot be exchanged for cash nor given for the unused amount.",
        "This E-Gift Voucher cannot be used for purchasing any other Gift Voucher.",
        "This E-Gift voucher must be used before the expiry date, failing which the coupon shall expire with no refund or exchange.",
        "JHC is not responsible for lost, stolen, or damaged conditions of the E-Gift voucher.",
        "We reserve the final decision of this privilege application.",
    ]
}

export const shell_title_tc: TitleDetail = {
    style_index: IndexStyle.none,
    details: [
        "Shell汽油禮券條款及細則：",
        "憑禮券可到香港各Shell油站換取價值港幣一百元之汽油產品。"
    ]
}

export const shell_tandc_tc: TitleDetail = {
    details: [
        "只可以蜆殼公司牌價換取汽油產品。",
        "請於入油前出示此禮券。",
        "此券不適用於潤滑油。",
        "此券不可與其他優惠同時使用。",
        "此券不得退換現金。",
        "逾期使用或經更改之禮券，一概作廢。",
        "香港蜆殼有限公司保留一切最終決議權。",
    ]
}

export const shell_title_en: TitleDetail = {
    style_index: IndexStyle.none,
    details: [
        "Terms and conditions of Shell Gasoline Voucher:",
        "This voucher entitles the bearer to Shell gasoline products to the value of HK$100 from any Shell Station in the territory of Hong Kong.",
    ]
}

export const shell_tandc_en: TitleDetail = {
    details: [
        "Only for entitlement of gasoline products at Shell list price.",
        "This voucher must be presented before refueling.",
        "This voucher is not applicable to Lube products.",
        "This voucher cannot be used in conjunction with other offers.",
        "This voucher is not redeemable for cash.",
        "Expired, defaced or altered coupon would not be accepted.",
        "Shell Hong Kong Limited reserves the right of final decisions in the event of a dispute.",
    ]
}

export const shell_precautions_31_03_2023_tc: TitleDetail = {

    title: "注意事項：",
    details: [
        "請於入油前，親身前往油站便利店出示並使用此禮券。",
        "此禮券有效期至2023年3月31日。",
        "每張回贈禮券只可使用一次。禮券條碼一經油站系統驗證，便無法再次使用。",
        "如有疑問，請致電Shell Bonus咭客戶服務熱線：(852) 24352064 。(辦公時間：星期一至五08:00 – 18:00)。或電郵至 cardctr@shell.com。"
    ]
}

export const shell_precautions_31_03_2023_en: TitleDetail = {

    title: "Notes:",
    details: [
        "Please visit Shell Select Convenience Store in person and use this voucher before refueling.",
        "This voucher expires after 31 March 2023.",
        "Each voucher can be used once. Barcode becomes ineffective once authenticated by system at site.",
        "For enquiries, contact Shell Bonus Card customer service by calling +852 24352064 (Office hour: Monday to Friday 08:00 – 18:00). Or email to cardctr@shell.com.",
    ]
}

export const seansin_tac_tc: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "條款及細則",
    details: [
        "此優惠不可兌換現金或其他產品。",
        "如有任何爭議，公司保留一切有關行使之最終決定。",
        "此優惠不可與其他折扣及優惠同時使用。",
        "優惠需要於指定有效日期前使用，逾期不設退款。",
        "每次只限使用優惠一次。"
    ]
}

export const seansin_tac_en: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "This offer cannot be redeemed for cash.",
        "The merchant reserves the final right of decision on all matters concerning the use of this offer.",
        "This offer cannot be used in conjunction with other discounts or promotional offers.",
        "This offer is valid until the expiry date stated and is refundable upon expiry.",
        "One Coupon(s) can be used per transaction only."
    ]
}

export const happy_pot_tc: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "條款及細則",
    details: [
        "此優惠不可兌換現金或其他產品。",
        "此優惠不可與其他折扣及優惠同時使用。",
        "優惠需要於指定有效日期前使用，逾期不設退款。",
        "每次只限使用優惠一次。",
        "如有任何爭議，公司保留一切有關行使之最終決定。"
    ]
}

export const happy_pot_en: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "This offer cannot be redeemed for cash.",
        "The merchant reserves the final right of decision on all matters concerning the use of this offer.",
        "This offer cannot be used in conjunction with other discounts or promotional offers.",
        "This offer is valid until the expiry date stated and is refundable upon expiry.",
        "One Coupon(s) can be used per transaction only."
    ]
}

export const ninoen_tc: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "條款及細則",
    details: [
        "此優惠不可兌換現金或其他產品。",
        "如有任何爭議，公司保留一切有關行使之最終決定。",
        "此優惠不可與其他折扣及優惠同時使用。",
        "優惠需要於指定有效日期前使用，逾期不設退款。",
        "每次只限使用優惠一次。"
    ]
}

export const ninoen_en: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "This offer cannot be redeemed for cash.",
        "The merchant reserves the final right of decision on all matters concerning the use of this offer.",
        "This offer cannot be used in conjunction with other discounts or promotional offers.",
        "This offer is valid until the expiry date stated and is refundable upon expiry.",
        "One Coupon(s) can be used per transaction only."
    ]
}


export const green_common_tac_tc: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "條款及細則",
    details: [
        "此禮券只適用於 Kind Kitchen、Green Common 各分店，但不適用於 TASTE Green Common Deli 之櫃位及eShop。",
        "此優惠券只可使用一次。",
        "一次交易中可使用多張電子現金劵",
        "電子現金劵可與其他優惠同時使用。",
        "本券持有人可在有效期內於 Green Common 換取同等價值貨品，逾期無效。",
        "本券不能兌換現金，不獲現金或其他形式找贖。",
        "本券如有遺失或損毀，不獲補發。",
        "如有任何爭議，Green Common 保留最終決定權。",
        "如對此優惠有疑問,請致電(852) 2263 3153 與電郵至 info@greencommon.com",
    ]
}

export const green_common_tac_en: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "This coupon is valid at Kind Kitchen and all Green Common branches except Green Common Deli counters at TASTE and eShop.",
        "This coupon can be redeemed once only.",
        "More than one eVoucher can be used in a single transaction.",
        "The eVoucher can be used in conjunction with other promotions.",
        "This coupon entitles the bearer to exchange the equivalent value for goods at Green Common on or before the expiry date.",
        "This coupon is not redeemable for cash or Green Common in other denominations. No change will be given either in cash or in other forms.",
        "This coupon is invalid and not replaceable if lost or found damaged.",
        "Green Common reserves the right of the decision in case of any dispute arising from this coupon.",
        "For any enquiries about the offer, please contact Green Common at (852) 2263 3153 or email to info@greencommon.com"
    ]
}

export const HealthWorks_tac_tc: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "條款及細則",
    details: [
        "憑此券於健康工房香港分店，五季甜悅及五季禪食分店購買任何正價產品，即可作HK$30現金禮券使用。",
        "如以此券付款，所有產品需以正價計算，產品包括健康茶、滋補湯、藥膳糖水、龜苓膏、土茯苓膏、健康小食、生態農產品及生活精品。",
        "此券不適於購買套券及套餐優惠。",
        "此券不能退款或兌換現金，並不設找續，亦不可與其他優惠同時使用。",
        "此券只適用於健康工房香港分店、五季甜悅及五季禪食分店。",
        "所有電子禮券只可使用一次。",
        "請於付款前出示此券以享優惠。",
        "此券如經塗改、損毀或遺失，則予作廢，恕不補發。",
        "此券不可轉售，亦不延期，逾期作廢。",
        "本公司保留隨時修改行使此券之條款及細則，並毋須作另行通知。",
        "如有任何爭議，健康工房(集團)有限公司保留行使此券之最終決定權。"
    ]
}

export const HealthWorks_tac_en: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "This coupon entitles the holder to purchase HealthWorks products valued at HK$30 at all HealthWorks Shops in Hong Kong.",
        "All products are calculated at original price, products include herbal tea, herbal soup, dessert, herbal jelly, fresh Tu Fu Ling herbal jelly, healthy snack and eco-farming products.",
        "This coupon is not applicable to coupon set and other set promotional offers.",
        "This coupon cannot be exchanged for cash and cannot be used in conjunction with discount coupons and any other promotional offers.",
        "This offer is only applicable at HealthWorks, Health in Five Seasons and Zen in Five Seasons stores.",
        "Each coupon can be used once only and only one coupon can be used per purchase.",
        "Please present this coupon before payment.",
        "If coupon are lost, stolen or damaged, no replacement or refund will be granted.",
        "This offer is valid until the expiry date stated and is non-refundable upon expiry.",
        "HealthWorks Group Holdings Limited (“HealthWorks”) reserves the right to amend the terms and conditions of this offer without any notice.",
        "If there is any dispute, the decision of HealthWorks shall be final."
    ]
}

export const atp25_tac_tc: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "條款及細則",
    details: [
        "此優惠不可兌換現金或其他產品。",
        "此優惠只適用於門市。",
        "如有任何爭議，Asia Top Loyalty Limited保留一切有關行使之最終決定。",
        "此優惠不可與其他折扣及優惠同時使用。",
        "優惠需要於指定有效日期前使用，逾期不設退款。"
    ]
}

export const atp25_tac_en: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "This offer cannot be redeemed for cash.",
        "Asia Top Loyalty Limited reserves the final right of decision on all matters concerning the use of this offer.",
        "This offer cannot be used in conjunction with other discounts or promotional offers.",
        "This offer is valid until the expiry date stated and is refundable upon expiry.",
        "Cash Voucher cannot combine with other promotion."
    ]
}

export const bdss_tac_tc: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "條款及細則",
    details: [
        "憑此券可於有效期內在香港喜點及包點先生分店(荃灣海之戀分店除外)，換領總值HK $30之正價產品(指定節日產品、現金優惠價產品及優惠產品除外)",
        "此券不適用於電話、手機程式或網上下單。",
        "顧客必須於付款前向收錢員出示此券。",
        "此券不可作退款或兌換現金，並不設找續。",
        "此券如若為影印本、逾期、遺失、損毁、遭塗改或模糊不清，即告無效，恕不延期或補發。",
        "此券一經使用即告無效，亦不會獲發還或補發。",
        "此券不可與其他推廣優惠一併使用。",
        "包點先生及喜點有權隨時修改此禮券之使用條款。",
        "此券必須經由(包點先生/喜點)公司蓋章方為有效。",
        "如有任何爭議，包點先生及喜點保留最終決定權。"
    ]
}

export const bdss_tac_en: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "This voucher entitles the holder to redeem goods (festival product, cash product & promotional product are excluded) amounting to retail price HK$30 at DiM Dim Sum Specialty Store & Bao Dim Sin Seng in Hon Kong (Tsuen Wan OP Mall is excluded) on or before the expiry date.",
        "This voucher is not appliable for telephone orders, mobile apps, or online purchases.",
        "This voucher must be presented to the cashier before payment.",
        "This voucher shall not be refunded nor redeemed for cash. No change shall be provided.",
        "This voucher shall not be re-issued nor extended if it is photocopied, expired, lost, damaged or defaced, shall forthwith become invalid.",
        "This voucher shall become invalid and shall not be re-issued and/or returned after use.",
        "This voucher shall not be used in conjunction with any other promotional offers.",
        "Bao Dim Sin Seng & DiM Dim Sum Specialty Store reserve the right to amend the terms & conditions of this voucher from time to time.",
        "This voucher is only valid with an authorized company (Bao Dim Sin Seng/DiM Dim Sum Specialty Store) stamp.",
        "In event of any disputes, Bao Dim Sin Seng & DiM Dim Sum Specialty Store shall reserve the right of final decision."
    ]
}

export const tws_tac_tc: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "條款及細則",
    details: [
        "此優惠不可兌換現金或其他產品。",
        "如有任何爭議，鯉魚門紹香園保留一切有關行使之最終決定。",
        "此優惠不可與其他折扣及優惠同時使用。",
        "優惠需要於指定有效日期前使用，逾期不設退款。",
        "不可與其他優惠同時使用。"
    ]
}

export const tws_tac_en: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "This offer cannot be exchanged for cash or other products.",
        "In case of any disputes, Lei Yue Mun Shao Heung Yuen reserves all final decisions regarding the exercise.",
        "This offer cannot be used in conjunction with other discounts and offers.",
        "The offer needs to be used before the specified effective date, and no refund will be issued after the expiry date.",
        "Cannot be used in conjunction with other discounts."
    ]
}

export const cupping_tac_tc: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "條款及細則",
    details: [
        "此餐飲現金券僅適用於在Cupping Room和CR²by Cupping Room的餐飲消費。 ",
        "此餐飲現金券有效期至2022年3月31日。",
        "此餐飲現金券沒有現金價值，不接受退款。",
        "此餐飲現金券只能使用一次。",
        "此餐飲現金券適用於就餐和外賣。 (不適用於零售產品。)",
        "此餐飲現金券不能與任何其他推廣優惠同時使用。",
        "此餐飲現金券不可兌換為現金，如有任何餘額不能以任何形式兌換。",
        "如餐飲現金券丟失、被盜或損壞時不可更換。",
        "如有任何爭議，Cupping Room Coffee Roasters保留最終決定權。"
    ]
}

export const cupping_tac_en: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "This voucher is applicable for Food & Beverage consumption at Cupping Room Coffee Roasters and CR² by Cupping Room.",
        "The dining voucher is valid til 31 Mar 2022.",
        "This voucher has no cash value and is non-refundable.",
        "This dining voucher can only be used once.",
        "This voucher is applicable for dine in and take away only.",
        "Dining vouchers cannot be used in conjunction with any other promotional offers.",
        "Dining vouchers are non-exchangeable for cash and the balance of a partial redemption cannot be reimbursed or credited in any form.",
        "In case of loss or damage, no replacement will be offered.",
        "In case of any dispute, the decision of Cupping Room Coffee Roasters shall be final."
    ]
}

export const bea_sthonore_mooncake_tac_tc_1: TitleDetail = {

    style_index: IndexStyle.none,
    title: "",
    details: [
        "持卡人須憑短訊於2021年9月9日至9月16日期間到全線聖安娜餅店 (https://www.sthonore.com/hk/location)換領指定禮品；2021年9月17日至9月19日到以下指定26間聖安娜餅店換領禮品。"
    ]
}

export const bea_sthonore_mooncake_tac_tc_2: TitleDetail = {

    style_index: IndexStyle.none,
    title: "換領詳情",
    details: [
        "聖安娜餅店換領地點",
        "\n",
        "香港中環皇后大道中 138 號威享大廈地下一號舖",
        "(星期一至星期六: 07:00 - 21:00，星期日及公眾假期: 08:30 - 20:30)",
        "2544 0544",
        "\n",
        "香港仔東勝道11號地舖",
        "(星期一至日及公眾假期: 07:00 - 21:00)",
        "2873 5881",
        "\n",
        "香港銅鑼灣蘭芳道 15 號地下",
        "(星期一至星期五: 07:00 - 21:00，星期六、日及公眾假期: 08:00 – 20:30)",
        "2752 7706",
        "\n",
        "香港北角道皇冠大廈地下22號舖",
        "(星期一至日及公眾假期: 07:00 - 21:00)",
        "2505 7318",
        "\n",
        "柴灣新翠商場一樓113b號舖",
        "(星期一至日及公眾假期: 07:00 - 21:00)",
        "2625 4831",
        "\n",
        "黃埔花園第9期G16號舖",
        "(星期一至六: 07:00 - 21:00，星期日及公眾假期: 08:00 - 21:00)",
        "2334 0221",
        "\n",
        "深水埗寶熙苑商場10號舖",
        "(星期一至日及公眾假期: 07:00 - 21:00)",
        "2729 1240",
        "\n",
        "觀塘輔仁街輔成樓地下4號舖",
        "(星期一至日及公眾假期: 07:00 - 21:00)",
        "2259 5485",
        "\n",
        "佐敦庇利金街41號地下",
        "(星期一至六: 07:00 - 21:00，星期日及公眾假期: 08:00 - 20:30)",
        "2419 2320",
        "\n",
        "荔枝角長沙灣道796號地下",
        "(星期一至六: 07:00 - 20:30，星期日及公眾假期: 09:00 - 18:00)",
        "2742 9771",
        "\n",
        "藍田匯景廣場3樓301號",
        "(星期一至五: 06:30 - 21:00，星期六、日及公眾假期: 07:00 - 21:00)",
        "2340 4776",
        "\n",
        "登打士街鴻輝大廈地下10號舖",
        "(星期一至星期六: 07:00 - 21:00，星期日及公眾假期: 08:00 - 21:00)",
        "2783 7008",
        "\n",
        "聯合道198號樂富廣場 2 樓 2118 號舖",
        "(星期一至日及公眾假期: 06:45 - 21:00)",
        "2267 0012",
        "\n",
        "牛頭角淘大商場地下G3B號舖",
        "(星期一至日及公眾假期: 07:00 - 21:00)",
        "2795 0330",
        "\n",
        "將軍澳欣景路8號新都城中心二期一樓1144號舖",
        "(星期一至日及公眾假期: 07:00 - 21:00)",
        "2465 9109",
        "\n",
        "荃灣愉景新城第二層 2024 號舖",
        "(星期一至星期六: 07:00 - 21:00，星期日及公眾假期: 08:00 - 21:00)",
        "2528 6812",
        "\n",
        "大埔超級城 D 區一樓 610-611 號舖",
        "(星期一至五: 07:00 - 21:00，星期六、日及公眾假期: 08:00 - 21:00)",
        "2660 7094",
        "\n",
        "元朗裕景坊祥發大廈地下8號舖",
        "(星期一至日及公眾假期: 07:00 - 21:00)",
        "2441 5649",
        "\n",
        "將軍澳站20號舖",
        "(星期一至日及公眾假期: 07:00 - 21:00)",
        "2392 2126",
        "\n",
        "大圍港鐵站 TAW 33 號舖",
        "(星期一至日及公眾假期: 07:00 - 21:00)",
        "2603 1797",
        "\n",
        "上水港鐵站 SHS 14 號舖",
        "(星期一至日及公眾假期: 07:00 - 21:30)",
        "2177 6318",
        "\n",
        "天水圍天澤商場地下 12 號舖",
        "(星期一至日及公眾假期: 07:00 - 21:00)",
        "2486 3143",
        "\n",
        "新界荃灣綠楊坊 P5 舖",
        "(星期一至日及公眾假期: 07:00 - 21:00)",
        "2869 5838",
        "\n",
        "沙田沙田廣場3樓26A號舖",
        "(星期一至五: 07:30 - 21:00，星期六、日及公眾假期: 08:00 - 21:00)",
        "2884 9470",
        "\n",
        "屯門屯隆街1號錦薈坊1樓 103B號舖",
        "(星期一至星期五: 07:00 - 21:00，星期六﹑日及公眾假期: 08:00 - 21:00)",
        "2781 9028",
        "\n",
        "馬鞍山新港城中心二樓2002-3號舖",
        "(星期一至五: 07:00 - 21:00，星期六、日及公眾假期: 08:00 - 21:00)",
        "2984 8755"
    ]
}

export const bea_sthonore_mooncake_tac_tc_3: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "條款及細則",
    details: [
        "如任何款式因缺貨而未能提供予顧客，聖安娜餅店有權提供相似價格及種類的貨品以供替代。",
        "到達指定換領地點時, 客人必須出示換領短訊予職員以換領月餅。",
        "此優惠不能用以兌換現金或其他面值之餐飲禮券。",
        "此優惠只適用於指定日期，逾期無效。",
        "此優惠不可與其他優惠一併使用。",
        "如有任何爭議，聖安娜餅店保留此優惠之最終決定權。"
    ]
}

export const bea_sthonore_mooncake_tac_en_1: TitleDetail = {

    style_index: IndexStyle.none,
    title: "",
    details: [
        "Kindly present the collection SMS to collect your item in person at any Saint Honore retail shop (https://www.sthonore.com/en/locations) from 9th to 16th September, 2021 or at any of the following 26 Saint Honore bakeries from 17th to 19th September, 2021."
    ]
}

export const bea_sthonore_mooncake_tac_en_2: TitleDetail = {

    style_index: IndexStyle.none,
    title: "Collection Details:",
    details: [
        "Saint Honore Collection Locations",
        "\n",
        "Shop No. 1, Ground Floor, V Heun Building, 138 Queen's Road Central ",
        "(Mon to Sat: 07:00 - 21:00; Sun & Public Hols.: 08:30 - 20:30)",
        "2544 0544",
        "\n",
        "G/F, 11 Tung Sing Road, Aberdeen",
        "(Mon to Sun & Public Hols.: 07:00 - 21:00)",
        "2873 5881",
        "\n",
        "No. 15 Lan Fong Road, Ground Floor, Causeway Bay",
        "(Mon to Fri: 07:00 - 21:00; Sat, Sun, & Public Hols.: 08:00 – 20:30)",
        "2752 7706",
        "\n",
        "Shop No. 22 on Ground Floor, Coronet Court, North Point Road",
        "(Mon to Sun & Public Hols.: 07:00 - 21:00)",
        "2505 7318",
        "\n",
        "Shop No. 113b on Level 1 of New Jade Shopping Arcade, Chai Wan Inland Lot No. 120",
        "(Mon to Sun & Public Hols.: 07:00 - 21:00)",
        "2625 4831",
        "\n",
        "Shop G16, Whampoa Garden Site, Hung Hom",
        "(Mon to Sat: 07:00 - 21:00; Sun, & Public Hols.: 08:00 - 21:00)",
        "2334 0221",
        "\n",
        "Shop 10, Po Hei Court Shopping Centre, Po Hei Court, Sham Shui Po, Kowloon",
        "(Mon to Sun & Public Hols.: 07:00 - 21:00)",
        "2729 1240",
        "\n",
        "Shop No.4, Ground Floor of Fu Shing House, Fu Yan Street, Kowloon",
        "(Mon to Sun & Public Hols.: 07:00 - 21:00)",
        "2259 5485",
        "\n",
        "Portion of Ground Floor, No. 41 Pilkem Street, Kowloon",
        "(Mon to Sat: 07:00 - 21:00; Sun, & Public Hols.: 08:00 - 20:30)",
        "2419 2320",
        "\n",
        "G/F, No. 796 Cheung Sha Wan Road, Kln.",
        "(Mon to Sat: 07:00 - 20:30; Sun & Public Hols.: 09:00 - 18:00)",
        "2742 9771",
        "\n",
        "Shop 301, Level 3, Sceneway Plaza, Lam Tin, KLN",
        "(Mon to Fri: 06:30 - 21:00; Sat, Sun, & Public Hols.: 07:00 - 21:00)",
        "2340 4776",
        "\n",
        "Shop No.10 on Ground Floor, Hung Fai Building, Nos. 43P-43S Dundas Street, Kowloon",
        "(Mon to Sat: 07:00 - 21:00; Sun, & Public Hols.: 08:00 - 21:00)",
        "2783 7008",
        "\n",
        "Shop Nos. 2118, Second Floor, Commercial Centre, Lok Fu Estate, 198 Junction Road, Kowloon",
        "(Mon to Sun & Public Hols.: 06:45 - 21:00)",
        "2267 0012",
        "\n",
        "Shop G3B, G/F, Amoy Plaza, Phase I, 77 Ngau Tau Kok Road, Ngau Tau Kok",
        "(Mon to Sun & Public Hols.: 07:00 - 21:00)",
        "2795 0330",
        "\n",
        "Shop No. 1144, Level 1, Metro City Phase II, Tseung Kwan O, Town Lot No. 27",
        "(Mon to Sun & Public Hols.: 07:00 - 21:00)",
        "2465 9109",
        "\n",
        "Shop 2024, Level 2, D．Park, 398 Castle Peak Road, Tsuen Wan",
        "(Mon to Sat: 07:00 - 21:00; Sun & Public Hols.: 08:00 - 21:00)",
        "2528 6812",
        "\n",
        "Shop Nos. 610-611 on Level 1, Zone D, Tai Po Mega Mall, Tai Po",
        "(Mon to Fri: 07:00 - 21:00; Sat, Sun, & Public Hols.: 08:00 - 21:00)",
        "2660 7094",
        "\n",
        "Shop 8 on G/F, Cheung Fat Building, Yu King Square, Yuen Long",
        "(Mon to Sun & Public Hols.: 07:00 - 21:00)",
        "2441 5649",
        "\n",
        "MTR Station Concession No. TKO 20 at Tseung Kwan O Station",
        "(Mon to Sun & Public Hols.: 07:00 - 21:00)",
        "2392 2126",
        "\n",
        "Shop TAW 33 at MTR Tai Wai Station",
        "(Mon to Sun & Public Hols.: 07:00 - 21:00)",
        "2603 1797",
        "\n",
        "MTR Station Shop SHS 14 at Sheung Shui Station",
        "(Mon to Sun & Public Hols.: 07:00 - 21:30)",
        "2177 6318",
        "\n",
        "Shop 12 on the Ground Floor of Tin Chak Shopping Centre, Tin Shui Wai",
        "(Mon to Sun & Public Hols.: 07:00 - 21:00)",
        "2486 3143",
        "\n",
        "Shop Unit P5, Luk Yeung Galleria",
        "(Mon to Sun & Public Hols.: 07:00 - 21:00)",
        "2869 5838",
        "\n",
        "Shop No. 26A, Level 3, Shatin Plaza, Shatin, N.T., STTL 189",
        "(Mon to Fri: 07:30 - 21:00; Sat, Sun, & Public Hols.: 08:00 - 21:00)",
        "2884 9470",
        "\n",
        "Shop No. 103B on Level 1 of K-Point, No. 1 Tuen Lung Street, Tuen Mun",
        "(Mon to Fri: 07:00 - 21:00; Sat, Sun, & Public Hols.: 08:00 - 21:00)",
        "2781 9028",
        "\n",
        "Shop Nos. 2002-3 on Level 2 of MOSTown, Ma On Shan",
        "(Mon to Fri: 07:00 - 21:00; Sat, Sun, & Public Hols.: 08:00 - 21:00)",
        "2984 8755"
    ]
}

export const bea_sthonore_mooncake_tac_en_3: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "Terms and Conditions:",
    details: [
        "If products become out of stock, Saint Honore reserves the right to offer the customer alternative products of similar value and style.",
        "Kindly present the collection SMS when you arrive at any of the designated collection locations to collect your item.",
        "This offer is not exchangeable for cash or food and beverage vouchers in other denominations.",
        "This offer is valid during the stated period only, which cannot be extended.",
        "This offer cannot be used in conjunction with other promotional offers.",
        "Saint Honore reserves the right of final decision in case of any dispute arising from the promotion."
    ]
}

export const pacific_coffee_tandc_16_03_2022_tc: TitleDetail = {
    title: "條款及細則",
    details: [
        "此電子券持有人可於購買任何菜單上之手調飲品 之交易中作現金HK$25付款，餘款將不予退還 (如欲升級選用燕麥奶，每杯飲品需額外加$4)",
        "此電子券有效日期由即日起至2022年03月16日。請於有效日期前使用、逾期作廢",
        "此電子券適用於全線香港Pacific Coffee分店",
        "此電子券不可兌換現金，不適用於網站或應用程式內購物及外賣遞送服務",
        "於購買兩杯手調飲品或以上之交易，顧客可使用多於一張電子現金券，惟每張電子現金券只可於最多兩杯手調飲品之總值提供現金扣減",
        "於購買一杯手調飲品之交易，顧客只可使用一張電子現金券",
        "此電子券不可與其他優惠同時使用 （自攜盛器優惠除外）",
        "影印本恕不接受",
        "此電子券之二維碼只可使用一次",
        "此電子券如有遺失或損壞，恕不補發",
        "Pacific Coffee Co. Ltd. 將保留此優惠之最終決定權"
    ]
}

export const pacific_coffee_tandc_16_03_2022_en: TitleDetail = {
    title: "Terms & Conditions:",
    details: [
        "This e-voucher entitles the holder to settle payment as HK$25 upon any purchase of handcrafted beverage on our menu and unused value will be forfeited (an additional $4 will be charged per beverage to upgrade to oat milk).",
        "This e-voucher is valid until March 16, 2022, and expiry date is non-extendable.",
        "This e-voucher is redeemable at all Pacific Coffee in Hong Kong.",
        "This e-voucher cannot be exchanged for cash or its equivalent and is not applicable to in-app purchase and delivery services.",
        "For the transaction of two or above beverages, more than one e-vouchers can be used and each e-voucher can be used to settle payment for a maximum of two beverages",
        "For the transaction of one beverage, only one e-voucher can be used.",
        "This e-voucher cannot be used in conjunction with any other promotions or privileges (except bring-your-own-mug discount)",
        "Photocopies of this voucher will not be accepted.",
        "The QR code on this e-voucher is valid for one-time use only.",
        "This e-voucher will not be re-issued in case of loss or damage.",
        "Pacific Coffee Co. Ltd. reserves the right of final decision in case of disputes."
    ]
}

export const atp_tac_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "This offer cannot be redeemed for cash.",
        "AT Rewards reserve the final right of decision on all matters concerning the use of this offer.",
        "This offer cannot be used in conjunction with other discounts or promotional offers.",
        "This offer is valid until the expiry date stated and is not refundable upon expiry.",
        "Cash Voucher cannot combine with other promotion."
    ]
}

export const atp_tac_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則:",
    details: [
        "此優惠不可兌換現金或其他產品。",
        "如有任何爭議，AT Rewards保留一切有關行使之最終決定。",
        "此優惠不可與其他折扣及優惠同時使用。",
        "優惠需要於指定有效日期前使用，逾期不設退款。",
        "不可與其他優惠同時使用。"
    ]
}

export const godiva_tac_en: TitleDetail = {
    style_index: IndexStyle.bullets,
    title: "Terms & Conditions:",
    details: [
        "This voucher entitles the bearer to exchange the equivalent amount for goods at any GODIVA shops (excluding Airport, Duty Free Outlets and Department Stores) in Hong Kong on or before the expiry date.",
        "This voucher shall be invalid after the expiry date.",
        "This voucher will not be accepted by phone, fax or through Internet.",
        "This voucher (or any part thereof) may not be redeemed for cash.",
        "The bearer will not be entitled to any refund or exchange or change in cash.",
        "This voucher is invalid if found defaced or damaged.",
        "This voucher cannot be used in conjunction with GODIVA VIP Card or other promotional offers.",
        "This voucher is valid only with authorized stamp.",
        // "This cash coupon is not applicable on the following days: December 21-27, 2021, January 25-31, 2022, February 12-14, 2022",
        "GODIVA Chocolatier (Asia) Limited reserves the right of final decision in case of any dispute arising from this voucher."
    ]
}

export const godiva_tac_tc: TitleDetail = {
    style_index: IndexStyle.bullets,
    title: "條款及細則:",
    details: [
        "此禮券持有人可在本券有效期內於任何香港GODIVA專門店（除機場，免稅及百貨公司）換取同等價值貨品，逾期無效。",
        "此禮券不適用於電話，傳真或網上購物。",
        "此禮券（或其部份面值）不能用以套换現金。",
        "持有人不得折回現金或以此禮券兌換現金此禮券如有塗污，塗改或損毀毁，則予作廢。",
        "不可與GODIVA Chocolatier VIP咕或其他推廣優惠一併使用。",
        "此禮券需有授權印鑑方為有效。",
        // "此現金券於以下日子不適用: 2021年12月21-27日, 2022年1月25-31日, 2022年2月12-14日",
        "如有任何爭議，GODIVA Chocolatier（Asia）Limited保留最終決定權。"
    ]
}


export const godiva_tac_en_30112023: TitleDetail = {
    style_index: IndexStyle.bullets,
    title: "Terms & Conditions:",
    details: [
        "This GODIVA e-voucher valid from 23/11/2022 to 30/11/2023 only.",
        "This GODIVA e-voucher entitles the bearer to exchange the equivalent amount for goods at any GODIVA shops* (*excluding Airport, Duty Free Outlets, Department Stores, Kiosks) in Hong Kong on or before the expiry date. This e-voucher shall be invalid after the expiry date.",
        "This e-voucher is valid for one-time use only and GODIVA’s system record shall be final & binding.",
        "No photocopy and screen captured copy are allowed.",
        "This e-voucher will not be accepted for purchase by phone, fax, or through internet.",
        "This e-voucher (or any part thereof) may not be redeemed for cash. The bearer will not be entitled to any refund or exchange or change in cash.",
        "This e-voucher cannot be used in conjunction with GODIVA VIP Card or other promotional offers.",
        "Godiva Chocolatier (Asia) Limited reserves the right of final decision in case of any dispute arising from this e-voucher.",
        "This cash coupon is not applicable on the following days:",
                "2023 Dec 19 - 25",
                "2024 Feb 3 - 9",
                "2024 Feb 10 - 14",
                "2024 Sep 13 - 17",
                "2024 Dec 19 - 25",
        "For shop details, pls visit: https://www.godiva.com.hk/boutiques.html"
    ]
}

export const godiva_tac_tc_30112023: TitleDetail = {
    style_index: IndexStyle.bullets,
    title: "條款及細則:",
    details: [
        "此GODIVA電子禮券有效期為2022年11月23日至2023年11月30日。",
        "此禮券持有人可在本券有效期內於任何香港GODIVA專門店 (機場、免稅店、祟光銅鑼灣百貨公司及期間限定店除外) 換取同等價值貨品，逾期無效。",
        "此電子禮券只可使用一次並以GODIVA的系統記錄為準。",
        "影印本及螢幕截圖恕不接納。",
        "此電子禮券不適用於電話、傳真或網上購物。",
        "此電子禮券 (或其部分面值) 不能用以套取現金。持有人不得折回現金或以此電子禮券兌換金",
        "此電子禮券不可與GODIVA VIP咭或其他推廣優惠一併使用。",
        "如對此電子禮券有任何爭議，Godiva Chocolatier (Asia) Limited保留最終決定權。",
        "此現金券於以下日子不適用: ",
            "2023 年12月19 - 25 日",
            "2024 年2月3 - 9 日",
            "2024 年2月10 - 14日",
            "2024 年9月13 - 17日",
            "2024 年12月19 - 25 日",
        "有關專門店資料，請瀏覽https://www.godiva.com.hk/boutiques.html"
    ]
}

export const godiva_24pcs_tac_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則",
    details:[
        "本禮遇推廣期由 2022 年 11 月 21 日至 2022 年 12 月 31 日（包括首尾兩日）（「推廣期」）。\n\t星展「星展豐盛理財」及「DBS Account」客戶（「客戶」） 於活動期間 於星展銀行（香港）有限公司（「本行」）與理財策劃經理完成理財分析將合資格領取 Godiva片裝巧克力禮盒24片裝電子換領禮券乙張（「禮遇」）。",
        "本行保留對客戶是否合資格客戶之最終決定權。",
        "「星展豐盛理財」及「DBS Account」是星展銀行（香港）有限公司 DBS Bank (Hong Kong) Limited（「本行」）的客戶層之一。「客戶層」指 DBS Account、星展豐盛理財、星展豐盛私 人客戶、星展私人銀行及本行不時提供的任何其他客戶層。在香港，星展私人銀行為星展銀 行(香港)有限公司的私人銀行部門。",
        "客戶於推廣期內簽署客戶禮遇簽收回條（「簽收回條」）後，方可換領禮遇。合資格客戶須於換領禮遇時為星展客戶。",
        "客戶簽署簽收回條後 ，本行將於以下日期以短信型式發送禮遇到客戶於本行登記的手提電話號碼。\n\tAppointment Period 1: 21 Nov - 30 Nov 2022, Fulfillment date (SMS send-out date) On or before 9 Dec 2022\n\tAppointment Period 2: 1 Dec - 14 Dec 2022, Fulfillment date (SMS send-out date) On or before 23 Dec 2022 \n\tAppointment Period 3: 15 Dec - 31 Dec 2022, Fulfillment date (SMS send-out date) On or before 13 Jan 2023",
        "禮遇必須於2023年2月28日前兌換，否則將被作廢。禮遇不設退換及轉讓，亦不能兌換現金或其他奬品。本行可以其他相等價值之獎賞/獎 品代替而毋須事先通知。",
        "本行對於電子換領禮券的供應商所提供的產品及服務質素概不承擔任何責任。電子換領禮券受供應商之使用條款及細則約束。",
        "每位客戶只可享本禮遇上限一次。",
        "此推廣優惠並不適用於本行的員工。",
        "合資格客戶在本禮遇中必須不涉及任何濫用/違規，方可參加此禮遇，否則本行不會向合資格客戶派發禮券。本行亦可在合資格客戶的戶口內扣除禮券的等值金額而不事先通 知及/或採取行動追討任何未償付金額。",
        "領取禮券及簽署客戶禮遇簽收回條的客戶將被視作已閲讀、明白並同意以上之條款及細則。",
        "本行可更改/終止本禮遇而無須另行通知。本行的決定為最終定論。",
        "如條款及細則的中英文版本有任何歧異，將以英文版本為準。"
    ]
}

export const godiva_24pcs_tac_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Program Terms & Conditions",
    details:[
        "The Program period is from 21 November 2022 to 31 December 2022 (both dates inclusive) (the “Program Period”). \n\tDBS Treasures and DBS Account customers (“Customers”) who during the Program Period complete the Financial Needs Analysis with a Financial Planning Manager of DBS Bank (Hong Kong) Limited (the “Bank”) at the Bank’s branches will be entitled to a Godiva Assorted Chocolate Carré Collection 24 pcs e-Gift voucher (“Gift”).",
        "The Bank’s decision on whether a Customer is entitled to any Gift is final.",
        "“DBS Treasures” and “DBS Account” are Customer Segments of the Bank. “Customer Segment” means DBS Account, DBS Treasures, DBS Treasures Private Client, DBS Private Bank and any other segment made available by the Bank from time to time. In Hong Kong, DBS Private Bank is the private banking division of the Bank.",
        "Customer is required to sign the Customer Acknowledgement Receipt Form (“CAR Form”) during the Program Period to redeem the Gift. Customer must be a DBS customer at the time any of the Gift is redeemed. ",
        "After signing the CAR Form, an SMS with the Gift redemption code will be sent to the Customer’s corresponding Hong Kong mobile number in the Bank’s record based on the fulfilment schedule below. \n\tAppointment Period 1: 21 Nov - 30 Nov 2022, Fulfillment date (SMS send-out date) On or before 9 Dec 2022\n\tAppointment Period 2: 1 Dec - 14 Dec 2022, Fulfillment date (SMS send-out date) On or before 23 Dec 2022 \n\tAppointment Period 3: 15 Dec - 31 Dec 2022, Fulfillment date (SMS send-out date) On or before 13 Jan 2023",
        "The Gift must be redeemed on or before 28 February, 2023, otherwise the Gift will be forfeited. The Gift is non-exchangeable, non-transferrable and non-redeemable for cash or other rewards. The Bank reserves the right to replace and substitute the Gift with another gift of a similar value at any time and without prior notice.",
        "The Bank shall not be responsible or liable for any consequence in respect of the quality, supply and use of the Gift. The use of the Gift is subject to the terms and conditions imposed thereon by the merchant. Any enquiry or complaint must be made to the merchant directly.",
        "Customer can only enjoy the Gift once.",
        "The Bank’s staff is not eligible to participate in the Program. ",
        "Participation in the Program is subject to there being no abuse/non-compliance by the Customer failing which the Bank will not offer the Gift to the Customer or where the Gift has been given to the Customer, the Bank may debit the value of the Gift from the Customer’s account without notice and/or take such action to recover any outstanding amounts.",
        "By redeeming the Gift and signing the CAR Form, Customers shall be deemed to have read, understood, and agreed to be bound by these Terms and Conditions.",
        "The Bank may change the terms and conditions and/or modify/terminate the Program without notice. The Bank’s decision is final.",
        "Should there be any discrepancy between the English and Chinese versions of the contents contained in the Terms and Conditions, the English version shall prevail.",
    ]
}

export const godiva_24pcs_tac_locations_en: TitleDetail = {
    title: "Godiva Store Locations",
    details:[
        "Cityplaza - Shop 213 & 221, Cityplaza, Quarry Bay Hong Kong",
        "Elements - Shop 1097, First Level, Elements, 1 Austin Road, Tsim Sha Tsui Kowloon",
        "Olympian City 2 - Shop Area A, UG/F, Olympian City 2, 18 Hoi Ting Road, Olympian, Kowloon",
        "MOKO - Shop M25-M26, MTR Floor, MOKO, 193 Prince Edward Road West, Kowloon",
        "Festival Walk - Shop LG1-62, Festival Walk, 80 Tat Chee Ave, Kowloon Tong Kowloon",
        "Telford Plaza - Shop G33, Telford Plaza, 33 Wai Yip Street, Kowloon Bay Kowloon",
        "PopCorn - Shop Unit F15, PopCorn, 9 Tong Yin Street, Tseung Kwan O New Territories",
        "V City - M-75, MTR Level, V city, 83 Tuen Mun Heung Sze Wui Road, Tuen Mun New Territories",
        "Maritime Square - Shop 133-134, Maritime Square, 33 Tsing King Rd, Tsing Yi New Territories",
        "Citywalk - Shop UG79, Citywalk, No. 1 Yeung Uk Road, Tsuen Wan New Territories",
        "YOHO Mall - Shop 1116A, Yoho Mall I, 8-9 Long Yat Road & 9 Yuen Lung Street, Yuen Long New Territories"
    ]
}

export const store759_tac_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "使用條款及細則",
    details: [
        "本券持有人可在本券有效期內於 759 阿信屋分店換取同等價值貨品，逾期無效。",
        "如購買價值超過本券面額之產品，顧客須補回有關之差額。",
        "本券適用於 759 旗下經營零售產品之分店。(合作店、759 麵包工房除外)。",
        "請於結賬時向收銀員出示此券。",
        "本券只限使用一次。使用後，此券會即時失效，不能重覆使用，並以 759 阿信屋紀錄為準。",
        "759 阿信屋不對任何遺失或被竊的現金券負責。該現金券持有人將負責現金券的使用和安全，並且對現金券的所有交易負責。",
        "此券不可退款，全部或部份兌換現金，或換取其他產品或折扣優惠。",
        "此券不能用以兌換現金或其他面值之 759 阿信屋禮券。持有人不獲現金或其他形式找續。",
        "如有任何爭議，一概以 759 阿信屋的最終決定為準。"
    ]
}

export const store759_tac_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms and Conditions",
    details: [
        "The coupon may be used to exchange for an equivalent amount of goods or services at 759 Store on or before the expiry date stated on the coupon. The coupon shall be invalid after the expiry date.",
        "Where the cost of purchase exceeds the available coupon balance, the coupon holder will be required to make up the difference between the purchase price and coupon balance.",
        "The coupon can be used in 759 retail outlets. (759 Bakery and co-operative store is not applicable)",
        "Kindly present your coupon to cashier for redemption.",
        "Each coupon can be used once only. 759 Store reserves the right of final decision in case of any dispute arising from any coupon.",
        "759 Store disclaims responsibility for any lost or stolen coupon. The coupon holder is responsible for the use and safety of the coupon and is liable for all transactions on the coupon.",
        "The coupon is non-refundable and is not exchangeable for cash, other offers or other coupons.",
        "No change will be given either in cash or in other form when using the coupon in your purchase.",
        "759 Store reserves the right to make the final decision in case of any dispute and may change the terms and conditions without prior notice.",
    ]
}

export const eugenebaby_tac_04032022_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "使用條款及細則",
    details: [
        "請於付款前出示此電子劵。",
        "以此電子劵換取貨品時, 需以貨品之原價計算, 不可與其他優惠同時使用。",
        "本電子劵不可與荷花會員折扣及優惠同時使用。",
        "本電子劵不適用於公價貨品。",
        "本電子劵可於任何荷花親子店門店換取同等價值之貨品，逾期無效。",
        "本電子劵不能兌換現金及不設找贖。",
        "本電子劵不可用作購買或兌換禮券。",
        "本電子劵有效日期至2022年3月4日。",
        "如有任何爭議，荷花集團保留最終決定權。",
    ]
}

export const eugenebaby_tac_04032022_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms and Conditions",
    details: [
        "Please present this e-Voucher before payment.",
        "This e-Voucher can only redeem goods at Original Price and cannot be used in conjunction with any other discount offer.",
        "This e-Voucher cannot be used in conjunction with Eugenebaby member card and any other discount offer.",
        "This e-Voucher is not applicable to fixed price items.",
        "This e-Voucher may redeem the equivalent amount of goods at Eugenebaby stores within the validity period.",
        "This e-Voucher cannot be exchanged for cash and no change will be given.",
        "This e-Voucher cannot be used for the purchase of or exchange for gift certificates.",
        "This e-Voucher valid until 4th March 2022.",
        "Eugene Group reserves the right of all final decisions.",
    ]
}

export const eugenebaby_tac_30062023_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "使用條款及細則",
    details: [
        "請於付款前出示此電子劵。",
        "以此電子劵換取貨品時, 需以貨品之原價計算, 不可與其他優惠同時使用。",
        "本電子劵不可與荷花會員折扣及優惠同時使用。",
        "本電子劵不適用於公價貨品。",
        "本電子劵可於任何荷花親子店門店換取同等價值之貨品，逾期無效。",
        "本電子劵不能兌換現金及不設找贖。",
        "本電子劵不可用作購買或兌換禮券。",
        "本電子劵有效日期至2023年6月30日。",
        "如有任何爭議，荷花集團保留最終決定權。",
    ]
}

export const eugenebaby_tac_30062023_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms and Conditions",
    details: [
        "Please present this e-Voucher before payment.",
        "This e-Voucher can only redeem goods at Original Price and cannot be used in conjunction with any other discount offer.",
        "This e-Voucher cannot be used in conjunction with Eugenebaby member card and any other discount offer.",
        "This e-Voucher is not applicable to fixed price items.",
        "This e-Voucher may redeem the equivalent amount of goods at Eugenebaby stores within the validity period.",
        "This e-Voucher cannot be exchanged for cash and no change will be given.",
        "This e-Voucher cannot be used for the purchase of or exchange for gift certificates.",
        "This e-Voucher valid until 30th June 2023.",
        "Eugene Group reserves the right of all final decisions.",
    ]
}

export const echome_tac_240320222_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "使用細則：",
    details: [
        "此現金券有效期至2022年3月24日，電子優惠券發出後及逾期不設退款。",
        "憑劵到億世家分店可當作HKD$50使用。",
        "此現金券適用於全港億世家分店 ，不適用於APP或網店。",
        "此現金券不能兌換現金。",
        "請於結帳前出示此劵，如已開立收據，請於下次消費使用。",
        "超過面額部分需要補回差價，不足卷面部分不得找零。",
        "此現金券限用一次。",
        "如有任何爭議，億世集團有限公司保留最終決定權。",
    ]
}

export const echome_tac_24032022_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "This coupon is valid before 24/03/2022.",
        "This coupon can be used as $50 in any ecHome offline stores.",
        "This coupon can only use in ecHome offline stores, cannot be used in ecHome APP or eShop.",
        "This coupon cannot be exchanged for cash.",
        "Present this coupon before settlement. The coupon is not acceptable if a receipt issued.",
        "The price difference needs to be made up for the part that exceeds the denomination, and no change is allowed for the part that is insufficient.",
        "This coupon can only use once.",
        "In case of dispute, E.P.S.A. Corporation Ltd reserves the right to make the final decision.",
    ]
}

export const bossini_tac_31032022_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "使用條款及細則",
    details: [
        "憑此券於有效期內到香港及澳門bossini分店可作港幣$100或葡幣$100使用",
        "此券有效期為2021年3月1日至2022年3月31日",
        "此券不可兑換現金及不設找贖",
        "此券只可使用—次",
        "影印本及任何損毀或被塗污之現金券，恕不接受",
        "此券不適用於八達通增值服務、禮品換購及購買bossini現金禮物卡",
        "此券如有遺失，bossini恕不負責",
        "bossini保留隨時更改及終止此優惠之權利，如有任何爭議，bossini保留最終決定權",
    ]
}

export const bossini_tac_31032022_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms and Conditions",
    details: [
        "This voucher offers a HK$100 or MOP$100 with any purchase at Hong Kong or Macau bossini shops",
        "This voucher is only valid during 1 Mar 2021 — 31 Mar 2022",
        "This voucher is not redeemable for cash",
        "This voucher can be used once only",
        "This voucher is invalid if found defaced, damaged or photocopied",
        "This voucher is not applicable to octopus value-added service, premium redemption and purchase of bossini gift card",
        "bossini accepts no responsibilty for lost or stolen voucher",
        "bossini reserves the right to alter or terminate the offer at any time without notice, in case of any dispute, the decision of bossini shall be final",
    ]
}

export const foodpanda_tandc_15_04_2022_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則:",
    details: [
        "不設最低訂單金額。",
        "優惠券適用於在foodpanda應用程式或foodpanda網站上兌換（https://www.foodpanda.hk和 http://corporate.foodpanda.hk）",
        "此現金券不適用於送貨費。",
        "不允許將優惠券兌換成其他形式的商品，服務，現金或任何等價貨幣。",
        "每個優惠券代碼只能使用一次，並且每個交易只能使用一個優惠券。",
        "現金券有效期至15-04-2022，逾期不設退款或更換。",
        "如有任何爭議，foodpanda保留一切有關行使之最終決定。",
    ]
}

export const foodpanda_tandc_15_04_2022_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "No minimum order value is required.",
        "The voucher is applicable for redemption in-app or on foodpanda website (https://www.foodpanda.hk & http://corporate.foodpanda.hk)",
        "The voucher is not applicable to the delivery fee.",
        "The voucher is not allowed to be exchanged for other forms of goods, services, cash or any monetary equivalent.",
        "Each voucher code can only be used once, and only one voucher can be used for each transaction.",
        "The voucher will be valid till 15-04-2022 (or less depending on the agreement with customer) and is not refundable or exchangeable.",
        "foodpanda reserves the right of final decision and interpretation in the case of any dispute."
    ]
}

export const hktv_tandc_tc: TitleDetail = {
    style_index: IndexStyle.bullets,
    title: "條款及細則:",
    details: [
        "必須登入帳戶方可兌換 HKTVmall 電子購物禮券。",
        "此電子購物禮券只適用於www.hktvmall.com或HKTVmall手機應用程式作網上購物(不適用於HKTVmall O2O 店內購物，HKTV Lite App 網上購物，HKTVpay付款及HKTV極速送之訂單或其他附加應用程式 / 服務)。",
        "此購物禮券換領代碼只可換領指定數量及額值之 HKTVmall 電子購物禮券。",
        "此購物禮券換領代碼只可兌換一次。",
        "此購物禮券換領代碼一經兌換及確認，電子購物禮券將被發送至進行兌換的帳戶；兌換後之購物禮券並不可轉讓至其他戶口。",
        "此購物禮券不適用於購買 HKTVmall 電子購物禮券，自願醫保計劃及人壽保險。",
        "此禮券換領代碼不可兌換現金或Mall Dollar現金回贈。",
        "此購物禮券換領代碼並不可用作支付所有運費及商戶派送之附加費。",
        "付款總額若少於購物禮券的面值，餘數會被自動扣除，不可兌換現金或Mall Dollar現金回贈。",
        "每張訂單可使用最多10張購物禮券。",
        "香港電視購物網絡有限公司不會因人為錯誤 (如丟失或被盜) 而重發換領代碼或購物禮券。",
        "此購物禮券須受有關條款及細則約束。",
        "如有爭議，香港電視購物網絡有限公司保留最終決定權。",
        "(2021年9月29日更新)"
    ]
}

export const hktv_tandc_en: TitleDetail = {
    style_index: IndexStyle.bullets,
    title: "Terms & Conditions:",
    details: [
        "You must log in to HKTVmall account to redeem the HKTVmall vouchers.",
        "This e-Gift voucher can only be used for online purchase at HKTVmall through its website (www.hktvmall.com) or mobile applications. (This e-Gift voucher is not applicable on shopping in HKTVmall O2O shop, online shopping at HKTV Lite, payment by HKTVpay, transactions made at HKTVexpress, or any add on app or service)",
        "The redemption code can only redeem the assigned quantity and amount of HKTVmall vouchers.",
        "The redemption code can only be redeemed once.",
        "Once the redemption code is redeemed successfully, the HKTVmall e-Gift vouchers will be sent into your account and it cannot be transferred to other accounts.",
        "This e-Gift voucher cannot be used to purchase HKTVmall e-Gift voucher, Voluntary Health Insurance Scheme (VHIS) and life insurance products.",
        "The redemption code/ e-Gift voucher cannot be exchanged to cash or Mall Dollar cash rebate.",
        "The redemption code cannot be used to settle any delivery charges and/ or extra charges arising from merchant delivery.",
        "No refund or exchange for Mall Dollar will be made for any unused balance of the e-Gift voucher.",
        "Maximum of 10 e-Gift vouchers can be used in each order.",
        "Hong Kong TV Shopping Network Company Limited is not responsible for lost or stolen of the redemption code or e-Gift voucher.",
        "Terms and conditions is applied for the HKTVmall e-Gift voucher.",
        "In case of any disputes, Hong Kong TV Shopping Network Company Limited reserves the right to make the final decision.",
        "(Updated on 29 September, 2021)"
    ]
}

export const HealthWorks_10_tac_tc: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "條款及細則",
    details: [
        "憑此券於健康工房香港分店，五季甜悅及五季禪食分店購買任何正價產品，即可作HK$10現金禮券使用。",
        "如以此券付款，所有產品需以正價計算，產品包括健康茶、滋補湯、藥膳糖水、龜苓膏、土茯苓膏、健康小食、生態農產品及生活精品。",
        "此券不適於購買套券及套餐優惠。",
        "此券不能退款或兌換現金，並不設找續，亦不可與其他優惠同時使用。",
        "此券只適用於健康工房香港分店、五季甜悅及五季禪食分店。",
        "所有電子禮券只可使用一次。",
        "請於付款前出示此券以享優惠。",
        "此券如經塗改、損毀或遺失，則予作廢，恕不補發。",
        "此券不可轉售，亦不延期，逾期作廢。",
        "本公司保留隨時修改行使此券之條款及細則，並毋須作另行通知。",
        "如有任何爭議，健康工房(集團)有限公司保留行使此券之最終決定權。"
    ]
}

export const HealthWorks_10_tac_en: TitleDetail = {

    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "This coupon entitles the holder to purchase HealthWorks products valued at HK$10 at all HealthWorks Shops in Hong Kong.",
        "All products are calculated at original price, products include herbal tea, herbal soup, dessert, herbal jelly, fresh Tu Fu Ling herbal jelly, healthy snack and eco-farming products.",
        "This coupon is not applicable to coupon set and other set promotional offers.",
        "This coupon cannot be exchanged for cash and cannot be used in conjunction with discount coupons and any other promotional offers.",
        "This offer is only applicable at HealthWorks, Health in Five Seasons and Zen in Five Seasons stores.",
        "Each coupon can be used once only and only one coupon can be used per purchase.",
        "Please present this coupon before payment.",
        "If coupon are lost, stolen or damaged, no replacement or refund will be granted.",
        "This offer is valid until the expiry date stated and is non-refundable upon expiry.",
        "HealthWorks Group Holdings Limited (“HealthWorks”) reserves the right to amend the terms and conditions of this offer without any notice.",
        "If there is any dispute, the decision of HealthWorks shall be final."
    ]
}

export const foodpanda_tandc_31_03_2022_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則:",
    details: [
        "不設最低訂單金額。",
        "優惠券適用於在foodpanda應用程式或foodpanda網站上兌換（https://www.foodpanda.hk和 http://corporate.foodpanda.hk）",
        "此現金券不適用於送貨費。",
        "不允許將優惠券兌換成其他形式的商品，服務，現金或任何等價貨幣。",
        "每個優惠券代碼只能使用一次，並且每個交易只能使用一個優惠券。",
        "現金券有效期至31-03-2022，逾期不設退款或更換。",
        "如有任何爭議，foodpanda保留一切有關行使之最終決定。",
    ]
}

export const foodpanda_tandc_31_03_2022_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "No minimum order value is required.",
        "The voucher is applicable for redemption in-app or on foodpanda website (https://www.foodpanda.hk & http://corporate.foodpanda.hk)",
        "The voucher is not applicable to the delivery fee.",
        "The voucher is not allowed to be exchanged for other forms of goods, services, cash or any monetary equivalent.",
        "Each voucher code can only be used once, and only one voucher can be used for each transaction.",
        "The voucher will be valid till 31-03-2022 (or less depending on the agreement with customer) and is not refundable or exchangeable.",
        "foodpanda reserves the right of final decision and interpretation in the case of any dispute."
    ]
}

export const cafe100_tandc_30_04_2022_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則:",
    details: [
        "此券適用於亞洲國際餐飲集團旗下百份百餐廳/意樂/韓樂/Itamama所有分店、任何時段惠顧。",
        "請於付款前出示此券。",
        "每張單據可使用多於一張現金券。",
        "此券不設找贖，不可兌換現金、轉售及退換。",
        "此券如經損毀、塗改、水浸或以其他方式加以改變，即屬無效，並不獲補發。",
        "此券不可與其他優惠（包括員工優惠）一同使用。",
        "此券不適用於購買其他現金券、節日食品或學生套餐。",
        "如有任何爭議，亞洲國際餐飲集團保留最終決定權。",
        "有效期至: 30/04/2022",
    ]
}

export const cafe100_tandc_30_04_2022_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "This coupon is applicable to consumption at any Café 100%/Itamomo/Momoku/Itamama of Taste of Asia Group Limited at any period of time.",
        "This coupon shall be presented before payment.",
        "More than one coupon can be used per transaction.",
        "This coupon is non-redeemable for cash, non-transferable and non-refundable. No change will be given either in cash or in other form.",
        "Damaged or altered coupon will be deemed invalid and will not be re-issued.",
        "This coupon cannot be used in conjunction with other promotional offer (including staff discount).",
        "This coupon is not applicable to purchase of any cash coupon, festive food or item from student menu.",
        "In case of dispute, Taste of Asia Group Limited shall reserve the right of final decision.",
        "This coupon is valid until: 30/04/2022",
    ]
}

export const oceanpark_tandc_12052022_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "香港海洋公園成人門票之條款及細則",
    details: [
        "2人入場套票：此為海洋公園入場門票（有效期至2022年5月12日，公園休園日（2021年9月7日起逢星期二直至另行通知）除外)。此為“2人入場套票”，套票包括兩位成人電子門票。",
        "閣下及一名同行者必須透過海洋公園官方網站提前預約入園日期。閣下可選擇14天內的入園日期，網址: https://www.oceanpark.com.hk/tc。成功登記後,系統將即時顯示載有預約二維碼的確認訊息,閣下亦會收到相關確認電郵，入園時須同時出示預約二維碼及登記之門票。 兩位客人必須同時抵達本園入口處，並憑此票（含一個二維碼）透過智能手機出示或憑 A4 列印本進場。閣下及一名同行者可憑此門票（透過智能手機出示或憑A4列印本）於海洋公園入口處進場。此門票不得退款或退換、只供兩位遊客及只可使用一次。",
        "香港居民持此優惠門票可於遊園當日升級至「無限FUN入場證」(有效期由購買日起至2022年2月28日)，升級時須支付指定金額(成人證: 港幣$300)；日間門票升級換購「無限FUN入場證」，須受有關條款及細則約束。",
        "此門票包括享用園內的機動遊戲、設施及展館；但不適用於園內攤位遊戲、須用輔幣操作的遊戲、收費活動或設施等。此門票只供同二位遊客於有效期內使用。",
        "海洋公園會因維修，保養，惡劣天氣或其他理由，在任何時間內關閉園內機動遊戲或景點而不作事先通知。遊客根據前述情況所作出的退款要求，不論全數或部份款項，將不作受理。此乃本園門票售賣條款之一。",
        "若有惡劣天氣，包括但不限於八號風球或以上、黑色暴雨等，海洋公園可在通知或不通知公眾的情況下關閉公園。",
        "若公園在場人數達到最多能容納的水平，海洋公園會控制入場人數，閣下可能未獲接納入場。入場須遵守公園入口處及/或其他適當位置所示之海洋公園附例、安全規則及公園守則。",
        "海洋公園對公園內任何人士之財物的損失或損害概不負責。如有任何爭議，將以海洋公園之最後決定為準。",
        "請留意：請小心保管此門票，切勿損壞或弄濕，特別是入場時必須掃描的二維碼。此門票一經修改或損毀即作廢。海洋公園對遺失門票恕不負責。閣下在入場當日可能須要出示適當身份証明文件。",
        "除持卡人及本行以外，並無其他人士有權按《合約（第三者權益）條例》（香港法例第623章）強制執行此等條款及細則的任何條文，或享有此等條款及細則的任何條文下的利益。",
        "東亞銀行不會對香港海洋公園的貨品、服務或資料之質素和供應作出任何陳述或保證，亦不會就香港海洋公園的貨品、服務或資料所引起或與其有關的事宜負上任何責任。如有查詢或投訴，持卡人應直接聯絡香港海洋公園。",
        "本行保留隨時更改或取消優惠及/或修改或修訂此等條款及細則之權利，並作出適當的通知。如有任何爭議，本行所作的決定為最終及不可推翻。"
    ]
}

export const oceanpark_tandc_12052022_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms and conditions of Ocean Park Hong Kong Adult General Admission Ticket",
    details: [
        "2 Adult Admission Package: This is an admission ticket (valid till 12 May 2022, except the Park closures every Tuesday from 7 September 2021 until further notice) to Ocean Park. The package includes two adult daytime admission tickets at a QR code.",
        "You and your companion are required to make reservation online for your date of visit up to 14 days in advance (Website: https://www.oceanpark.com.hk/en). Upon successful registration, you will get instant confirmation with a reservation QR code on-screen, followed by an email notification. You and your companion simply need to present the reservation QR codes together with your ticket for Park admission. Two guests must arrive at the admission turnstile at the same time and present this ticket inclusive of a QR code via Smartphone or on an A4 size paper at the entrance of Ocean Park for admission. This ticket is non-refundable, non-exchangeable, two guests admission turnstile at the same time and can be used once only.",
        'Hong Kong residents holding this promotional ticket can upgrade to “InFUNity Entry Pass” (valid from purchase date until 28 February, 2022) on the day of your admission by paying the price (Adult pass: HK$300); Other Terms and Conditions apply for the upgrade of Day time Admission Ticket to a “InFUNity Entry Pass”.',
        "This ticket entitles you and your companion to all operating rides, facilities and exhibits, except skill games, coin-operated games, special paid events and facilities being held in the Park. This ticket must be used by the same two persons during its period of validity.",
        "For repair, maintenance, inclement weather or other reason, Ocean Park may suspend the operation of any ride or close any attraction at any time without prior notice. It is a condition of sale of the Park’s admission ticket that any request for refund, whether partial or in full, for the tickets purchased by visitors will not be considered by the Park as a result of such ride suspension or closure of attraction.",
        "In case of inclement weather, including but without limitation to the hoisting of tropical cyclone signal no. 8 or above or black rainstorm warnings, Ocean Park may close the Park with or without prior notice to the public.",
        "In case the Park reaches its maximum capacity, Ocean Park may exercise admission control and you may not be allowed for entry. A condition of entry is to comply with Ocean Park Bylaw, safety rules and Park Rules and Regulations which are displayed at the entrances and / or other appropriate locations of the Park.",
        "Ocean Park shall not be responsible for any loss of any kind or any damage to any property of any person within the Park. In the event of dispute, the decision of Ocean Park will be final.",
        "Please note: Please protect this ticket, do not damage or wet it, especially the QR code which must be scanned at the entrance. This ticket will be void if altered or defaced. Ocean Park accepts no responsibility for any lost ticket. Suitable identification may need to be provided on the day of visit.",
        'No person other than the cardholder or The Bank of East Asia, Limited (“BEA”) will have any right under the Contracts (Rights of Third Parties) Ordinance (Cap. 623 of the Laws of Hong Kong) to enforce or enjoy the benefit of any of the provisions of these Terms and Conditions.',
        "BEA makes no representation or guarantee as to the quality and availability of the products, services, or information provided by Ocean Park Hong Kong. BEA shall not be liable for any matters arising from or in connection with the products, services, or information provided by Ocean Park Hong Kong. Cardholders should direct any queries or complaints to Ocean Park Hong Kong.",
        "BEA reserves the sole right to vary or cancel the Offer and/or amend or alter these Terms and Conditions at any time with appropriate notice. In the event of any dispute, the decision of BEA shall be final and conclusive.",
    ]
}

export const deliveroo_tandc_11_11_2022_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則:",
    details: [
        "於 www.deliveroo.hk 或戶戶送應用程式註冊並在結賬前輸入戶戶送電子優惠碼。",
        "你只需輸入優惠碼一次，其面值或餘額（如有）便會被存入你的戶戶送帳戶。",
        "有效日期至2022年11月11日。",
        "優惠碼不能兌換現金及不設退款。",
        "詳情請參閱 deliveroo.hk/legal 條款及細則。"
    ]
}

export const deliveroo_tandc_11_11_2022_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "Enter the Deliveroo voucher code before checkout on the Deliveroo app or on www.deliveroo.hk.",
        "You only need to enter the voucher code once, its value or balance (if any) will be credited and saved to your account.",
        "Deliveroo voucher code is valid till 11/11/2022.",
        "Vouchers are not exchangeable or refundable.",
        "See deliveroo.hk/legal for full terms."
    ]
}

export const deliveroo_tandc_05_04_2023_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則:",
    details: [
        "於 www.deliveroo.hk 或戶戶送應用程式註冊並在結賬前輸入戶戶送電子優惠碼。",
        "你只需輸入優惠碼一次，其面值或餘額（如有）便會被存入你的戶戶送帳戶。",
        "有效日期至2023年4月5日。",
        "優惠碼不能兌換現金及不設退款。",
        "詳情請參閱 deliveroo.hk/legal 條款及細則。"
    ]
}

export const deliveroo_tandc_05_04_2023_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "Enter the Deliveroo voucher code before checkout on the Deliveroo app or on www.deliveroo.hk.",
        "You only need to enter the voucher code once, its value or balance (if any) will be credited and saved to your account.",
        "Deliveroo voucher code is valid till 05 Apr 2023.",
        "Vouchers are not exchangeable or refundable.",
        "See deliveroo.hk/legal for full terms."
    ]
}

export const st_honore_tandc_30_06_2022_tc: TitleDetail = {
    style_index: IndexStyle.bullets,
    title: "條款及細則",
    details: [
        "憑券到聖安娜惠顧可當作港幣HKD$25使用",
        '此券只適用於聖安娜餅屋香港分店',
        "此優惠不可兌換現金或其他產品。",
        "此券不適用於選購飲品、非聖安娜產品及現金券(包括禮餅券及麵包券)",
        "此優惠不可與其他折扣及優惠同時使用。",
        "優惠需要於指定有效日期內(30-03-2021 至30-06-2022)使用，逾期不設退款。",
        "每券限用一次"
    ]
}

export const st_honore_tandc_30_06_2022_en: TitleDetail = {
    style_index: IndexStyle.bullets,
    title: "Terms & Conditions:",
    details: [
        "This coupon can be used as $25 at any Saint Honore Cake Shop stores.",
        "This coupon is vaild for use in Toast Box stores in Hong Kong only。",
        "This offer cannot be redeemed for cash.",
        "This coupon is not applicable to drinks, non-Saint Honore products and vouchers (including Gift Voucher and bread Coupon)",
        "This offer cannot be used in conjunction with other discounts or promotional offers.",
        "This offer is valid from March 30, 2021 to June 30,2022, and is refundable upon expiry.",
        "This offer is valid for one-time use only."
    ]
}

export const st_honore_tandc_31_12_2023_tc: TitleDetail = {
    style_index: IndexStyle.bullets,
    title: "條款及細則",
    details: [
        "憑券到聖安娜惠顧可當作港幣HKD$25使用",
        '此券只適用於聖安娜餅屋香港分店',
        "此優惠不可兌換現金或其他產品。",
        "此券不適用於選購飲品、非聖安娜產品及現金券(包括禮餅券及麵包券)",
        "此優惠不可與其他折扣及優惠同時使用。",
        "優惠需要於指定有效日期內(16-09-2022 至31-12-2023)使用，逾期不設退款。",
        "每券限用一次"
    ]
}

export const st_honore_tandc_31_12_2023_en: TitleDetail = {
    style_index: IndexStyle.bullets,
    title: "Terms & Conditions:",
    details: [
        "This coupon can be used as $25 at any Saint Honore Cake Shop stores.",
        "This coupon is vaild for use in Saint Honore stores in Hong Kong only。",
        "This offer cannot be redeemed for cash.",
        "This coupon is not applicable to drinks, non-Saint Honore products and vouchers (including Gift Voucher and bread Coupon)",
        "This offer cannot be used in conjunction with other discounts or promotional offers.",
        "This offer is valid from September 16, 2022 to December 31,2023, and is not refundable upon expiry.",
        "This offer is valid for one-time use only."
    ]
}

export const st_honore_tandc_31_12_2024_tc: TitleDetail = {
    style_index: IndexStyle.bullets,
    title: "條款及細則",
    details: [
        "憑券到聖安娜惠顧可當作港幣HKD$25使用",
        '此券只適用於聖安娜餅屋香港分店',
        "此優惠不可兌換現金或其他產品。",
        "此券不適用於選購飲品、非聖安娜產品及現金券(包括禮餅券及麵包券)",
        "此優惠不可與其他折扣及優惠同時使用。",
        "優惠需要於指定有效日期內(20-03-2023 至 31-12-2024)使用，逾期不設退款。",
        "每券限用一次"
    ]
}

export const st_honore_tandc_31_12_2024_en: TitleDetail = {
    style_index: IndexStyle.bullets,
    title: "Terms & Conditions:",
    details: [
        "This coupon can be used as $25 at any Saint Honore Cake Shop stores.",
        "This coupon is vaild for use in Saint Honore stores in Hong Kong only。",
        "This offer cannot be redeemed for cash.",
        "This coupon is not applicable to drinks, non-Saint Honore products and vouchers (including Gift Voucher and bread Coupon)",
        "This offer cannot be used in conjunction with other discounts or promotional offers.",
        "This offer is valid from March 20, 2023 to December 31, 2024, and is not refundable upon expiry.",
        "This offer is valid for one-time use only."
    ]
}

export const hft_tandc_28_02_2023_tc: TitleDetail = {
    details: [
        // "憑券到鴻福堂惠顧可當作港幣HKD$20使用",
        // "此優惠不可兌換現金或其他產品。",
        // "如有任何爭議，公司保留一切有關行使之最終決定。",
        // "此優惠不可與其他折扣及優惠同時使用。",
        // "優惠需要於2023年2月28日前使用，逾期不設退款。",
        // "每次只限使用優惠一次。"
        "憑券到香港鴻福堂分店可免費換取同等面值之正價產品(特價或優惠產品除外)。",
        "憑券適用於換領保健自家湯系列、正品龜苓膏系列、自家涼茶系列、滋潤甘露系列、自家喜慶系列、自家節慶系列、自家小食系列、自家粥品列、自家甜品系列及鮮磨豆漿系列等產品；產品以正價計算。",
        "換取正價產品之總值如超過此券之面值，則需以現金支付餘數；若少於此券之金額則作棄權論；餘額不可兌換現金、找贖、退款或轉換至「自家CLUB」會員的備用儲值額，亦不能用作補回使用其他套票時之差額。",
        "此券不能兌換現金、不設找贖、亦不能與其他鴻福堂優惠同時使用。",
        "此券不可用作(包括但不限於)換領鴻福堂自家套票、現金券、增值自家CLUB卡或八達通卡服務。",
        "請於付款前出示此券，此券可累積使用。如有塗改或破損即屬無效，影印本恕不接受。",
        "此券須於有效日期內使用，逾期作廢。",
        "鴻福堂產品價格或產品款式會不時調整或更改，請聯絡顧客服務部熱線2920-2206以取得最新產品資訊。",
        "如有任何爭議，一概以「QUALITY OF LIFE PRODUCTS COMPANY LIMITED生活良方產品有限公司」的最終決定為準。"
    ]
}

export const hft_tandc_28_02_2023_en: TitleDetail = {
    details: [
        "Gift voucher can be redeemed at any Hung Fook Tong stores in Hong Kong at its face value for the original priced products only (discounted and promotional products are excluded).",
        "This gift voucher is eligible to redeem our Healthy Home-Made Soup Series, Herbal Jelly Series, Healthy Herbal Tea Series, Nourishing Deluxe Drink Series, Home-Made Joyous Series, Home-Made Festive Series, Home-Made Snacks, Home-Made Congee．Rice．Noodle Series, Home-Made Dessert Series and Fresh Soybean Milk Series products etc. at their original prices.",
        "If the purchase exceeds the amount of the voucher's face value, the remaining balance must be paid by cash. The remaining value of the voucher is not refundable, exchangeable, redeemable for cash or transferred to JIKA Club member's account balance.  No change will be given either in cash or in other form. The voucher cannot be used in conjunction with any other coupon.",
        "This voucher is not exchangeable for cash and/or used in conjunction with other Hung Fook Tong discount offers. No change will be given either in cash or in other form.",
        "This voucher is not applicable for purchase of (including but not limited to) Hung Fook Tong Home-Made selected coupons, gift vouchers, or adding value service for JIKA Club Card nor Octopus Card.",
        "Please present this voucher before payment. This voucher can be accumulated. This voucher will become invalid if it has been defaced or damaged in anyway. Photocopies will not be accepted.",
        "This voucher is only valid on or before the expiry date.",
        "Hung Fook Tong may adjust the product prices or change products from time to time. Please contact the Customer Services Hotline at 2920-2206 for more details.",
        "QUALITY OF LIFE PRODUCTS COMPANY LIMITED生活良方產品有限公司 reserves its right to make the final decision in the event of any dispute."
    ]
}


export const deliveroo_tandc_23_01_2023_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則:",
    details: [
        "於 www.deliveroo.hk 或戶戶送應用程式註冊並在結賬前輸入戶戶送電子優惠碼。",
        "你只需輸入優惠碼一次，其面值或餘額（如有）便會被存入你的戶戶送帳戶。",
        "有效日期至2023年1月11日。",
        "優惠碼不能兌換現金及不設退款。",
        "詳情請參閱 deliveroo.hk/legal 條款及細則。"
    ]
}

export const deliveroo_tandc_23_01_2023_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "Enter the Deliveroo voucher code before checkout on the Deliveroo app or on www.deliveroo.hk.",
        "You only need to enter the voucher code once, its value or balance (if any) will be credited and saved to your account.",
        "Deliveroo voucher code is valid till 11/1/2023.",
        "Vouchers are not exchangeable or refundable.",
        "See deliveroo.hk/legal for full terms."
    ]
}

export const cafedecoral20_tandc_tc: TitleDetail = {
    style_index: IndexStyle.bullets,
    title: "條款及細則:",
    details: [
        "本券可於全港各大家樂分店惠顧時，作現金港幣貳拾元使用。",
        "本券不可退款或兌換現金，並不設找續。",
        "顧客必須於付款前向收銀員出示本券。",
        "本券如逾期，遺失，損毀，遭受塗改或模糊不清，即告無效，不獲補發。",
        "如有任何爭議，大家樂快餐有限公司保留本券之最終決定權。",
        "此優惠券由AT Points by AsiaTop Loyalty Limited提供。",
        "如有任何查詢，請與AT Points聯絡:",
        "聯絡電話: 2322 8350",
        "WhatsApp: +852 9311 6084",
        "辦公時間: 星期一至五:  10am - 6pm (公眾假期例外)"
    ]
}

export const cafedecoral20_tandc_en: TitleDetail = {
    style_index: IndexStyle.bullets,
    title: "Terms & Conditions:",
    details: [
        "Redeemable at any Café de Coral restaurants in Hong Kong for HK$20 worth of purchase.",
        "This coupon is not exchangeable for cash. No change shall be provided.",
        "This coupon must be presented to the cashier before payment.",
        "This coupon is invalid and not replaceable if expired, lost, found defaced or damaged.",
        "In event of any disputes, Café de Coral Fast Food Limited shall reserve the right of final decision.",
        "The voucher is provided by AT Points by AsiaTop Loyalty Limited.",
        "For any enquiries, please contact AT Points.",
        "Contact number: 2322 8350",
        "WhatsApp: +852 9311 6084"
    ]
}

export const foodpanda_tandc_31_08_2022_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則:",
    details: [
        "不設最低訂單金額。",
        "優惠券適用於在foodpanda應用程式或foodpanda網站上兌換（https://www.foodpanda.hk和 http://corporate.foodpanda.hk）",
        "此現金券不適用於送貨費。",
        "不允許將優惠券兌換成其他形式的商品，服務，現金或任何等價貨幣。",
        "每個優惠券代碼只能使用一次，並且每個交易只能使用一個優惠券。",
        "現金券有效期至31-08-2022，逾期不設退款或更換。",
        "如有任何爭議，foodpanda保留一切有關行使之最終決定。",
    ]
}

export const foodpanda_tandc_31_08_2022_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "No minimum order value is required.",
        "The voucher is applicable for redemption in-app or on foodpanda website (https://www.foodpanda.hk & http://corporate.foodpanda.hk)",
        "The voucher is not applicable to the delivery fee.",
        "The voucher is not allowed to be exchanged for other forms of goods, services, cash or any monetary equivalent.",
        "Each voucher code can only be used once, and only one voucher can be used for each transaction.",
        "The voucher will be valid till 31-08-2022 (or less depending on the agreement with customer) and is not refundable or exchangeable.",
        "foodpanda reserves the right of final decision and interpretation in the case of any dispute."
    ]
}

export const fresh_tandc_50_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則:",
    details: [
        "此電子現金券持有人於此券有效期內憑券到FRESH新鮮生活或本灣水產購物可作HK$50使用，逾期未使用將自動失效。",
        "此券不適用於網上下單 (電話及手機應用程式)、各餐飲及新鮮食品專區、自助收銀機及「Seamless Buy閃買」自助付款櫃位使用。",
        "此電子現金券須於付款前出示，過後恕不受理。",
        "此電子現金券只可使用一次。",
        "此電子現金券一經使用，在任何情況下不可再次使用（即使購物交易及後因任何原因取消）。",
        "此電子現金券不能用以套換現金或其他面值之禮券。 持有人不獲現金或其他形式找續。",
        "瑪雷有限公司有權隨時修改此電子現金券之使用條款，恕不另行通知。",
        "如有任何爭議，瑪雷有限公司將保留最終決定權。"
    ]
}

export const fresh_tandc_50_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "This e-Cash Coupon entitles the bearer to redeem products, amounting to the value of HK$50 at FRESH or HONG KONG FLAVOUR on or before the expiry date. This e-Cash Coupon will automatically become invalid if it is not used before the expiry date.",
        "This coupon will not be applicable for online purchase (telephone orders and mobile app), F&B and Fresh Food Zones counter, self-checkout counter and Seamless Buy counter.",
        "This e-Cash Coupon must be presented before payment. No retrospective arrangement can be made.",
        "Each e-Cash Coupon can only be used once.",
        "This e-Cash Coupon cannot be reused in any circumstances, even if the transaction is subsequently cancelled, refunded or withdrawn for whatsoever reason.",
        "This e-Cash Coupon is not exchangeable for cash or Cash Coupons in other denominations.  No change will be given either in cash or in other form.",
        "MARAE LIMITED reserves the right to amend the terms & conditions of this e-Cash Coupon from time to time without any prior notice.",
        "MARAE LIMITED reserves the right of final discretion in case of any dispute arising from this e-Cash Coupon."
    ]
}

export const paul_tandc_50_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則:",
    details: [
        "此二維碼可換領 PAUL LAFAYET 價值 HK$50 之禮品",
        "此二維碼適用於香港所有 PAUL LAFAYET 專門店",
        "此二維碼只可使用一次，請於付款前出示此券",
        "此二維碼不適用於任何送貨服務及網上商店",
        "此二維碼不可與其他優惠及二維碼同時使用",
        "此二維碼不可退款，或兌換現金、其他禮劵",
        "未經 PAUL LAFAYET 授權，此二維碼不得作任何買賣、交易及宣傳推廣用途",
        "影印本，及經任何塗改或損毀的二維碼，恕不接受",
        "產品供應視乎換領當日專門店之供貨量而定",
        "換領不適用於以下期間： 2021 年 : 9 月21 - 22 日 、12 月24 - 27 日及12 月31 日和焦糖燉蛋節 2022 年 : 1 月1日 及1 月31 日 、2 月1 - 3 日及2 月14 日",
        "此二維碼如有遺失，恕不補發",
        "此二維碼須於下列有效日期或之前使用，逾期作廢",
        "PAUL LAFAYET 保留隨時更改二維碼條款及細則之權利而毋須另行通知",
        "如有任何爭議，PAUL LAFAYET 保留最終決定權利",
        "以上條款及細則均以英文及中文編寫，中英文本如有歧異或矛盾，概以英文版為準",
        "此二維碼有效期至：2022 年 3 月 28 日"
    ]
}

export const paul_tandc_50_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "This QR Code entitles the holder to redeem PAUL LAFAYET items amounting to the value of HK$50",
        "This QR Code is applicable to all PAUL LAFAYET boutiques in Hong Kong",
        "This QR Code is valid for one time only and must be presented for redemption",
        "This QR Code is not applicable on delivery service or online purchases",
        "This QR Code cannot be used in conjunction with other promotional offers and QR Codes",
        "This QR Code is non-refundable, non-redeemable for cash, or other voucher",
        "This QR Code is not for resale, trade or promotional purposes without authorization from PAUL LAFAYET",
        "Photocopies, damaged or defaced QR Code will not be accepted",
        "In case of unusual redemption volume, redemption shall be limited to stock availability",
        "Redemption is not available on the following days: 2021: 21-22 Sep, 24-27 & 31 Dec and Crème Brûlée Festival 2022: 1 & 31 Jan, 1-3 & 14 Feb",
        "PAUL LAFAYET assumes no responsibility for the loss of this QR Code",
        "This QR Code is only valid on or before the expiry date stated below",
        "PAUL LAFAYET reserves the right to amend the above terms without prior notice",
        "PAUL LAFAYET reserves the right of final decision in case of disputes",
        "In the event of any conflict or discrepancy between the English version and the Chinese version of these Terms and Conditions, the English version shall apply and prevail",
        "Date of Expiry: 28 March 2022"
    ]
}

export const cova_tandc_17_12_2022_tc: TitleDetail = {

    details: [
        "此電子券於有效期內可作HK$50使用，於COVA 朱古力餠店或外賣咖啡吧選購正價貨品、或於COVA餐廳作HK$50午膳或晚膳堂食享用。",
        "在同一交易可使用多於一張禮券。",
        "此電子券不適用於預設節日禮籃或外送服務。",
        "此電子券不適用於到會服務、或餐廳內二十位以上宴會、節日或推廣餐單。",
        "請於付款前出示此電子券，每券限用一次。 此電子券不能兌換現金，如禮券金額未完全用罄，餘額將被註銷。",
        "此電子券不能與COVA Privilege Card及其他推廣優惠一同使用。",
        "COVA不會對任何顧客，就拒絕、取消或轍銷電子券之使用而導致任何的逾期、損壞或遺失而負上責任。",
        "此電子券不能在未獲COVA書面同意下轉售、發佈給第三者使用或作任何商業或推廣用途。",
        "此電子券上之二維條碼已列於相關電子券上，逾期無效。",
        "如有任何爭議，COVA保留最終決定權。",
        "COVA保留權利修改本條款及細則或終止此電子券之使用而不作另行通知。"
    ]
}

export const cova_tandc_17_12_2022_en: TitleDetail = {

    details: [
        "This Certificate is applicable to any product at original price up to HK$50 at COVA Pasticceria-Confetteria Boutiques or Takeaway Coffee Bars; or for dine-in lunch or dinner consumption up to the value of HK$50 at COVA Caffè-Ristorante, in Hong Kong within the validity period.",
        "Multiple Certificates are accepted in a single transaction.",
        "This Certificate is not applicable to use on pre-designed festive hamper and delivery service.",
        "This Certificate cannot be used for outside catering, banquet party of more than 20 guests, festive menu, or promotional menu at Caffè-Ristorante.",
        "This Certificate must be presented before payment.",
        "Each Certificate can be used once only.",
        "This Certificate cannot be redeemed for cash and any unused balance shall be forfeited.",
        "This Certificate cannot be used in conjunction with COVA Privilege Card or other promotional offers.",
        "COVA shall not be liable to any customer for any expiry, loss or damage arising out of the refusal, cancellation, withdrawal of the e-Voucher or any failure or inability of a customer to use an e-Voucher for any reason.",
        "This Certificate is not allowed for resale by any means and cannot be used for any commercial or promotional purposes without the prior written approval of COVA.",
        "The QR code on this Certificate is valid until the expiry date shown on the respective e-Voucher.",
        "All matters and dispute will be subject to the final decision of COVA. COVA reserves the right to amend the terms and conditions without prior notice."
    ]
}

export const cova_tandc_05_08_2023_tc: TitleDetail = {

    details: [
        "此電子券於有效期內可作HK$50使用，於COVA 朱古力餠店或外賣咖啡吧選購正價貨品、或於COVA餐廳作HK$50午膳或晚膳堂食享用。",
        "在同一交易可使用多於一張禮券。",
        "此電子券不適用於預設節日禮籃或外送服務。",
        "此電子券不適用於到會服務、或餐廳內二十位以上宴會、節日或推廣餐單。",
        "請於付款前出示此電子券，每券限用一次。 此電子券不能兌換現金，如禮券金額未完全用罄，餘額將被註銷。",
        "此電子券不能與COVA Privilege Card及其他推廣優惠一同使用。",
        "COVA不會對任何顧客，就拒絕、取消或轍銷電子券之使用而導致任何的逾期、損壞或遺失而負上責任。",
        "此電子券不能在未獲COVA書面同意下轉售、發佈給第三者使用或作任何商業或推廣用途。",
        "此電子券上之二維條碼已列於相關電子券上，逾期無效。",
        "如有任何爭議，COVA保留最終決定權。",
        "COVA保留權利修改本條款及細則或終止此電子券之使用而不作另行通知。"
    ]
}

export const cova_tandc_05_08_2023_en: TitleDetail = {

    details: [
        "This Certificate is applicable to any product at original price up to HK$50 at COVA Pasticceria-Confetteria Boutiques or Takeaway Coffee Bars; or for dine-in lunch or dinner consumption up to the value of HK$50 at COVA Caffè-Ristorante, in Hong Kong within the validity period.",
        "Multiple Certificates are accepted in a single transaction.",
        "This Certificate is not applicable to use on pre-designed festive hamper and delivery service.",
        "This Certificate cannot be used for outside catering, banquet party of more than 20 guests, festive menu, or promotional menu at Caffè-Ristorante.",
        "This Certificate must be presented before payment.",
        "Each Certificate can be used once only.",
        "This Certificate cannot be redeemed for cash and any unused balance shall be forfeited.",
        "This Certificate cannot be used in conjunction with COVA Privilege Card or other promotional offers.",
        "COVA shall not be liable to any customer for any expiry, loss or damage arising out of the refusal, cancellation, withdrawal of the e-Voucher or any failure or inability of a customer to use an e-Voucher for any reason.",
        "This Certificate is not allowed for resale by any means and cannot be used for any commercial or promotional purposes without the prior written approval of COVA.",
        "The QR code on this Certificate is valid until the expiry date shown on the respective e-Voucher.",
        "All matters and dispute will be subject to the final decision of COVA. COVA reserves the right to amend the terms and conditions without prior notice."
    ]
}

export const sthRiceDumping_tandc_01_06_2022_tc: TitleDetail = {

    details: [ 
        "憑此電子禮券可在2022年5月19日至5月29日期間於全線聖安娜換領「極品元貝金腿裹蒸糭」一套或在2022年5月30日至2022年6月1 日到指定14間聖安娜分店換領。",
        "此電子禮券不可兌換現金或其他物品，貨品恕不退換及不可轉售。",
        "此券如有逾期，即作廢無效，並不獲補發。",
        "星展銀行（香港）有限公司（「星展銀行」）並非此電子禮券的供應商。此電子禮券須按其供應商指定的條款及細則使用，如對已換領的禮品或產品或服務有任何查詢或投訴，請直接與有關供應商聯絡，本行對此不承擔任何責任/法律責任。",
        "客戶須簽署客戶簽收回條（「簽收回條」）後，方可換領獎賞。",
        "客戶簽署簽收回條後 ，本行將存入電子禮券予客戶的有效DBS  digibank HK App戶口。",
        "客戶於本行存入電子禮券時必須仍然持有有效的DBS  digibank HK App戶口。",
        "如條款及細則的中英文版本有任何歧異，將以英文版本為準。",
        "銀行可以修改本條款及細則。銀行的決定為最終決定。",
        "查詢供應商的最新換領地點，請瀏覽 https://www.sthonore.com/hk/locations 或致電顧客服務熱線 2991 6020。"
    ]
}

export const sthRiceDumping_tandc_01_06_2022_en: TitleDetail = {

    details: [
        "Customer may present this e-coupon to redeem a set of「Deluxe Rice Dumpling with Scallop and Chinese Ham」at all St Honore Branches from May 19 to May 29, 2022 or redeem at the designated 14 St Honore Branches from May 30 to June 1,2022.",
        "This e-coupon cannot be exchanged for cash or other items. Items sold are non-transferable.",
        "This e-coupon will be invalid if it is expired.",
        "DBS Bank (Hong Kong) Limited (the “Bank”) is not the supplier of the e-coupon. Any enquiry or complaint regarding the e-coupon or the goods or services redeemed should be directed to the supplier. The Bank shall assume no responsibility or liability in respect thereof.",
        "Customer is required to sign the Customer Acknowledgement Receipt Form (“CAR Form”) to redeem the Gift.",
        "Customers must maintain a DBS digibank HK App account with the Bank when the e-coupon is given.",
        "E-coupon will be sent to the Customer’s corresponding DBS digibank HK App maintained in the Bank’s record at the time of fulfillment. ",
        "Should there be any discrepancy between the English and Chinese versions of these Terms and Conditions, the English version shall prevail.",
        "The Bank may modify/terminate these Terms and Conditions without prior notice. The Bank’s decision is final.",
        "To check the supplier’s up-to-date locations, please browse https://www.sthonore.com/hk/locations  or call customer service hotline 2991 6020."
    ]
}

export const royal_garden_riceDumpling_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    details: [
        "查詢及預訂電話: 27332840",
        "5 隻或以上, 請預早 3 天致電預留。",
        "預訂取貨需要提供兌換券碼。",
        "取貨時需出示兌換券正本。",
        "兌換券不能用以套換其他面值之餐飲禮券。",
        "取貨時間: 每日早上 12 時至晚上 8 時。",
        "兌換券逾期作廢，恕不退換。",
        "兌換券不可兌換現金。",
        "產品供應視乎情況而定。",
        "如有任何爭議，帝苑酒店保留最終決定權。",
        "此優惠券由 AT Points by AsiaTop Loyalty Limited 提供。"
    ]
}

export const sth_tandc_number_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    details: [
        "憑券到聖安娜惠顧可當作港幣HKD$25使用",
        "此券只適用於聖安娜餅屋香港分店",
        "此優惠不可兌換現金或其他產品。",
        "此券不適用於選購飲品、非聖安娜產品及現金券(包括禮餅券及麵包券)",
        "此優惠不可與其他折扣及優惠同時使用。",
        "優惠需要於指定有效日期至30-06-2022使用，逾期不設退款。",
        "每券限用一次"
    ]
}

export const sth_tandc_number_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    details: [
        "This coupon can be used as $25 at any Saint Honore Cake Shop stores.",
        "This coupon is vaild for use in Saint Honore Cake Shop stores in Hong Kong only",
        "This offer cannot be redeemed for cash.",
        "This coupon is not applicable to drinks, non-Saint Honore products and vouchers (including Gift Voucher and bread Coupon)",
        "This offer cannot be used in conjunction with other discounts or promotional offers.",
        "This offer is valid until June 30,2022, and is refundable upon expiry.",
        "This offer is valid for one-time use only"
    ]
}

export const atp_orix_tac_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms & Conditions:",
    details: [
        "This offer cannot be redeemed for cash.",
        "AT Rewards reserve the final right of decision on all matters concerning the use of this offer.",
        "This offer cannot be used in conjunction with other discounts or promotional offers.",
        "This offer is valid until the expiry date stated and is refundable upon expiry.",
        "Cash Voucher cannot combine with other promotion."
    ]
}

export const atp_orix_tac_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則:",
    details: [
        "此優惠不可兌換現金或其他產品。",
        "如有任何爭議，AT Rewards保留一切有關行使之最終決定。",
        "此優惠不可與其他折扣及優惠同時使用。",
        "優惠需要於指定有效日期前使用，逾期不設退款。",
        "不可與其他優惠同時使用。"
    ]
}

export const sasa_tac_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "使用條款及細則",
    details: [
        "於港、澳莎莎分店購物即可享 HKD/MOP$100 現金折扣優惠 (單一收據計)",
        "以上現金券只限使用一次。",
        "此現金券於發出日期起計1年內有效，逾期無效。",
        "以上現金券不可兌換現金或找贖。如購物總額低於此面值，餘額將不會作出現金找贖。",
        "已使用以上現金券之交易，將不會獲退款。",
        "必須於有效期前使用，逾期無效，不得延期。",
        "請小心保管電子現金券，如有遺失或任何其他原因而導致被第三者冒用及或盜取，莎莎化粧品有限公司及香港電訊有限公司恕不負責，亦不設補發及退款。",
        "以上現金券不可轉售或作其他商業用途。",
        "莎莎化粧品有限公司保留修改以上條款及細則之權利，毋須另行通知。",
        "如有任何爭議，莎莎化粧品有限公司保留最終決定權。",
        "如以上條款的中英文版本有任何分歧，將以[中]文版本為準。",
        "莎莎客戶服務熱線 (852) 2505 5023 (辦公時間內)。"
    ]
}

export const sasa_tac_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms and Conditions",
    details: [
        "Present SaSa cash digital voucher to enjoy HKD/MOP$100 purchase discount at any Hong Kong and Macau Sa Sa shops (per one invoice).",
        "This coupon is valid for one year after the issue date.",
        "This coupon can be used once and for one redemption only.",
        "This coupon cannot be redeemed in cash or exchange. No charge will be given for any purchase if its value is lower than the face value of this coupon.",
        "Refund is not applicable for the offer redeemed.",
        "This coupon must be used before the expiry date, otherwise they will be forfeited. No extension will be allowed.",
        "Please take care of the cash voucher. Sa Sa Cosmetic Company Ltd. are not responsible for loss or any reasons that lead to lost or stolen by third parties, and the voucher will not be reissued or refunded.",
        "Coupon is not for resale or other commercial uses",
        "Sa Sa Cosmetic Company Limited reserves the right to amend these terms and conditions without giving prior notice. In case of any dispute, the decision of Sa Sa Cosmetic Company Limited shall be final and conclusive.",
        "The term in Chinese version shall prevail if there is any inconsistency with the English version.",
        "Sa Sa Customer Service Hotline (852) 2505 5023 (during office hours)."
    ]
}

export const wechat_starbucks_tac_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "$30 e-Starbucks Gift Certificate T&Cs：",
    details: [
        "1. E-Gift Certificate Valid till 31 August 2022",
        "2. This e-Gift Certificate can be used one only at Starbucks stores in Hong Kong except Hong Kong Disneyland Store",
        "3. This e-Gift Certificate can be used at Starbucks stores in Hong Kong except \“We Proudly Serve Starbucks\”",
        "4. This e-Gift Certificate cannot be redeemed for cash and any unused balance will be forfeited.",
        "5. This e-Gift Certificate only applicable to in-store purchase, not applicable to Mobile Order & Pay, Starbucks Delivers, delivery service, phone order or Starbucks Drink eGift",
        "6. This e-Gift Certificate cannot be used to activate or reload Starbucks Card and Octopus Card",
        "7. This e-Gift Certificate is not applicable to merchandise, packaged food, bottled and canned beverage.",
        "8. Customers are required to use this offer and settle full payment by Wechat Pay upon in store consumption",
        "9. Only digital e-Gift Certificate will be accepted. The hard copy of eGC is invalid and will not be accepted",
        "10. This e-Gift Certificate cannot be resold",
        "11. Coffee Concepts (HK) Ltd. will not be responsible for any loss or damage to this e-Gift Certificate",
        "12. Coffee Concepts (HK) Ltd. reserve the right to change the terms & conditions in relation to the use of this e-Gift Certificate"
    ]
}

export const wechat_starbucks_tac_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則",
    details: [
        "1.有效期至2022年8月31日。",
        "2.此電子現金禮券 (“電子禮券”) 只限在香港星巴克分店使用一次 (香港迪士尼樂園分店除外)",
        "3.此電子禮券不適用於香港之星巴克\“We Proudly Serve Starbucks\”櫃檯",
        "4.此電子禮券不能兌換現金，並恕不找續",
        "5.此電子禮券只適用於親臨分店消費，不適用於Mobile Order & Pay、星星送、外賣速遞服務、星巴克飲品eGift及電話訂購。",
        "6.此電子禮券不能用作啟用或增值星巴克卡、八達通卡",
        "7.此電子禮券不適用於購買任何星巴克商品、禮券產品、包裝食品、樽裝及罐裝飲品。",
        "8.惠顧時需以 WeChat Pay 全數支付",
        "9.只接受電子禮券的電子版本，列印版本恕不接受",
        "10.此電子禮券不能轉售予他人",
        "11.此電子禮券如遭損壞或遺失，Coffee Concepts (HK) Ltd. 恕不負責",
        "12.Coffee Concepts (HK) Ltd. 保留更改有關使用此電子禮券規則的決定權"
    ]
}

export const starbucks_tac_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "$25 e-Starbucks Gift Certificate T&Cs：",
    details: [
        "Redemption Details",
        "1. This e-Gift Certificate entitles you to spend up to HK/MOP$25 at Starbucks stores in Hong Kong or Macau.",
        "2. This Certificate can be used once only at Starbucks Stores in Hong Kong or Macau",
        "3. This Certificate cannot be used at any“We Proudly Serve Starbucks”counters in Hong Kong or Macau",

        "Terms & Conditions",
        "4. This e-Gift Certificate (“eGC”) can be used once only at Starbucks stores in Hong Kong or Macau except Hong Kong Disneyland Store",
        "5. This eGC cannot be used at any “We Proudly Serve Starbucks” counters in Hong Kong or Macau",
        "6. This eGC cannot be redeemed for cash and any unused balance will be forfeited",
        "7.This eGC cannot be used to activate or reload Starbucks Card, Octopus Card and Macau Pass Card",
        "8. This eGC cannot be used to purchase any Starbucks Gift Certificate Only digital eGC will be accepted.",
        "9. The hard copy of eGC is invalid and will not be accepted",
        "10. This eGC cannot be resold",
        "11. Coffee Concepts (HK) Ltd. and Coffee Concepts (Macau) Ltd. will not be responsible for any loss or damage to this eGC",
        "12. Coffee Concepts (HK) Ltd. and Coffee Concepts (Macau) Ltd. reserve the right to change the terms & conditions in relation to the use of this eGC"
    ]
}

export const starbucks_tac_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則",
    details: [
        "換領詳情",
        "1. 此電子現金禮券可於香港或澳門星巴克分店作港幣/澳門幣25元使用。",
        "2. 此禮券可於香港或澳門星巴克分店使用一次 (香港迪士尼樂園分店除外)",
        "3. 此禮券不適用於香港或澳門之星巴克“We Proudly Serve Starbucks”櫃檯",

        "條款及細則",
        "4. 此電子現金禮券 (“電子禮券”) 只限在香港或澳門之星巴克分店使用一次 (香港迪士尼樂園分店除外)",
        "5. 此電子禮券不適用於香港或澳門之星巴克“We Proudly Serve Starbucks”櫃檯",
        "6. 此電子禮券不能兌換現金，並恕不找續",
        "7. 此電子禮券不能用作啟用或增值星巴克卡、八達通卡及澳門通卡",
        "8. 此電子禮券不適用於購買任何星巴克禮券產品",
        "9. 只接受電子禮券的電子版本，列印版本恕不接受",
        "10. 此電子禮券不能轉售予他人",
        "11. 此電子禮券如遭損壞或遺失，Coffee Concepts (HK) Ltd. 及 Coffee Concepts (Macau) Ltd.",
        "12. 恕不負責 Coffee Concepts (HK) Ltd. 及 Coffee Concepts (Macau) Ltd. 保留更改有關使用此電子禮券規則的決定權"
    ]
}

export const fairwood20220726_tac_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則",
    details: [
        "1. 憑此電子現金卷可於各大快活快餐香港全線分店內使用，不適用於網上點餐、訂購到會美食及盆菜服務。",
        "2. 請於付款時出示此電子現金卷及/或其二維碼(\"QR code\")，此電子現金卷及其二維碼只可使用一次。",
        "3. 每次惠顧可使用多張大快活之電子現金卷。",
        "4. 此電子現金卷不可兌換現金或退款，並不獲現金或其他形式找續。",
        "5. 此電子現金卷不可作增值八達通或作任何儲值或增值服務之用。",
        "6. 此電子現金卷及/或其二維碼如有遺失、塗污、塗改或損毀，即屬無效，恕不補發。",
        "7. 不論任何原因，若未能成功掃瞄電子現金卷內之二維碼，此電子現金卷將不獲接受。",
        "8. 此電子現金卷只限於有效日期內使用，逾期作廢。",
        "9. 以上圖片只供参考。",
        "10. 如有任何爭議，一概以大快活快餐有限公司之最後決定權為準。",
        "備註: 詳細分店地址以大快活快餐網址為準"
    ]
}

export const fairwood20220726_tac_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms and Conditions",
    details: [
        "1. This e-cash voucher can be used in all Fairwood branches in Hong Kong. It is not applicable to online food ordering, party food ordering and Poon Choi services.",
        "2. Please present this electronic cash voucher and/or QR code when making payment. This electronic cash voucher and its QR code can only be used once.",
        "3. More than one electronic cash coupons can be used in single transaction.",
        "4. This electronic cash voucher cannot be exchanged for cash or refunded, and it will not be renewed in cash or other forms.",
        "5. This electronic cash voucher cannot be used for value-added Octopus or for any value-added services.",
        "6. If this electronic cash voucher and/or its QR code is lost, defaced, altered or damaged, it is invalid and will not be reissued.",
        "7. For any reason, if the QR code in the electronic cash voucher is not scanned successfully, the electronic cash voucher will not be accepted.",
        "8. This electronic cash voucher can only be used within the effective date and will be invalid after the expiry date.",
        "9. The above picture is for reference only.",
        "10. In case of any disputes, Fairwood Fast Food Limited reserves the right of the final decision.",
        "Remarks: The detailed branch address is subject to Fairwood Fast Food official website"
    ]
}

export const goldenharvest_tac_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms and Conditions",
    details:[
        "- 此現金券適用於香港嘉禾院線、the sky及 StagE",
        "- 此券只適用於戲院票房購票；不適用於自助購票機、網上或手機售票服務",
        "- 此券只適用於兌換有效日期內放映場次之戲票",
        "- 此券不可退還，亦不可兌換現金",    
        "- 如換取之票價低於此券面值，恕不退款",
        "- 請於購票前出示此券；每個電子碼限兌換一次；所有兌換一經確認後不能更改或取消",
        "- 如有任何爭議，橙天嘉禾娛樂有限公司保留一切最終決定權"
    ]
}

export const goldenharvest_tac_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms and Conditions",
    details:[
        "- This coupon can be used at HK Golden Harvest Cinemas, the sky and StagE",
        "- This coupon is applicable to ticket purchased at cinema box office only. Ticketing Kiosk, online and mobile ticketing are excluded",
        "- This coupon can only redeem ticket of screening shown within the valid period",
        "- The coupon is non-refundable and cannot be exchanged for cash",
        "- No change will be given if the value of the redeemed ticket is lower than the value of this coupon",
        "- Please present this coupon prior to purchase; Each code can be redeemed once only. No alteration or cancellation is allowed once a redemption transaction is confirmed.",
        "- In case of any dispute, Orange Sky Golden Harvest Entertainment Co Ltd reserves the rights of final decision"
    ]
}

export const demo_tac_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms and Conditions",
    details:[
        "- 此現金券適用於香港嘉禾院線、the sky及 StagE",
        "- 此券只適用於戲院票房購票；不適用於自助購票機、網上或手機售票服務",
        "- 此券只適用於兌換有效日期內放映場次之戲票",
        "- 此券不可退還，亦不可兌換現金",    
        "- 如換取之票價低於此券面值，恕不退款",
        "- 請於購票前出示此券；每個電子碼限兌換一次；所有兌換一經確認後不能更改或取消",
        "- 如有任何爭議，橙天嘉禾娛樂有限公司保留一切最終決定權",
        "此優惠券由AT Points by AsiaTop Loyalty Limited提供。",
        "如有任何查詢，請與AT Points聯絡:",
        "聯絡電話: 2322 8350",
        "WhatsApp: +852 9311 6084",
        "辦公時間: 星期一至五:  10am - 6pm (公眾假期例外)"
    ]
}

export const demo_tac_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms and Conditions",
    details:[
        "- This coupon can be used at HK Golden Harvest Cinemas, the sky and StagE",
        "- This coupon is applicable to ticket purchased at cinema box office only. Ticketing Kiosk, online and mobile ticketing are excluded",
        "- This coupon can only redeem ticket of screening shown within the valid period",
        "- The coupon is non-refundable and cannot be exchanged for cash",
        "- No change will be given if the value of the redeemed ticket is lower than the value of this coupon",
        "- Please present this coupon prior to purchase; Each code can be redeemed once only. No alteration or cancellation is allowed once a redemption transaction is confirmed.",
        "- In case of any dispute, Orange Sky Golden Harvest Entertainment Co Ltd reserves the rights of final decision",
        "The voucher is provided by AT Points by AsiaTop Loyalty Limited.",
        "For any enquiries, please contact AT Points.",
        "Contact number: 2322 8350",
        "WhatsApp: +852 9311 6084"
    ]
}

export const emperor_cinemas_tac_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則",
    details: [
        "電影禮券適用於到期日或之前於戲院票房；或憑電子禮券編號於網站或手機App兌換2D電影戲票乙張。",
        "電影禮券不適用於香港以外之英皇戲院、the CORONET貴賓影院、優先場、電影節、特備節目、直播節目、大量訂票及包場及與發行商有特別協議之電影。",
        "兌換較長片長之電影、3D電影、IMAX 2D / 3D電影、4D電影、ATMOS電影、與發行商有特別協議之電影或指定電影須付附加費。",
        "電影禮券不可兌換現金或再次銷售或與其他優惠同時使用。",
        "電影禮券只可使用一次，每次只可兌換一張戲票。",
        "如有遺失、被竊、塗改、修改或損毀，電影禮券將被視作無效，恕不退款或補發。",
        "英皇戲院保留修改此條款及細則之權利，而毋須另行通知。",
        "如有任何爭議，英皇戲院保留最終決定權。",
        "此優惠券由AT Points by AsiaTop Loyalty Limited提供。",
        "如有任何查詢，請與AT Points聯絡:",
        "聯絡電話: 2322 8350",
        "WhatsApp: +852 9311 6084",
        "辦公時間: 星期一至五:  10am - 6pm (公眾假期例外)"
    ]
}

export const emperor_cinemas_tac_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms and Conditions",
    details:[
        "Movie Voucher is redeemable for a 2D movie ticket at box office, website or app with e-Voucher Code, on or before the Expiry Date.",
        "This voucher is not applicable for branches out of Hong Kong region, VIP Theatre - the CORONET, preview shows, film festivals, alternative programs, live broadcast programs, bulk purchase and house booking and movie with special engagement with film distributors.",
        "A surcharge is required for long movies, 3D movies, IMAX 2D / 3D movies, 4D movies, ATMOS movies, movies with special engagement with film distributors and designated movies.",
        "Movie Voucher cannot be redeemed for cash or resold or used in conjunction with any other promotion offers.",
        "Movie Voucher can be used once and redeemable for one movie ticket only.",
        "Movie Voucher will be invalidated if lost, stolen, defaced, altered or damaged. Invalid vouchers are non-refundable and non-replaceable.",
        "Emperor Cinemas reserves the right to amend and supplement any terms & conditions of Movie Voucher without prior notice.",
        "In case of disputes, decisions of Emperor Cinemas shall be final.",
        "The voucher is provided by AT Points by AsiaTop Loyalty Limited.",
        "For any enquiries, please contact AT Points.",
        "Contact number: 2322 8350",
        "WhatsApp: +852 9311 6084"
    ]
}

export const enoteca_tac_en: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "Terms and Conditions",
    details:[
        "This voucher is non-refundable and cannot be exchanged for cash in part or full and is valid for a single transaction only.",
        "No change will be given.",
        "Not applicable for online purchase.",
        "This voucher cannot be redeemed in order to purchase voucher.",
        "There will be no replacement provided if this voucher is lost, stolen, destroyed, or used without permission.",
        "This voucher is invalid if found damaged, altered, or expired.",
        "ENOTECA reserved the right to change the Terms & Conditions without prior notice.",
        "ENOTECA reserves the right of final decision in case of any disputes.",
        "The voucher is provided by AT Points by AsiaTop Loyalty Limited.",
        "For any enquiries, please contact AT Points.",
        "Contact number: 2322 8350",
        "WhatsApp: +852 9311 6084"
    ]
}

export const enoteca_tac_tc: TitleDetail = {
    style_index: IndexStyle.numbering,
    title: "條款及細則",
    details: [
        "此禮券不可退款，不能兌換成部分或全部現金，及只能用於單次交易。",
        "不設找續。",
        "不適用於網店。",
        "此禮券不可用於購買禮券。",
        "如有遺失，被盜，損毀或未經允許使用，將不設任何補發。",
        "如禮券損毀，曾被更改或過期，即告無效。",
        "ENOTECA保留更改條款的權利，恕不另行通知。",
        "如有任何爭議，ENOTECA保留最終決定權。",
        "此優惠券由AT Points by AsiaTop Loyalty Limited提供。",
        "如有任何查詢，請與AT Points聯絡:",
        "聯絡電話: 2322 8350",
        "WhatsApp: +852 9311 6084",
        "辦公時間: 星期一至五:  10am - 6pm (公眾假期例外)"
    ]
}