import React from 'react';
// import { Redirect } from 'react-router-dom'
import {isMobile} from "react-device-detect";



const PaymentSuccessPage: React.FC = () => {

    return (
        <>
            <div style={{textAlign: "center", margin: "30px 0 30px 0", display: "flex", flexDirection: "column", alignItems: "center"}} >
                <img src={require('../../images/atp_logo.png').default} alt="atp_logo" width={isMobile ? "85%": "40%"} height={isMobile ? "85%": "40%"} />
                <img src={require('../../images/success_icon.png').default} alt="success_icon" width={isMobile ? "20%": "10%"} height={isMobile ? "20%": "10%"} />
            </div>
            <div style={{margin: "0 10px 50px 10px"}}>
                <p style={{textAlign: "center", fontSize: isMobile ? "28px" : "32px", fontWeight: 'bolder', margin: '0 0 10px 0', color: "#314084", lineHeight: '30px'}}> 成功! </p>
                {/* <p style={{textAlign: "center", fontSize: isMobile ? "28px" : "32px", fontWeight: 'bolder', margin: '0 0 10px 0', color: "#314084", lineHeight: '30px'}}> 交易成功 </p> */}
                <p style={{textAlign: "center", fontSize: isMobile ? "28px" : "32px", fontWeight: 'bolder', margin: '0 0 10px 0', color: "#314084", lineHeight: '30px'}}> Success! </p>
            </div>

            <div style={{margin: "0 10px 50px 10px"}}>
                <p style={{textAlign: "center", fontSize: isMobile ? "26px" : "30px", fontWeight: 'bolder', margin: '0 0 10px 0', color: "#314084", lineHeight: '30px'}}> 請按返回鍵返回AT Points </p>
                {/* <p style={{textAlign: "center", fontSize: isMobile ? "26px" : "30px", fontWeight: 'bolder', margin: '0 0 10px 0', color: "#314084", lineHeight: '30px'}}> 请按返回键返回AT Points </p> */}
                <p style={{textAlign: "center", fontSize: isMobile ? "28px" : "32px", fontWeight: 'bolder', margin: '0 0 10px 0', color: "#314084", lineHeight: '30px'}}> Please click Back Button to go back to AT Points </p>
            </div>
        </>
    )
}

export default PaymentSuccessPage;
