import React, { useState, useEffect } from 'react';
import Template1, { bowtieCssProps, cuppingCssProps, towngasCssProps } from '../components/Template1';
import Template2, { tgCssProps } from '../components/Template2';
import Loading from '../pages/Loading';
import Error from '../pages/Error';
import axios from 'axios';
import { RouteProps } from "../App";

const ActInfo: React.FC<RouteProps> = ({match}) => {
    
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [res,setRes] = useState<AtResponse<IGetCouponDetails> | undefined>(undefined)
    const [webLang, setWebLang] = useState("")

    useEffect(()=> {
        const url = `http://ec2-52-78-237-245.ap-northeast-2.compute.amazonaws.com:6001/app/v1/coupondetails/`
        const promoCode = match.params.param1
        const apiUrl = url + promoCode
        const lang = navigator.language
        const langStr = lang.split('-')[0] 

        axios.get<AtResponse<IGetCouponDetails>>(apiUrl)
            .then(res =>  {
                setRes(res.data)                
                if(langStr === "en") {
                    setWebLang("en-US")
                } else if (langStr === "zh") {
                    if(lang === "zh-CN") {
                        setWebLang("zh-CN")
                    } else {
                        setWebLang("zh-TC")
                    }
                } else {
                    setWebLang("zh-TC")
                }

                setIsLoading(false)
                setIsError(false)
            })
            .catch(err => {
                setIsLoading(false)
                setIsError(true)
                const msg = err.message
                console.log("Error content: " + msg)
            })
    },[])

    const changeEnLang = () => {
        setWebLang("en-US")
    }

    const changeTcLang = () => {
        setWebLang("zh-TC")
    }

    const changeCnLang = () => {
        setWebLang("zh-CN")
    }
    
    return (            
        <div style={{margin: "0 25px 0 25px"}} >                        
            {isError && <Error />}
            {isLoading && <Loading />}            
            {!isLoading && !isError &&
                <div style={{textAlign: "right", margin: "15px 10px 0 0"}}>                    
                    <span style={{fontSize: "16px", color: "rgb(90, 85, 85)",
                        marginRight: "8px", borderRight: "1px solid", cursor: "pointer"}} onClick={changeTcLang}> 繁 </span>
                    <span style={{fontSize: "16px", color: "rgb(90, 85, 85)", 
                    marginRight: "8px", borderRight: "1px solid", cursor: "pointer"}} onClick={changeCnLang}> 簡 </span>
                    <span style={{fontSize: "16px", color: "rgb(90, 85, 85)",
                        cursor: "pointer"}} onClick={changeEnLang}> ENG </span>                    
                </div>
            }
            {!isLoading && !isError && getTemplate(webLang, res)}
        </div>
    )
}

const getContent = (webLang: string, mRes?: AtResponse<IGetCouponDetails>) => {

    const contents = new IContentDetails()
    
    if(mRes) {
        const data = mRes.data 

        if(webLang === "en-US") {
            contents.txtWebTitle = data.txtEnWebTitle
            contents.txtHeader = data.txtEnHeader
            contents.txtDescription = data.txtEnDescription
            contents.txtTaC = data.txtEnTaC
            contents.txtCodeDescription = data.txtEnCodeDescription
            contents.txtReferralUrlDescription = data.txtEnReferralUrlDescription
        }
        else if (webLang === "zh-CN") {
            
            contents.txtWebTitle = data.txtScWebTitle
            contents.txtHeader = data.txtScHeader
            contents.txtDescription = data.txtScDescription
            contents.txtTaC = data.txtScTaC
            contents.txtCodeDescription = data.txtScCodeDescription
            contents.txtReferralUrlDescription = data.txtScReferralUrlDescription
    
        }
        else {
            contents.txtWebTitle = data.txtTcWebTitle
            contents.txtHeader = data.txtTcHeader
            contents.txtDescription = data.txtTcDescription
            contents.txtTaC = data.txtTcTaC
            contents.txtCodeDescription = data.txtTcCodeDescription
            contents.txtReferralUrlDescription = data.txtTcReferralUrlDescription
        }        
    }
    return contents
}

const getTemplate = (webLang: string, mRes?: AtResponse<IGetCouponDetails>)  => {

    const screenWidth = window.innerWidth

    if(mRes) {
        const data = mRes.data        
        const contents = getContent(webLang, mRes)     
        if (data.eventName === "Bowtie") {
            return (
                <>
                    {/* {screenWidth > 450 && 
                        <div style={{ textAlign: "center"}}>
                            <img src={require('../images/atp_logo.png').default} alt="atp_logo" width="35%" height="35%" />
                            <img src={require('../images/bowtie_logo.png').default} alt="bowtie_logo" width="35%" height="35%" />
                        </div>
                    }
                    {screenWidth < 450 && 
                        <div style={{ margin: "20px 0 50px 0",textAlign: "center"}}>
                        <img src={require('../images/atp_logo.png').default} alt="atp_logo" width="50%" height="50%" />
                        <img src={require('../images/bowtie_logo.png').default} alt="bowtie_logo" width="50%" height="50%" />
                    </div>
                    } */}
                    
                    {screenWidth > 450 && 
                        <div style={{textAlign: "center"}}>
                        <img src={require('../images/banner1.png').default} alt="banner1" width="80%" height="80%" />
                        </div>
                    }
                    {screenWidth < 450 && 
                        <div style={{margin: "35px 0 30px 0", textAlign: "center"}}>
                        <img src={require('../images/banner1.png').default} alt="banner1" width="100%" height="100%" />
                        </div>
                    }
                    <Template1
                        lang={webLang}
                        desc1={contents.txtHeader}
                        desc2={contents.txtDescription}
                        desc3={contents.txtCodeDescription}
                        desc4={contents.txtReferralUrlDescription}                   
                        codeText={data.codeText}
                        codeQR={data.codeQR}
                        codeBar={data.codeBar}
                        referralUrl={data.referralUrl}
                        // actName={"Bowtie"}
                        cssProps={bowtieCssProps}
                    />
                </>
            )
        } else if (data.eventName === "Towngas") {
            return (
                <>
                    {screenWidth > 450 && 
                        <div style={{margin: "30px 0 50px 0", textAlign: "center"}}>
                            <img src={require('../images/atp_logo2.png').default} alt="atp_logo2" width="35%" height="35%" />
                            <img src={require('../images/towngas_logo.png').default} alt="towngas_logo" width="35%" height="35%" />
                        </div>
                    }
                    {screenWidth < 450 && 
                        <div style={{margin: "30px 0 50px 0", textAlign: "center"}}>
                        <img src={require('../images/atp_logo2.png').default} alt="atp_logo2" width="45%" height="45%" />
                        <img src={require('../images/towngas_logo.png').default} alt="towngas_logo" width="45%" height="45%" />
                    </div>
                    }
                    {/* {screenWidth > 450 && 
                        <div style={{margin: "30px 0 50px 0", textAlign: "center"}}>
                        <img src={require('../images/banner2.png').default} alt="banner2" width="70%" height="70%" />
                        </div>
                    }
                    {screenWidth < 450 && 
                        <div style={{margin: "30px 0 50px 0", textAlign: "center"}}>
                        <img src={require('../images/banner2.png').default} alt="banner2" width="80%" height="80%" />
                        </div>
                    } */}
                    <Template1
                        lang={webLang}
                        desc1={contents.txtHeader}
                        desc2={contents.txtDescription}
                        desc3={contents.txtCodeDescription}
                        desc4={contents.txtReferralUrlDescription}
                        codeText={data.codeText}
                        codeQR={data.codeQR}
                        codeBar={data.codeBar}
                        referralUrl={data.referralUrl}                    
                        cssProps={towngasCssProps}
                    />
                </>
            ) 
        }
        else {
            return <Template2
                lang={webLang}
                desc1={contents.txtHeader}
                desc2={contents.txtDescription}
                desc3={contents.txtCodeDescription}
                desc4={contents.txtReferralUrlDescription}
                code={data.codeText}
                cssProps={tgCssProps}
            />
        }                       
    }
}


export default ActInfo


interface AtResponse<T> {
    statusCode: number;
    message: string;
    data: T
}

interface IGetCouponDetails {
    id: number;
    eventName: string;

    txtEnWebTitle: string;
    txtEnHeader: string;
    txtEnDescription: string;
    txtEnTaC: string;
    txtEnCodeDescription: string;
    txtEnReferralUrlDescription: string;

    txtTcWebTitle: string;
    txtTcHeader: string;
    txtTcDescription: string;
    txtTcTaC: string;
    txtTcCodeDescription: string;
    txtTcReferralUrlDescription: string;

    txtScWebTitle: string;
    txtScHeader: string;
    txtScDescription: string;
    txtScTaC: string;
    txtScCodeDescription: string;
    txtScReferralUrlDescription: string;

    codeText: string;
    codeQR: string;
    codeBar: string;
    referralUrl: string;
}

class IContentDetails {
    txtWebTitle: string = ""
    txtHeader: string = ""
    txtDescription: string = ""
    txtTaC: string = ""
    txtCodeDescription: string = ""
    txtReferralUrlDescription: string = ""
}