import React, {useEffect} from 'react';
import TitleDetailComponent from "../../components/title_detail/TitleDetailComponent";
import {haagen_dazs_tc} from "../third_party_page/constant";


const Event_Page_roadshow_atp_member_2021_06: React.FC = () => {

    useEffect(() => {
        document.title = "AT Points Day"
    }, []);

    return (            
        <div style={{margin: "25px 25px 25px 25px"}} >
            <div style={{textAlign: "center"}}><b>【AT Points Day 1st Reward: Sharing Love Photo Contest】</b></div>
            <div><b>How to join:</b></div>

            <ol style={{ padding: "0px 16px 0px 16px"}}>
                {
                    [
                        'Download AT Points app and register as a member.',
                        'Follow and like 𝐀𝐓𝐏𝐨𝐢𝐧𝐭𝐬 Facebook @at.points, upload pictures and tag @at.points.',
                        'The post picture and content must fit the topic "Share Love" ',
                        'Add the tag on your post #ATPoints #mobile APP #coupon #membership #ATPointsDay #ShareLoveMoment #1minFreeShoppingSpree #FRESH #GoodShoppingHabit #ExcellentShoppingExperience',
                        'The post and picture setting should be public, and share on your personal Facebook'
                    ].map(
                        (it, index) => <li>{it}</li>
                    )
                }
            </ol>

            <dl>
                <dd style={{ margin: 0 }}>* There is no limit to the number of posts published by participants, but each AT Points account has only one chance to win the award.</dd>
            </dl>


            <div>The Prize Winners will be selected by AT Points,  the post conveys the theme of the competition "Share Love".</div>
            <div>1st: The winner can join a 1min free FRASH supermarket shopping spree（2 quota) </div>
            <div>2nd: Earn 100 AT Points （8 quota) </div>
            <div>3rd: Earn 20 AT Points（10 quota) </div>

            <br/>
            <br/>

            <div style={{textAlign: "center"}}>【AT Points Day 1st Reward: Sharing Love Photo Contest】Terms and Conditions</div>

            <ol style={{ padding: "0px 16px 0px 16px"}}>
                {
                    [
                        'Asia Top Loyalty Limited （referred to "AT Points" ) is the photo contest（referred to organizer of the "AT Points Day") ；The posts and photos uploaded by the participants on their personal Facebook are the entry materials（referred to "Post" ) .',
                        'This game commences from 11 Jun to 23:59 on 27 Jun, 2021 (HK time). Submission received outside this time period will not be considered.',
                        'Before participating in the event, please read the following terms and conditions carefully.Participants participating in the event means that they have confirmed that they have read, understood, and agreed to abide by all terms and conditions.',
                        'Participants must currently reside in Hong Kong and hold a Hong Kong ID card, also above  18 years old.',
                        'Participants must use  personal Facebook participate in the competition, participants Facebook post must set as public share.',
                        'Participants must complete the following stepsto join the Sharing Love Photo Contest: '
                    ].map(
                        (it, index) => <li>{it}</li>
                    )
                }

                <dl>
                    <dd style={{ margin: 0 }}>a. Download AT Points app and register as a member.</dd>
                    <dd style={{ margin: 0 }}>b. Follow and like <b>ATPoints</b> Facebook @at.points, upload pictures and tag@at.points</dd>
                    <dd style={{ margin: 0 }}>c. The post picture and content must fit the topic "Share Love  " </dd>
                    <dd style={{ margin: 0 }}>d. Add the tag on your post #ATPoints #mobile APP #coupon #membership #ATPointsDay #ShareLoveMoment #1minFreeShoppingSpree #FRESH #GoodShoppingHabit #ExcellentShoppingExperience</dd>
                    <dd style={{ margin: 0 }}>e. The post and picture setting should be public, write and share the messages to your personal Facebook account.</dd>
                </dl>

                {
                    [
                        'There is no limit to the number of posts published by participants, but each AT Points account has only one chance to win the award.',
                        'The prize Winners will be selected by AT Points,  the post convey the theme of the competition "Share Love" .'
                    ].map(
                        (it, index) => <li>{it}</li>
                    )
                }

                <dl>
                    <dd style={{ margin: 0 }}>a. 1st: The winner can join 1min free FRASH supermarket shopping spree (2 quota);</dd>
                    <dd style={{ margin: 0 }}>b. 2nd: Earn 100 AT Points (8 quota);</dd>
                    <dd style={{ margin: 0 }}>c. 3ed: Earn 20 AT Points (10 quota).</dd>
                </dl>

                {
                    [
                        'The result of the competition is subject to the decision of At Points. Participants are not allowed to object. There is no appeal mechanism for the competition.',
                        'The 1st winner（2 qouta) can join 2021/ 8/ 27 1min free FRASH supermarket shopping spree。',
                        'All entrants must be the sole and exclusive owner of the copyright of the entry(ies) or have the consent or endorsements from its property owner / licensor. ',
                        'Entries should not infringe on the rights of any third party.' +
                        'Otherwise, any unearthed infringement of any third party will result in disqualification of the entry(ies) concerned. Entrants must acknowledge any authorised use of graphics in the entry(ies) in accordance with the relevant copyright laws.',
                        '.The Organiser shall not be responsible whether directly or indirectly for any liability arising whatsoever or howsoever from any violation of any copyright laws. Entrant shall indemnify the Organiser against all actions, costs, claims and liabilities of whatsoever nature arising out of or in connection with any allegation and / or claim of infringement of any IPR of any party.',
                        'If it is found that a participant’s uploaded entry violates any of the following rules, AT Points reserves the right to request deletion of the entry, and the participant will be disqualified from participating without prior notice, including:',
                    ].map(
                        (it, index) => <li>{it}</li>
                    )
                }

                <dl>
                    <dd style={{ margin: 0 }}>a. The work must not violate applicable laws and regulations, does not contain personal political content; does not contain any personal privacy content; does not contain obscenity, pornography, gambling, superstition, terror, violence, insults, defamation, defamation, provocation, offensive, slanderous Content; does not contain content that involves ethnicity, race, religion, or gender discrimination; does not contain false or misleading content.</dd>
                    <dd style={{ margin: 0 }}>b. The content of the post does not conform to the brand image of AT Points.</dd>

                </dl>

                {
                    [
                        'Announcement of the results: AT Points will notify the winners through Facebook Inbox. The winners must reply within 24 hours and provide the required prize collection information, otherwise they will be considered as abstention. Eligibility for the prize will be filled by other contestants.',
                        'If a participant is found to participate in this event with an empty account or fake account, plug-in or other informal means, or any program disrupts or manipulates the event, AT Points will disqualify the participant from participating without prior notice.',
                        'AT Points employees are not allowed to participate in this activity.',
                        'Regarding the solicitation of personal data, the personal data collected by AT Points will have the opportunity to be used for brand promotion activities or other purposes, regardless of whether it is awarded or not.',
                        'Redemptoris need to bring along the prize collection notice and go to the designated place to collect the prizes in person. The redeemer must present a Hong Kong ID card and redeem it on the date and place specified by AT Points. For any prizes that cannot be claimed within the deadline, ATPoints reserves the right of final disposal.',
                        'Upon successful top-up of a designated amount, the wallet holder will be awarded an electronic cash coupon, directly deposited to the e-wallet account. The coupon is valid until the expiry date stated and the expiry date is non-extendable.',
                        '1AT Points is equal to HKD 1.2. AT Points cannot be redeemed for cash. is non-refundable.',
                        'Prizes are not transferable and cannot be exchanged for other gifts. The company reserves the right to substitute other gifts without prior notice.',
                        'This event is not related to Facebook and is not sponsored, supported, or managed by Facebook.',
                        'If there is any delay, loss, error, or inability to send or login information or notice to send AT Points due to computer, internet, telephone, technology, or reasons that are not attributable to AT Points Identification or damage. AT Points does not bear any legal responsibility.',
                        'All dates and times related to the event (including but not limited to the date and time of participating in the event, the date and time of participants\' response information) are subject to the AT Points Facebook page system report and subject to the final decision of Asia Top Loyalty Limited.',
                        'AT Points reserves the right to suspend, revise or change the content of this event and can revise or change these terms and conditions at any time without prior notice and any responsibility for this.',
                        'Any intellectual property rights and related moral rights arising from this event and thereafter are absolutely and permanently exclusively owned by ATPoints in all media worldwide.',
                        'Participants in this activity are deemed to have read and agree to be bound by these terms and conditions. In case of violation, ATPoints reserves the right to cancel its qualifications to participate or win prizes, and reserves the right to pursue any violations of this activity.',
                        'Asia Top Loyalty Limited reserves the right of final decision in case of disputes.'
                    ].map(
                        (it, index) => <li>{it}</li>
                    )
                }
            </ol>

        </div>
    )
}

export default Event_Page_roadshow_atp_member_2021_06