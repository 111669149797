import React from 'react';
// import { Redirect } from 'react-router-dom'
import {isMobile} from "react-device-detect";



const Error: React.FC = () => {

    return (
        <>
            <div style={{textAlign: "center", margin: "50px 0 80px 0"}} >
                <img src={require('../images/atp_logo.png').default} alt="atp_logo" width={isMobile ? "85%": "40%"} height={isMobile ? "85%": "40%"} />
            </div>


            <div style={{marginBottom: "80px"}}>
                <p style={{textAlign: "center", fontSize: isMobile ? "28px" : "32px", fontWeight: 'bolder', margin: '0 0 10px 0', color: "#314084"}}> Page Error </p>
                <p style={{textAlign: "center", fontSize: isMobile ? "28px" : "32px", fontWeight: 'bolder', margin: '0 0 10px 0', color: "#314084"}}> 404 Not Found </p>
            </div>
            {/* may delete */}
            <div>
                <p style={{textAlign: "center", fontSize: isMobile ? "26px" : "30px", fontWeight: 'bolder', margin: '0 0 10px 0', color: "#314084"}}> 現正跳轉回上一頁... </p>
                <p style={{textAlign: "center", fontSize: isMobile ? "26px" : "30px", fontWeight: 'bolder', margin: '0 0 10px 0', color: "#314084"}}> 现正跳转回上一页... </p>
                <p style={{textAlign: "center", fontSize: isMobile ? "28px" : "32px", fontWeight: 'bolder', margin: '0 0 10px 0', color: "#314084"}}> Redirecting to previous page... </p>
            </div>
        </>
    )
}

export default Error;
