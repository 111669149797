import React from "react";
import '../../css/RGRiceDumpling.css'

const RGRiceDumpling: React.FC = () =>{
    return (
        <>
            <div className="RGRiceDumpling-title"> 條款及細則 </div>
            <div className="RGRiceDumpling-terms"> - 查詢及預訂電話: 27332840 </div>
            <div className="RGRiceDumpling-terms"> - 5 隻或以上, 請預早 3 天致電預留。 </div>
            <div className="RGRiceDumpling-terms"> - 預訂取貨需要提供兌換券碼。 </div>
            <div className="RGRiceDumpling-terms"> - 取貨時需出示兌換券正本。 </div>
            <div className="RGRiceDumpling-terms"> - 兌換券不能用以套換其他面值之餐飲禮券。 </div>
            <div className="RGRiceDumpling-terms"> - 取貨時間: 每日早上 12 時至晚上 8 時。 </div>
            <div className="RGRiceDumpling-terms"> - 兌換券逾期作廢，恕不退換。 </div>
            <div className="RGRiceDumpling-terms"> - 兌換券不可兌換現金。 </div>
            <div className="RGRiceDumpling-terms"> - 產品供應視乎情況而定。 </div>
            <div className="RGRiceDumpling-terms"> - 如有任何爭議，帝苑酒店保留最終決定權。 </div>
            <div className="RGRiceDumpling-terms"> - 此優惠券由 AT Points by AsiaTop Loyalty Limited 提供。 </div>
            
            <div className="RGRiceDumpling-contactBox">
                <div className="RGRiceDumpling-contact"> 如有任何查詢，請與 AT Points 聯絡: </div>
                <div className="RGRiceDumpling-contact"> 聯絡電話: 2322 8350 </div>
                <div className="RGRiceDumpling-contact"> WhatsApp: +852 9311 6084 </div>
                <div className="RGRiceDumpling-contact"> 辦公時間: 星期一至五: 10am - 6pm (公眾假期例外) </div>
            </div>
            
        </>
    )
}

export default RGRiceDumpling;