import React, { useEffect } from "react";

export interface ToastProps {
  id: string;
  destroy: () => void;
  title: string;
  content: string;
  duration?: number;
}

const Toast: React.FC<ToastProps> = (props) => {
  const { destroy, content,  duration = 0, } = props;

  const toastBtn = {
    background: "none",
    border: "none",
    color: "#ffffff",
    fontWeight: 600,
    outline: "none",
    // cursor: pointer,
    borderRadius: "8px",
    padding: "4px 8px",
    marginLeft: "15px",
  }

  useEffect(() => {
    if (!duration) return;

    const timer = setTimeout(() => {
      destroy();
    }, duration);

    return () => clearTimeout(timer);
  }, [destroy, duration]);

  return (
    <div>
      {/* <div className={"toast-header"}>
        <div>{title} {id}</div>
        <button onClick={destroy}>X</button>
      </div> */}
      <div className={"toast-body"}>
          {content}
          {/*<button onClick={destroy} style={toastBtn}>X</button>*/}
    </div>
    </div>
  );
};

export default Toast;