/**
 * Path?d={distributor}&m={merchant}&i={info}&c={code}
 */
 import React, {useEffect, useState} from 'react';
 import '../../css/Template3.css';
 import TitleDetail from "../title_detail/TitleDetailProps";
 import TitleDetailComponent from "../title_detail/TitleDetailComponent";
 import CopyToClipboard from 'react-copy-to-clipboard';
 import 'bootstrap/dist/css/bootstrap.min.css';
 import { Button } from 'reactstrap';
 import {toast} from "../ToastManager";
 
 const QRCode = require('qrcode.react');
 
 const MultipleQr: React.FC<MultipleQrProps> = (props) => {
 
     const screenSize = window.innerWidth
 
     const [state, setState] = useState<boolean>(false);

     let logoSize = 'normal';
 
     useEffect(() => {
         document.title = props.productTitle
     })

     if (props.productTitle === "金沙流心奶黃月餅 Saint Honore Premium Lava Custard Mooncake") {
        logoSize = 'smaller';
     }
 
     return (
         <>
             {
                 props.isShowAtpLogo &&
                     <>
                         <img src={require('../../images/coupon_logo.png').default} alt="en_version_image" className="logoImg"
                              width={screenSize < 450 ? "125px" : "230px"} style={{margin: "20px 0 0 0"}}/>
                         <img src={require('../../images/background.png').default} alt="en_version_image" className="bgImg"
                              width={screenSize < 450 ? "82px" : "150px"}/>
                     </>
             }
 
             {props.merchantLogoSrc &&
             <img src={props.merchantLogoSrc}
                  alt="en_version_image"
                  height={screenSize < 450 ? "100px" : "180px"}/>}

             {props.distributerLogoSrc && logoSize === 'smaller' &&
             <img src={props.distributerLogoSrc}
                  alt="en_version_image"
                  height={screenSize < 450 ? "80px" : "125px"}/>}
                  
            {props.distributerLogoSrc && logoSize === 'normal' &&
             <img src={props.distributerLogoSrc}
                  alt="en_version_image"
                  height={screenSize < 450 ? "100px" : "180px"}/>}
 
             {
                 props.qrcode && 
                 props.qrcode.map((qr) => 
                    <div className="qrCode-box">
                        <QRCode value={qr} style={screenSize < 450 ? { width: "250px", height: "250px"} : { width: "450px", height: "450px"}}/>
                        <div>{qr}</div>
                    </div>
                 )
             }
 
             {
                 props.plainText&& <div className="qrCode-box">
                     <h3> 券碼 Voucher Code</h3>
                     <h1>{ props.plainText }</h1>
                     <CopyToClipboard
                         text={props.plainText}
                         onCopy={() =>{
                             toast.show({
                                 title: '',
                                 content: "成功複製 Copy successfully",
                                 duration: 1600,
                             })
                             setState(true)
                         }}>
                         <Button variant="info">點擊以複製券碼 Click me to copy</Button>
                     </CopyToClipboard>
                 </div>
             }
 
             <div className="text-box">
                 <div className="text"> <b>產品名稱: </b> {props.productTitle} </div>
                 {props.moneyValue && <div className="text"> <b>價值: </b> HKD{props.moneyValue} </div>}
                 <div className="text"> <b>到期日: </b> {props.expiryDate} </div>
             </div>
             <div className="text-box">
                 <div className="text"> 親愛的顧客， </div>
                 <div className="text"> 請小心妥善保存及保管你的電子禮券，如有遺失將不獲補發。 </div>
             </div>
             <div className="text-box">
                 <div className="text"> Dear Customer, </div>
                 <div className="text"> Please keep your eGift Voucher properly and confidential. This eGift Voucher is invalid and not replaceable if lost. </div>
             </div>
             {props.description?.map((it, index)=> <TitleDetailComponent key={index} title={it.title} details={it.details} style_index={it.style_index} />)}
         </>
     )
 };
 
 interface MultipleQrProps {
     productTitle: string,
     qrcode?: string[],
     plainText?: string,
     distributerLogoSrc?: string,
     merchantLogoSrc?: string,
     isShowAtpLogo: boolean,
     moneyValue?: string,
     expiryDate?: string
     description?: TitleDetail[]
 };
 
 export default MultipleQr;