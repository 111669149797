import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ActInfo from './pages/ActInfo';
import Error from './pages/Error';
import { RouteComponentProps } from 'react-router';
import {ThirdPartyHardcodeCouponPage} from "./pages/third_party_page/ThirdPartyHardcodeCouponPage";
import {MultipleQrPage} from "./pages/third_party_page/MultipleQrPage";
import {CouponQRCodePage} from "./pages/third_party_page/CouponQRCodePage";
import Event_Page_roadshow_atp_member_2021_06 from "./pages/event/Event_Page_roadshow_atp_member_2021_06";
import Event_Page_roadshow_atp_member_2021_06_sc from "./pages/event/Event_Page_roadshow_atp_member_2021_06_sc";
import Event_Page_roadshow_atp_member_2021_06_en from "./pages/event/Event_Page_roadshow_atp_member_2021_06_en";
import Term_And_Condition_en from "./pages/term_and_condition/Term_And_Condition_en";
import Term_And_Condition_tc from "./pages/term_and_condition/Term_And_Condition_tc";
import PaymentCancelPage from "./pages/payment_complete_page/PaymentCancelPage";
import PaymentFailPage from "./pages/payment_complete_page/PaymentFailPage";
import PaymentSuccessPage from "./pages/payment_complete_page/PaymentSuccessPage";

function App() {
  return (
    <Router>
      <Switch>
          <Route exact path="/" component={Error} />
          <Route exact path="/coupon/:param1" component={ActInfo} />
          <Route exact path="/error" component={Error} />
          <Route exact path="/qrcoupon/:param1/:param2/:param3" component={ThirdPartyHardcodeCouponPage} />
          <Route exact path="/qrcoupon_batch/:param1/:param2/:param3" component={MultipleQrPage} />
          <Route exact path="/qrcoupon_batch/OceanPark" component={CouponQRCodePage} />
          {/*  Marketing Event Page       */}
          <Route exact path="/event/v1/roadshow202106" component={Event_Page_roadshow_atp_member_2021_06} />
          <Route exact path="/event/v1/tc/roadshow202106" component={Event_Page_roadshow_atp_member_2021_06} />
          <Route exact path="/event/v1/sc/roadshow202106" component={Event_Page_roadshow_atp_member_2021_06_sc} />
          <Route exact path="/event/v1/en/roadshow202106" component={Event_Page_roadshow_atp_member_2021_06_en} />

          <Route exact path="/info/v1/en/t_and_c" component={Term_And_Condition_en} />
          <Route exact path="/info/v1/tc/t_and_c" component={Term_And_Condition_tc} />

          <Route exact path="/payment/success" component={PaymentSuccessPage} />
          <Route exact path="/payment/fail" component={PaymentFailPage} />
          <Route exact path="/payment/cancel" component={PaymentCancelPage} />


      </Switch>
    </Router>      
  );
}



export default App;

/**
 * React router params
 */
export interface MatchParams {
    param1?: string;
    param2?: string;
    param3?: string;
    param4?: string;
}

export interface RouteProps extends RouteComponentProps<MatchParams> {}


