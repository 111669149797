import React, {useEffect} from 'react';
import TitleDetailComponent from "../../components/title_detail/TitleDetailComponent";
import {haagen_dazs_tc} from "../third_party_page/constant";


const Event_Page_roadshow_atp_member_2021_06: React.FC = () => {

    useEffect(() => {
        document.title = "AT Points Day"
    }, []);

    return (            
        <div style={{margin: "25px 25px 25px 25px"}} >
            <div style={{textAlign: "center"}}><b>AT Points 會員慶第1賞：分享愛攝影比賽</b></div>
            <div><b>參與方法：</b></div>

            <ol style={{ padding: "0px 16px 0px 16px"}}>
                {
                    [
                        '下載AT Points 手機應用程式及登記成為會員',
                        '追蹤及讚好 𝐀𝐓𝐏𝐨𝐢𝐧𝐭𝐬 Facebook @at.points，上載相片及標籤@at.points',
                        '相片及帖子撰寫符合「Share Love 分ㆍ享愛 」主題',
                        '於帖子加上主題標籤 #ATPoints #手機APP #coupon #會員積分 #ATPoints會員慶#分享愛的時刻 #1分鐘瘋狂掃 #FRESH新鮮生活 #培養消費好習慣 #給你不一樣的消費體驗',
                        '帖子及相片設定為公開，並發佈到個人Facebook'
                    ].map(
                        (it, index) => <li>{it}</li>
                    )
                }
            </ol>

            <dl>
                <dd style={{ margin: 0 }}>* 參加者發佈作品數量不限，但每個AT Points 帳戶只有一次得獎機會。</dd>
            </dl>


            <div>AT Points 將選出合資格而最能傳達比賽主題「分ㆍ享愛」 的作品為得獎作品。</div>
            <div>最佳作品：得獎者可參與FRESH新鮮生活日出康城店指定範圍內免費瘋狂掃貨一分鐘之活動 （名額 2 位）。</div>
            <div>優秀作品：可獲 100 AT Points積分 （名額 8 位）</div>
            <div>優異作品：可獲 20 AT Points積分（名額 10 位）。</div>

            <br/>
            <br/>

            <div style={{textAlign: "center"}}>【 AT Points 會員慶第 1 賞：分享愛攝影比賽 】條款及細則</div>

            <ol style={{ padding: "0px 16px 0px 16px"}}>
                {
                    [
                        '亞洲卓盛有限公司（下稱「AT Points」）為是次攝影比賽（下稱「本活動」的主辦 單位）；參加者於其個人Facebook所上傳之帖子及相片為參賽材料（下稱「作品」）。',
                        '本活動截止時間為2021年6月27日晚上11時59分（香港時間），逾期參加將被取消參加資格。',
                        '參加活動前，請細閱以下條款及細則。參加者參與該活動，即代表已確認閱讀、明白，並同意遵守所有條款及細則。',
                        '參加者必須現居香港及持有香港身份證，以及年滿 18 歲或以上的 Facebook 用戶。',
                        '參加者須以 Facebook 個人帳戶參加比賽，其個人 Facebook 帖子須設定為公開分享。',
                        '參加者須完成指定參加步驟，方為合資格作品：'
                    ].map(
                        (it, index) => <li>{it}</li>
                    )
                }

                <dl>
                    <dd style={{ margin: 0 }}>a. 下載 AT Points 手機應用程式及登記成為會員</dd>
                    <dd style={{ margin: 0 }}>b. 追蹤及讚好 <b>ATPoints</b> Facebook @at.points，上載相片及標籤@at.points</dd>
                    <dd style={{ margin: 0 }}>c. 相片及帖子撰寫符合「Share Love 分ㆍ享愛 」主題</dd>
                    <dd style={{ margin: 0 }}>d. 於帖子加上主題標籤 #ATPoints #手機 APP #coupon #會員積分 #ATPoints 會員慶 #分享愛的時刻 #1 分鐘瘋狂掃 #FRESH 新鮮生活 #培養消費好習慣 #給你不一樣的消費體驗</dd>
                    <dd style={{ margin: 0 }}>e. 帖子及相片設定為公開，並發佈到個人 Facebook</dd>
                </dl>

                {
                    [
                        '參加者發佈作品數量不限，但每個 AT Points 帳戶只有一次得獎機會。',
                        'AT Points 將選出合資格而最能傳達比賽主題「分ㆍ享愛」的作品為得獎作品。'
                    ].map(
                        (it, index) => <li>{it}</li>
                    )
                }

                <dl>
                    <dd style={{ margin: 0 }}>a. 最佳作品：得獎者可參與 FRESH 新鮮生活日出康城店指定範圍內免費瘋狂掃貨一分鐘之活動（名額 2 位）；</dd>
                    <dd style={{ margin: 0 }}>b. 優秀作品：可獲 100 AT Points 積分 （名額 8 位）；</dd>
                    <dd style={{ margin: 0 }}>c. 優異作品：可獲 20 AT Points 積分（名額 10 位）。</dd>
                </dl>

                {
                    [
                        '比賽結果以主辦單位決定為準，參加者不得異議，比賽亦不設上訴機制。',
                        '最佳作品得獎者，可參與 2021 年 8 月 27 日於 FRESH 新鮮生活日出康城店指定 範圍內免費瘋狂掃貨 1 分鐘之活動。',
                        '作品須為參加者專屬擁有的原創作品及版權所有人，並無侵犯任何第三方的版權或任何其他權利，包括（但不限於）版權、商標、專利、商業秘密、私隱或其他個人或專利權，否則參加者會被取消參加資格。',
                        '參加者應保證已獲作品中出現的所有人士批准，並確保該人物亦同意 AT Points 在全球範圍內有權使用該影象。',
                        '如因參加者作品引起任何版權糾紛或其它第三方侵權糾紛，一切後果及賠償由參 賽者承擔，AT Points 對此不承擔任何責任。',
                        '凡發佈作品至個人 Facebook 及標籤@at.points 的所有人士即被視為申請參加本活 動，並同意接受本條款及細則約束。參加者乃自願參與本活動並同意自行承擔所有風險及責任。',
                        '所有參加者的作品一經提交，即視為同意授權 AT Points 及其合作商戶對參加作品作編輯之權利及以任何方式、任何時間、任何媒體複製、出版或刊登所有參加照片之內容、拍攝資料及參加者 Facebook 用戶名稱的權利（包括但不限於上載互聯網、供公眾欣賞及下載及作推廣或展覽之權利），而無須另徵參加者同意或向其支付版權費用。參加者確認及保證其已獲得在其作品中出現的可辨認人士的許可。如上述的保證不完整及不正確，參加者須承擔所有責任並向本會彌償其所有損失。',
                        '如發現參加者上載之參賽作品違反以下任何一項細則，AT Points 保留要求刪除作品之權利，同時參加者將會被取消參加資格而不作另行通知，包括：',

                    ].map(
                        (it, index) => <li>{it}</li>
                    )
                }

                <dl>
                    <dd style={{ margin: 0 }}>a. 作品不可違反可適用的法律規定，不含有個人政治內容；不含有任何個人隱私內容；不含有淫穢、色情、賭博、迷信、恐怖、暴力、侮辱、詆毀、誹謗、挑釁、攻擊性、造謠中傷的內容；不含有涉及民族、種族、宗教、性別歧視的內容；不含有虛假或者引人誤解的內容；不含影響其他品牌的不適當內容</dd>
                    <dd style={{ margin: 0 }}>b. 作品不符合 AT Points 品牌形象的內容</dd>

                </dl>

                {
                    [
                        '結果公佈：AT Points 會透過 Facebook Inbox 通知得獎者，得獎者須於 24 小時內回覆及提供所需領獎資料，否則當棄權論。得獎資格將由其他參賽者補上。',
                        '得獎者須於指定時間內出席活動才可獲得免費購物一分鐘的資格。得獎者必須為本人，並於出席活動時出示有效的身份證明文件以作核實。如得獎者無法於指定日期及時間內到指定地點參加活動；或因任何未能控制之因素或所遞交之個人資料有錯漏或不正確，以導致 AT Points 未能聯絡或核實得獎者身份，即視為放棄得獎資格，其得獎資格將被取消並由其他參賽者補上，而恕不作另行通知。得獎者亦不得要求補發或任何形式的補償。對於任何未能於期限內領取的奬品，AT Points 保留最終處理的權利。',
                        '如發現參加者以空號或假帳戶、外掛程式或其他非正式途徑參加此活動，或以任何程式擾亂或操控活動，AT Points 將取消該參賽者之參加資格，而不作另行通知。',
                        ' AT Points 員工均不能參與本活動，以示公允。',
                        '有關徵集個人資料，AT Points 所收集之個人資料，將有機會用作品牌推廣活動或等其他用途，不論得獎與否。',
                        '換領者需帶同領奬通知，親自到指定地點領取獎品。換領者必須出示有效香港身份證，於 AT Points 指定日期及地點換領。對於任何未能於期限內領取的奬品，AT Points 保留最終處理的權利。',
                        'AT Points 積分將直接轉到得獎者的 AT Points 錢包中，所有獎品均不可退換或兌換現金，亦不能更換、退款、轉讓及轉售、更換其他獎品及/或款式替代。',
                        '1 AT Points 等於港幣 $1.2 ，兌換 AT Points 後，不可兌換現金及不設退款。',
                        '獎品不得轉讓，亦不可兌換其他禮品。本公司有權以其他禮品代替而不作事先通知。',
                        '本活動與 Facebook 不相關，並非由 Facebook 贊助、支持或管理。',
                        '如有任何因電腦、網路、電話、技術或不可歸責於 AT Points 之事由，而使參加者所寄出、登錄之資料或使 AT Points 寄出之通知，有遲延、遺失、錯誤、無法辨識或毀損之情況，AT Points 均不負任何法律責任。',
                        '所有與活動有關之日期及時間（包括但不限於參加活動之日期及時間、參加者回覆資料之日期及時間）均以 AT Points Facebook 專頁系統報告為準及受制於本公司的最終決定。',
                        'AT Points 有權暫停、修訂或更改本活動內容並可隨時修訂或更改此條款及細則，而恕不另行通知，亦毋須為此承擔任何責任。',
                        '本活動當中及其後因而產生之任何知識產權及其相關精神權利及鄰接權，均由 AT Points 於所有媒介全球絕對永久獨家擁有。',
                        '凡參加本活動者，即被視為已細閱並同意接受此條款及細則約束。如有違反，AT Points 有權取消其參加或得獎資格，並對於任何破壞本活動之行為保留追究權利。',
                        '如有任何爭議，AT Points 保留取消參加者參加活動的資格及/或向參加者追究法律責任之權利，AT Points 保留一切有關行使之最終決定權。'
                    ].map(
                        (it, index) => <li>{it}</li>
                    )
                }
            </ol>

        </div>
    )
}

export default Event_Page_roadshow_atp_member_2021_06