import React, {useEffect} from 'react';
import TitleDetailComponent from "../../components/title_detail/TitleDetailComponent";
import {haagen_dazs_tc} from "../third_party_page/constant";
import {tc0,
    tc1,
    tc2,
    tc3,
    tc4,
    tc5,
    tc6,
    tc7,
    tc8,
    tc9,
    tc10,
    tc11,
    tc12,
    tc13,
    tc14,
    tc15,
    tc16,
    tc17,
    tc18,
    tc19,
    tc20,
    tc21,
    tc22,
    tc23,
    tc24,
    tc25,
    tc26,
    tc27,
    tc28,
    tc29,
    tc30,
    tc31} from "./constant";


const Term_And_Condition_tc: React.FC = () => {

    useEffect(() => {
        document.title = 'AT Points 條款及條件'
    }, []);

    return (            
        <>
            {[
                tc0,
                tc1,
                tc2,
                tc3,
                tc4,
                tc5,
                tc6,
                tc7,
                tc8,
                tc9,
                tc10,
                tc11,
                tc12,
                tc13,
                tc14,
                tc15,
                tc16,
                tc17,
                tc18,
                tc19,
                tc20,
                tc21,
                tc22,
                tc23,
                tc24,
                tc25,
                tc26,
                tc27,
                tc28,
                tc29,
                tc30,
                tc31,
            ].map((it, index)=> <TitleDetailComponent key={index} title={it.title} details={it.details} style_index={it.style_index} />)}

        </>
    )
}

export default Term_And_Condition_tc