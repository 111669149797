import {Component} from "react";
import MultipleQr from "../../components/third_party_ui/MultipleQr";
import {RouteProps} from "../../App";
import * as TitleDetailConstant from "./constant";
import { Redirect } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars

/**
 * This is a temporary third party coupon page
 *
 * props param 1 = distributer name e.g. daisen
 * props param 2 = voucher provider name e.g. haagen dazs*
 * props param 3 = qrcode value
 */
export class MultipleQrPage extends Component<RouteProps, any> {

    componentDidMount() {

    }

    render() {
        const distributerName = this.props.match.params.param1
        const voucherName = this.props.match.params.param2
        const productName = this.props.match.params.param3
        // productName -> {product name}{created date}{batch}

        if(distributerName === 'bea' && voucherName === 'sthonore' && productName === 'mooncake02092021_1') {
            // BEA x St Honore: a batch of 15 coupons
            return <MultipleQr
                productTitle={'金沙流心奶黃月餅 Saint Honore Premium Lava Custard Mooncake'}
                qrcode={["052235128622", "052235128633", "052235128641", "052235128652", "052235128663", "052235128671", "052235128682", "052235128693", "052235128701", "052235128711", "052235128722", "052235128733", "052235128741", "052235128752", "052235128763"]}
                distributerLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/bea-letterhead.png'}
                isShowAtpLogo={false}
                expiryDate={"19/09/2021"}
                description={[TitleDetailConstant.bea_sthonore_mooncake_tac_tc_1, TitleDetailConstant.bea_sthonore_mooncake_tac_en_1, TitleDetailConstant.bea_sthonore_mooncake_tac_tc_2,
                    TitleDetailConstant.bea_sthonore_mooncake_tac_tc_3, TitleDetailConstant.bea_sthonore_mooncake_tac_en_2, TitleDetailConstant.bea_sthonore_mooncake_tac_en_3]}
            />
        } else if (distributerName === 'bea' && voucherName === 'sthonore' && productName === 'mooncake02092021_2') {
            // BEA x St Honore: a batch of 15 coupons
            return <MultipleQr
                productTitle={'金沙流心奶黃月餅 Saint Honore Premium Lava Custard Mooncake'}
                qrcode={["052235128771", "052235128782", "052235128793", "052235128801", "052235128812", "052235128823", "052235128834", "052235128842", "052235128853", "052235128864", "052235128872", "052235128883", "052235128894", "052235128902", "052235128913"]}
                distributerLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/bea-letterhead.png'}
                isShowAtpLogo={false}
                expiryDate={"19/09/2021"}
                description={[TitleDetailConstant.bea_sthonore_mooncake_tac_tc_1, TitleDetailConstant.bea_sthonore_mooncake_tac_en_1, TitleDetailConstant.bea_sthonore_mooncake_tac_tc_2,
                    TitleDetailConstant.bea_sthonore_mooncake_tac_tc_3, TitleDetailConstant.bea_sthonore_mooncake_tac_en_2, TitleDetailConstant.bea_sthonore_mooncake_tac_en_3]}
            />
        }

        // error redirection
        return <Redirect to="/error" />;
    }
}