import {Component} from "react";
import Template3 from "../../components/third_party_ui/Template3";
import BroadwayCoupon from "../../components/third_party_ui/BroadwayCoupon";
import {RouteProps} from "../../App";
import * as TitleDetailConstant from "./constant";
import { Redirect } from 'react-router-dom';
import Template4 from "../../components/third_party_ui/Template4";
// eslint-disable-next-line @typescript-eslint/no-unused-vars

/**
 * This is a temporary third party coupon page
 *
 * props param 1 = distributer name e.g. daisen
 * props param 2 = voucher provider name e.g. haagen dazs*
 * props param 3 = qrcode value
 */
export class ThirdPartyHardcodeCouponPage extends Component<RouteProps, any> {

    componentDidMount() {

    }

    render() {
        const distributerName = this.props.match.params.param1
        const voucherName = this.props.match.params.param2
        const qrcodeValue = this.props.match.params.param3

        if(distributerName === 'bea' && voucherName === 'haagendazs' && qrcodeValue) {
            // 東亞銀行: haagen-dazs
            return <Template3
                productTitle={'Haagen Dazs $50 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/haagen-dazs.jpeg'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"28/02/2022"}
                description={[TitleDetailConstant.haagen_dazs_tc, TitleDetailConstant.haagen_dazs_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'jetco' && voucherName === 'foodpanda' && qrcodeValue) {
            // Jetco: foodpanda
            return <Template3
                productTitle={'foodpanda $50 Cash Voucher'}
                plainText={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/foodpanda.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"04/09/2021"}
                description={[TitleDetailConstant.foodpanda_instructions_tc, TitleDetailConstant.foodpanda_instructions_en, TitleDetailConstant.foodpanda_tandc_04_09_2021_tc, TitleDetailConstant.foodpanda_tandc_04_09_2021_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'jetco' && voucherName === 'deliveroo' && qrcodeValue) {
            // Jetco: Deliveroo
            return <Template3
                productTitle={'Deliveroo $50 Cash Voucher'}
                plainText={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/deliveroo.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"24/02/2022"}
                description={[TitleDetailConstant.deliveroo_instructions_tc, TitleDetailConstant.deliveroo_instructions_en, TitleDetailConstant.deliveroo_tandc_24_02_2022_tc, TitleDetailConstant.deliveroo_tandc_24_02_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'jetco' && voucherName === 'hft' && qrcodeValue) {
            // Jetco: 鴻福堂
            return <Template3
                productTitle={'鴻福堂 $20 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/HFT.png'}
                isShowAtpLogo={false}
                moneyValue={"20.00"}
                expiryDate={"28/02/2022"}
                description={[TitleDetailConstant.hft_tandc_28_02_2022_tc, TitleDetailConstant.hft_tandc_28_02_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'jetco' && voucherName === 'tenren' && qrcodeValue) {
            // Jetco: Tenren
            return <Template3
                productTitle={'天仁茗茶 $25 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/tenren.png'}
                isShowAtpLogo={false}
                moneyValue={"25.00"}
                expiryDate={"30/09/2021"}
                description={[TitleDetailConstant.tenren_tandc_28_02_2022_tc, TitleDetailConstant.tenren_tandc_28_02_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'jetco' && voucherName === 'pacificcoffee' && qrcodeValue) {
            // Jetco: Pacific Coffee
            return <Template3
                productTitle={'Pacific Coffee $25 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/ppc.png'}
                isShowAtpLogo={false}
                moneyValue={"25.00"}
                expiryDate={"01/08/2021"}
                description={[TitleDetailConstant.pacific_coffee_tandc_01_08_2021_tc, TitleDetailConstant.pacific_coffee_tandc_01_08_2021_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'jetco' && voucherName === 'toastbox' && qrcodeValue) {
            // Jetco: TOASTBOX
            return <Template3
                productTitle={'TOASTBOX $50 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/Toast+box.jpg'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"07/08/2021"}
                description={[TitleDetailConstant.TOASTBOX_tandc_07_08_2021_tc, TitleDetailConstant.TOASTBOX_tandc_07_08_2021_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'jetco' && voucherName === 'fairwood' && qrcodeValue) {
            // Jetco: Fairwood
            return <Template3
                productTitle={'大快活 $20 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/fairwood.png'}
                isShowAtpLogo={false}
                moneyValue={"20.00"}
                expiryDate={"28/02/2022"}
                description={[TitleDetailConstant.fairwood_tandc_tc, TitleDetailConstant.fairwood_tandc_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'jetco' && voucherName === 'sthonore' && qrcodeValue) {
            // Jetco: St Honore
            return <Template3
                productTitle={'St Honore $50 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/st_honore.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"31/12/2021"}
                description={[TitleDetailConstant.st_honore_tandc_31_12_2021_tc, TitleDetailConstant.st_honore_tandc_31_12_2021_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'jetco' && voucherName === 'haagendazs' && qrcodeValue) {
            // Jetco: Haagen Dazs
            return <Template3
                productTitle={'Haagen Dazs $50 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/haagen-dazs.jpeg'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"28/02/2022"}
                description={[TitleDetailConstant.haagen_dazs_tc, TitleDetailConstant.haagen_dazs_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'jetco' && voucherName === 'kingbakery' && qrcodeValue) {
            // Jetco: 蛋撻王
            return <Template3
                productTitle={'蛋撻王 $20 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/king_bakery.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"15/03/2022"}
                description={[TitleDetailConstant.king_bakery_tandc_15_03_2022_tc, TitleDetailConstant.king_bakery_tandc_15_03_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'jetco' && voucherName === 'jhc' && qrcodeValue) {
            // Jetco: 日本城
            return <Template3
                productTitle={'日本城 $50 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/JCH.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"31/05/2022"}
                description={[TitleDetailConstant.jhc_tandc_tc, TitleDetailConstant.jhc_tandc_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'jetco' && voucherName === 'shell' && qrcodeValue) {
            // Jetco: Shell
            return <Template3
                productTitle={'Shell $100 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                barcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/Shell+Logo.png'}
                isShowAtpLogo={false}
                moneyValue={"100.00"}
                expiryDate={"31/03/2023"}
                description={[
                    TitleDetailConstant.shell_title_tc,
                    TitleDetailConstant.shell_tandc_tc,
                    TitleDetailConstant.shell_title_en,
                    TitleDetailConstant.shell_tandc_en, TitleDetailConstant.shell_precautions_31_03_2023_tc, TitleDetailConstant.shell_precautions_31_03_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'towngas' && voucherName === 'seansin' && qrcodeValue) {
            // Towngas: 尚鮮
            return <Template3
                productTitle={'尚鮮海鮮料理$100現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/Seansin.png'}
                isShowAtpLogo={true}
                moneyValue={"100.00"}
                expiryDate={"06/08/2021"}
                description={[
                    TitleDetailConstant.seansin_tac_tc,
                    TitleDetailConstant.seansin_tac_en,
                ]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'towngas' && voucherName === 'happy_pot' && qrcodeValue) {
            // Towngas: 一樂小鍋
            return <Template3
                productTitle={'一樂小鍋$50現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/happy_pot_logo.png'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"06/08/2021"}
                description={[
                    TitleDetailConstant.happy_pot_tc,
                    TitleDetailConstant.happy_pot_en,
                ]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'towngas' && voucherName === 'ninoen' && qrcodeValue) {
            // Towngas: 一樂小鍋
            return <Template3
                productTitle={'日の苑$50現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/ninoen_logo.jpg'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"06/08/2021"}
                description={[
                    TitleDetailConstant.ninoen_tc,
                    TitleDetailConstant.ninoen_en,
                ]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'towngas' && voucherName === 'pacificcoffee' && qrcodeValue) {
            // Jetco: Pacific Coffee
            return <Template3
                productTitle={'Pacific Coffee $25 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                isShowAtpLogo={true}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/ppc.png'}
                moneyValue={"25.00"}
                expiryDate={"01/08/2021"}
                description={[TitleDetailConstant.pacific_coffee_tandc_01_08_2021_tc, TitleDetailConstant.pacific_coffee_tandc_01_08_2021_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'towngas' && voucherName === 'hft' && qrcodeValue) {
            return <Template3
                productTitle={'鴻福堂 $20 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/HFT.png'}
                isShowAtpLogo={true}
                moneyValue={"20.00"}
                expiryDate={"31/08/2021"}
                description={[
                    TitleDetailConstant.hft_tandc_header_tc,
                    TitleDetailConstant.hft_tandc_tc,
                    TitleDetailConstant.hft_tandc_header_en,
                    TitleDetailConstant.hft_tandc_en,
                ]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'chb' && voucherName === 'hft' && qrcodeValue) {
            // 創興銀行: 鴻福堂
            return <Template3
                productTitle={'鴻福堂 $20 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/HFT.png'}
                isShowAtpLogo={true}
                moneyValue={"20.00"}
                expiryDate={"28/02/2022"}
                description={[TitleDetailConstant.hft_tandc_28_02_2022_tc, TitleDetailConstant.hft_tandc_28_02_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'haagendazs' && qrcodeValue) {
            return <Template3
                productTitle={'Haagen Dazs $50現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/haagen-dazs.jpeg'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"28/02/2022"}
                description={[
                    TitleDetailConstant.haagen_dazs_tc,
                    TitleDetailConstant.haagen_dazs_en,
                ]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'GreenCommon' && qrcodeValue) {
            return <Template3
                productTitle={'Green Common $50現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/Green_Common_logo.jpg'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"31/01/2022"}
                description={[
                    TitleDetailConstant.green_common_tac_tc,
                    TitleDetailConstant.green_common_tac_en,
                ]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'fairwood' && qrcodeValue) {
            // TravelFlan: Fairwood
            return <Template3
                productTitle={'大快活 $20現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/fairwood.png'}
                isShowAtpLogo={false}
                moneyValue={"20.00"}
                expiryDate={"28/02/2022"}
                description={[TitleDetailConstant.fairwood_tandc_tc, TitleDetailConstant.fairwood_tandc_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'foodpanda' && qrcodeValue) {
            // TravelFlan: foodpanda
            return <Template3
                productTitle={'foodpanda $50現金券'}
                plainText={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/foodpanda.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"04/09/2021"}
                description={[
                    TitleDetailConstant.foodpanda_instructions_tc,
                    TitleDetailConstant.foodpanda_tandc_04_09_2021_tc,
                    TitleDetailConstant.foodpanda_instructions_en,
                    TitleDetailConstant.foodpanda_tandc_04_09_2021_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'foodpanda100' && qrcodeValue) {
            // TravelFlan: foodpanda
            return <Template3
                productTitle={'foodpanda $100現金券'}
                plainText={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/foodpanda.png'}
                isShowAtpLogo={false}
                moneyValue={"100.00"}
                expiryDate={"04/09/2021"}
                description={[
                    TitleDetailConstant.foodpanda_instructions_tc,
                    TitleDetailConstant.foodpanda_tandc_04_09_2021_tc,
                    TitleDetailConstant.foodpanda_instructions_en,
                    TitleDetailConstant.foodpanda_tandc_04_09_2021_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'breadtalk' && qrcodeValue) {
            // TravelFlan: foodpanda
            return <Template3
                productTitle={'BreadTalk $20 現金劵'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/BreadTalk.jpg'}
                isShowAtpLogo={false}
                moneyValue={"20.00"}
                expiryDate={"31/12/2021"}
                description={[
                    TitleDetailConstant.BreadTalk_tandc_31_12_2021_tc,
                    TitleDetailConstant.BreadTalk_tandc_31_12_2021_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'toastbox' && qrcodeValue) {
            return <Template3
                productTitle={'TOASTBOX $50 現金劵'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/Toast+box.jpg'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"31/12/2021"}
                description={[TitleDetailConstant.TOASTBOX_tandc_31_12_2021_tc, TitleDetailConstant.TOASTBOX_tandc_31_12_2021_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'lhk' && qrcodeValue) {
            // Jetco: 老行家
            return <Template3
                productTitle={'老行家 $100 現金劵'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/LHK+Logo1.jpg'}
                isShowAtpLogo={false}
                moneyValue={"100.00"}
                expiryDate={"31/12/2021"}
                description={[TitleDetailConstant.LoHongKa_tandc_31_12_2021_tc, TitleDetailConstant.LoHongKa_tandc_31_12_2021_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'otw50' && qrcodeValue) {
            // Jetco: 原味家作
            return <Template3
                productTitle={'原味家作 $50 現金劵'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/OTW_Logo.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"31/12/2021"}
                description={[TitleDetailConstant.OTW_tandc_50_tc, TitleDetailConstant.OTW_tandc_50_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'deliveroo' && qrcodeValue) {
            // Jetco: Deliveroo
            return <Template3
                productTitle={'Deliveroo $50現金券'}
                plainText={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/deliveroo.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"24/02/2022"}
                description={[
                    TitleDetailConstant.deliveroo_instructions_tc,
                    TitleDetailConstant.deliveroo_tandc_24_02_2022_tc,
                    TitleDetailConstant.deliveroo_instructions_en,
                    TitleDetailConstant.deliveroo_tandc_24_02_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'shell' && qrcodeValue) {
            // Jetco: Shell
            return <Template3
                productTitle={'Shell $100現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/Shell+Logo.png'}
                isShowAtpLogo={false}
                moneyValue={"100.00"}
                expiryDate={"31/03/2023"}
                description={[
                    TitleDetailConstant.shell_title_tc,
                    TitleDetailConstant.shell_tandc_tc,
                    TitleDetailConstant.shell_precautions_31_03_2023_tc,
                    TitleDetailConstant.shell_title_en,
                    TitleDetailConstant.shell_tandc_en,
                    TitleDetailConstant.shell_precautions_31_03_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'jhc' && qrcodeValue) {
            // Jetco: 日本城
            return <Template3
                productTitle={'日本城 $50現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/JCH.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"31/05/2022"}
                description={[TitleDetailConstant.jhc_tandc_number_tc, TitleDetailConstant.jhc_tandc_number_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'HealthWorks' && qrcodeValue) {
            // Jetco: HealthWorks
            return <Template3
                productTitle={'HealthWorks $30現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/Healthworks_logo.png'}
                isShowAtpLogo={false}
                moneyValue={"30.00"}
                expiryDate={"31/03/2022"}
                description={[TitleDetailConstant.HealthWorks_tac_tc, TitleDetailConstant.HealthWorks_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'hft2002282002' && qrcodeValue) {
            return <Template3
                productTitle={'鴻福堂$20 電子禮券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/HFT.png'}
                isShowAtpLogo={false}
                moneyValue={"20.00"}
                expiryDate={"28/02/2022"}
                description={[TitleDetailConstant.hft_tandc_28_02_2022_tc, TitleDetailConstant.hft_tandc_28_02_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'hft5002282002' && qrcodeValue) {
            return <Template3
                productTitle={'鴻福堂$50 電子禮券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/HFT.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"08/02/2022"}
                description={[TitleDetailConstant.hft_tandc_08_02_2022_50_tc, TitleDetailConstant.hft_tandc_08_02_2022_50_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        }  else if(distributerName === 'wechat' && voucherName === 'hft50-13112022' && qrcodeValue) {
            return <Template3
                productTitle={'鴻福堂$50 電子禮券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/HFT.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"13/11/2022"}
                description={[TitleDetailConstant.hft_tandc_08_02_2022_50_tc, TitleDetailConstant.hft_tandc_08_02_2022_50_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        }else if(distributerName === 'beademo' && voucherName === 'tenren' && qrcodeValue) {
            // Jetco: Tenren
            return <Template3
                productTitle={'天仁茗茶 $25 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                distributerLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/bea-logo.png'}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/tenren.png'}
                isShowAtpLogo={false}
                moneyValue={"25.00"}
                expiryDate={"30/09/2021"}
                description={[TitleDetailConstant.tenren_tandc_28_02_2022_tc, TitleDetailConstant.tenren_tandc_28_02_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'atpoints' && voucherName === 'atp25' && qrcodeValue && qrcodeValue.includes('&')) {
            // 2 qr code demo
            let qrcodes = this.props.match.params.param3;
            let qrcodeArr = qrcodes!.split('&');
            let qr1 = qrcodeArr[0];
            let qr2 = qrcodeArr[1];
            return <Template3
                productTitle={'AT Points $25 Cash Coupon'}
                qrcode={qr1}
                qrcode2={qr2}
                // distributerLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/bea-logo.png'}
                // merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/atpoints.jpeg'}
                isShowAtpLogo={true}
                moneyValue={"25.00"}
                expiryDate={"31/12/2021"}
                description={[TitleDetailConstant.atp25_tac_tc, TitleDetailConstant.atp25_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'tenren' && qrcodeValue) {
            // travelflan: Tenren
            return <Template3
                productTitle={'天仁茗茶 $25 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/tenren.png'}
                isShowAtpLogo={false}
                moneyValue={"25.00"}
                expiryDate={"09/02/2022"}
                description={[TitleDetailConstant.tenren_tandc_28_02_2022_tc, TitleDetailConstant.tenren_tandc_28_02_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'bdss3031122021' && qrcodeValue) {
            // travelflan: baodim
            return <Template3
                productTitle={'包點先生 $30現金劵'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/bdss.jpg'}
                isShowAtpLogo={false}
                moneyValue={"30.00"}
                expiryDate={"31/12/2021"}
                description={[TitleDetailConstant.bdss_tac_tc, TitleDetailConstant.bdss_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'tws5031012022' && qrcodeValue) {
            // travelflan: the walnut shop
            return <Template3
                productTitle={'紹香園 $50 現金劵'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/tws.jpeg'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"31/01/2022"}
                description={[TitleDetailConstant.tws_tac_tc, TitleDetailConstant.tws_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'sthonore5031122021' && qrcodeValue) {
            // travelflan: St Honore
            return <Template3
                productTitle={'St Honore $50 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/st_honore.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"31/12/2021"}
                description={[TitleDetailConstant.st_honore_tandc_31_12_2021_tc, TitleDetailConstant.st_honore_tandc_31_12_2021_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'kingbakery2015032022' && qrcodeValue) {
            // travelflan: 蛋撻王
            return <Template3
                productTitle={'蛋撻王 $20 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/king_bakery.png'}
                isShowAtpLogo={false}
                moneyValue={"20.00"}
                expiryDate={"15/03/2022"}
                description={[TitleDetailConstant.king_bakery_tandc_15_03_2022_tc, TitleDetailConstant.king_bakery_tandc_15_03_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'cupping5031032022' && qrcodeValue) {
            // travelflan: cupping room
            return <Template3
                productTitle={'Cupping Room $50 現金劵'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/cupping.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"31/03/2022"}
                description={[TitleDetailConstant.cupping_tac_tc, TitleDetailConstant.cupping_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'orix' && voucherName === 'pacificcoffee' && qrcodeValue) {
            // ORIX: Pacific Coffee
            return <Template3
                productTitle={'Pacific Coffee HK$25電子咖啡現金券 Pacific Coffee $25 e-cash Coffee Coupon'}
                qrcode={this.props.match.params.param3}
                isShowAtpLogo={false}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/ppc.png'}
                moneyValue={"25.00"}
                expiryDate={"16/03/2022"}
                description={[TitleDetailConstant.pacific_coffee_tandc_16_03_2022_tc, TitleDetailConstant.pacific_coffee_tandc_16_03_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'bea' && voucherName === 'HealthWorks' && qrcodeValue) {
            return <Template3
                productTitle={'HealthWorks $30現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/Healthworks_logo.png'}
                isShowAtpLogo={true}
                moneyValue={"30.00"}
                expiryDate={"31/03/2022"}
                description={[TitleDetailConstant.HealthWorks_tac_tc, TitleDetailConstant.HealthWorks_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
        />
        } else if (distributerName === 'bea' && voucherName === 'toastbox' && qrcodeValue) {
            return <Template3
                productTitle={'TOASTBOX $50 現金劵'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/Toast+box.jpg'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"31/12/2021"}
                description={[TitleDetailConstant.TOASTBOX_tandc_31_12_2021_tc, TitleDetailConstant.TOASTBOX_tandc_31_12_2021_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
        />
        } else if (distributerName === 'bea' && voucherName === 'pacificcoffee' && qrcodeValue) {
            return <Template3
                productTitle={'Pacific Coffee HK$25電子咖啡現金券 Pacific Coffee $25 e-cash Coffee Coupon'}
                qrcode={this.props.match.params.param3}
                isShowAtpLogo={true}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/ppc.png'}
                moneyValue={"25.00"}
                expiryDate={"16/03/2022"}
                description={[TitleDetailConstant.pacific_coffee_tandc_16_03_2022_tc, TitleDetailConstant.pacific_coffee_tandc_16_03_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'orix' && voucherName === 'atp' && qrcodeValue) {
            return <Template3
                productTitle={'ORIX: 167 AT Points 積分劵 167 AT Points Coupon'}
                qrcode={this.props.match.params.param3}
                isShowAtpLogo={true}
                moneyValue={"200.40"}
                expiryDate={"19/04/2022"}
                description={[TitleDetailConstant.atp_tac_tc, TitleDetailConstant.atp_tac_en]}
                showOrixUserGuide={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'orix' && voucherName === 'atp50030092023' && qrcodeValue) {
            return <Template3
                productTitle={'BYON GO! HKD 500 AT Rewards Coupon'}
                qrcode={this.props.match.params.param3}
                isShowAtpLogo={true}
                moneyValue={"500.00"}
                expiryDate={"30/09/2023"}
                description={[TitleDetailConstant.atp_tac_tc, TitleDetailConstant.atp_tac_en]}
                showOrixUserGuide={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'orix' && voucherName === 'atp50031122022' && qrcodeValue) {
            return <Template3
                productTitle={'BYON GO! HKD500 AT Rewards Coupon'}
                qrcode={this.props.match.params.param3}
                isShowAtpLogo={true}
                moneyValue={"500.00"}
                expiryDate={"30/06/2023"}
                description={[TitleDetailConstant.atp_tac_tc, TitleDetailConstant.atp_tac_en]}
                showOrixUserGuide={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'orix' && voucherName === 'atp834' && qrcodeValue) {
            return <Template3
                productTitle={'BYON GO! HKD100 AT Rewards Coupon'}
                qrcode={this.props.match.params.param3}
                isShowAtpLogo={true}
                moneyValue={"100.08"}
                expiryDate={"30/06/2023"}
                description={[TitleDetailConstant.atp_orix_tac_tc, TitleDetailConstant.atp_orix_tac_en]}
                showOrixUserGuide={false}
                showOrixUserGuide2={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'towngas' && voucherName === 'atp20_01112022' && qrcodeValue) {
            return <Template3
                productTitle={'Towngas: 20 AT Points 積分劵 20 AT Points Coupon'}
                qrcode={this.props.match.params.param3}
                isShowAtpLogo={true}
                moneyValue={"24.00"}
                expiryDate={"01/11/2022"}
                description={[TitleDetailConstant.atp_tac_tc, TitleDetailConstant.atp_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'towngas' && voucherName === 'atp20_03112022' && qrcodeValue) {
            return <Template3
                productTitle={'Towngas: 20 AT Points 積分劵 20 AT Points Coupon'}
                qrcode={this.props.match.params.param3}
                isShowAtpLogo={true}
                moneyValue={"24.00"}
                expiryDate={"03/11/2022"}
                description={[TitleDetailConstant.atp_tac_tc, TitleDetailConstant.atp_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'travelflan' && voucherName === 'godiva5030062022' && qrcodeValue) {
            return <Template3
                productTitle={'Godiva $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/godiva.jpg'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"30/06/2022"}
                description={[TitleDetailConstant.godiva_tac_tc, TitleDetailConstant.godiva_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'travelflan' && voucherName === '7595031122022' && qrcodeValue) {
            return <Template3
                productTitle={'759阿信屋 $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/store759.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"31/12/2022"}
                description={[TitleDetailConstant.store759_tac_tc, TitleDetailConstant.store759_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'towngas' && voucherName === 'breadtalk2031122021' && qrcodeValue) {
            return <Template3
                productTitle={'BreadTalk $20 現金劵'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/BreadTalk.jpg'}
                isShowAtpLogo={true}
                moneyValue={"20.00"}
                expiryDate={"31/12/2021"}
                description={[TitleDetailConstant.BreadTalk_tandc_31_12_2021_tc, TitleDetailConstant.BreadTalk_tandc_31_12_2021_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'towngas' && voucherName === 'toastbox5031122021' && qrcodeValue) {
            return <Template3
                productTitle={'TOASTBOX $50 現金劵'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/Toast+box.jpg'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"31/12/2021"}
                description={[TitleDetailConstant.TOASTBOX_tandc_31_12_2021_tc, TitleDetailConstant.TOASTBOX_tandc_31_12_2021_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'towngas' && voucherName === 'otw5031122021' && qrcodeValue) {
            return <Template3
                productTitle={'原味家作 $50 現金劵'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/OTW_Logo.png'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"31/12/2021"}
                description={[TitleDetailConstant.OTW_tandc_50_tc, TitleDetailConstant.OTW_tandc_50_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'towngas' && voucherName === 'bdss3031122021' && qrcodeValue) {
            return <Template3
                productTitle={'包點先生 $30現金劵'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/bdss.jpg'}
                isShowAtpLogo={true}
                moneyValue={"30.00"}
                expiryDate={"31/12/2021"}
                description={[TitleDetailConstant.bdss_tac_tc, TitleDetailConstant.bdss_tac_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'user-25112021' && voucherName === 'breadtalk2031122021' && qrcodeValue) {
            return <Template3
                productTitle={'BreadTalk $20 現金劵'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/BreadTalk.jpg'}
                isShowAtpLogo={true}
                moneyValue={"20.00"}
                expiryDate={"31/12/2021"}
                description={[TitleDetailConstant.BreadTalk_tandc_31_12_2021_tc, TitleDetailConstant.BreadTalk_tandc_31_12_2021_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'towngas' && voucherName === 'hft50-08022022' && qrcodeValue) {
            return <Template3
                productTitle={'鴻福堂 $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/HFT.png'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"08/02/2022"}
                description={[TitleDetailConstant.hft_tandc_08_02_2022_50_tc, TitleDetailConstant.hft_tandc_08_02_2022_50_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'towngas' && voucherName === 'hft20-28022022' && qrcodeValue) {
            return <Template3
                productTitle={'鴻福堂 $20 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/HFT.png'}
                isShowAtpLogo={true}
                moneyValue={"20.00"}
                expiryDate={"28/02/2022"}
                description={[TitleDetailConstant.hft_tandc_28_02_2022_tc, TitleDetailConstant.hft_tandc_28_02_2022_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'towngas' && voucherName === 'tenren25-09022022' && qrcodeValue) {
            return <Template3
                productTitle={"天仁茗茶 $25 電子現金券 TenRen's Tea $25 Cash e-Coupon"}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/tenren.png'}
                isShowAtpLogo={true}
                moneyValue={"25.00"}
                expiryDate={"09/02/2022"}
                description={[TitleDetailConstant.tenren_tandc_28_02_2022_tc, TitleDetailConstant.tenren_tandc_28_02_2022_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'towngas' && voucherName === 'healthworks30-31032022' && qrcodeValue) {
            return <Template3
                productTitle={'HealthWorks $30 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/Healthworks_logo.png'}
                isShowAtpLogo={true}
                moneyValue={"30.00"}
                expiryDate={"31/03/2022"}
                description={[TitleDetailConstant.HealthWorks_tac_tc, TitleDetailConstant.HealthWorks_tac_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'towngas' && voucherName === 'haagendazs50-28022022' && qrcodeValue) {
            return <Template3
                productTitle={'Haagen Dazs $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/haagen-dazs.jpeg'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"28/02/2022"}
                description={[TitleDetailConstant.haagen_dazs_tc, TitleDetailConstant.haagen_dazs_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'towngas' && voucherName === 'eugenebaby100-04032022' && qrcodeValue) {
            return <Template3
                productTitle={'Eugenebaby $100 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/eugenebaby.png'}
                isShowAtpLogo={true}
                moneyValue={"100.00"}
                expiryDate={"04/03/2022"}
                description={[TitleDetailConstant.eugenebaby_tac_04032022_tc, TitleDetailConstant.eugenebaby_tac_04032022_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'towngas' && voucherName === 'echome50-24032022' && qrcodeValue) {
            return <Template3
                productTitle={'億世家 $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/echome.png'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"24/03/2022"}
                description={[TitleDetailConstant.echome_tac_240320222_tc, TitleDetailConstant.echome_tac_24032022_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'towngas' && voucherName === 'bossini100-31032022' && qrcodeValue) {
            return <Template3
                productTitle={'Bossini $100 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/bossini.jpg'}
                isShowAtpLogo={true}
                moneyValue={"100.00"}
                expiryDate={"31/03/2022"}
                description={[TitleDetailConstant.bossini_tac_31032022_tc, TitleDetailConstant.bossini_tac_31032022_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'towngas' && voucherName === 'jhc50-31052022' && qrcodeValue) {
            return <Template3
                productTitle={'日本城 $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/JCH.png'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"31/05/2022"}
                description={[TitleDetailConstant.jhc_tandc_tc, TitleDetailConstant.jhc_tandc_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'user' && voucherName === 'toastbox5031122021' && qrcodeValue) {
            return <Template3
                productTitle={'TOASTBOX $50 現金劵'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/Toast+box.jpg'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"31/12/2021"}
                description={[TitleDetailConstant.TOASTBOX_tandc_31_12_2021_tc, TitleDetailConstant.TOASTBOX_tandc_31_12_2021_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'travelflan' && voucherName === 'foodpanda5015042022' && qrcodeValue) {
            // TravelFlan: foodpanda
            return <Template3
                productTitle={'foodpanda $50現金券'}
                plainText={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/foodpanda.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"15/04/2022"}
                description={[
                    TitleDetailConstant.foodpanda_instructions_tc,
                    TitleDetailConstant.foodpanda_tandc_15_04_2022_tc,
                    TitleDetailConstant.foodpanda_instructions_en,
                    TitleDetailConstant.foodpanda_tandc_15_04_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'chonghing' && voucherName === 'fairwood20-28022022' && qrcodeValue) {
            return <Template3
                productTitle={'大快活 $20 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/fairwood.png'}
                isShowAtpLogo={false}
                moneyValue={"20.00"}
                expiryDate={"28/02/2022"}
                description={[TitleDetailConstant.fairwood_tandc_tc, TitleDetailConstant.fairwood_tandc_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'chonghing' && voucherName === 'tenren25-09022022' && qrcodeValue) {
            return <Template3
                productTitle={'天仁茗茶 $25 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/tenren.png'}
                isShowAtpLogo={false}
                moneyValue={"25.00"}
                expiryDate={"09/02/2022"}
                description={[TitleDetailConstant.tenren_tandc_28_02_2022_tc, TitleDetailConstant.tenren_tandc_28_02_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'chonghing' && voucherName === 'godiva50-30062022' && qrcodeValue) {
            return <Template3
                productTitle={'Godiva $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/godiva.jpg'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"30/06/2022"}
                description={[TitleDetailConstant.godiva_tac_tc, TitleDetailConstant.godiva_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'chonghing' && voucherName === 'hktv50-30112022' && qrcodeValue) {
            return <Template3
                productTitle={'HKTV Mall $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/hktvmall.jpg'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"30/11/2022"}
                description={[TitleDetailConstant.hktv_tandc_tc, TitleDetailConstant.hktv_tandc_en]}
                showHktvmallManual={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'chonghing' && voucherName === 'sthonore2531122023' && qrcodeValue){
            return <Template3
                productTitle={'St Honore $25 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/st_honore.png'}
                isShowAtpLogo={false}
                moneyValue={"25.00"}
                expiryDate={"31/12/2023"}
                description={[TitleDetailConstant.st_honore_tandc_31_12_2023_tc, TitleDetailConstant.st_honore_tandc_31_12_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        }else if(distributerName === 'atpoints' && voucherName === 'broadway100-24122022' && qrcodeValue) {
            // console.log(qrcodeValue);
            const splitValues = qrcodeValue.split('&');
            const qrCode = splitValues[0];
            const cardNo = splitValues[1];
            const pin = splitValues[2];
            return <BroadwayCoupon
                productTitle={'Broadway HK$100 e-Gift Card'}
                qrcode={qrCode}
                moneyValue={"HK$100 e-Gift Card"}
                expiryDate={"24 Dec 2022"}
                pin={pin}
                cardNo={cardNo}
            />
        } else if(distributerName === 'atpoints' && voucherName === 'foodpanda50-15042022' && qrcodeValue) {
            return <Template3
                productTitle={'foodpanda $50 Cash Voucher'}
                plainText={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/foodpanda.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"15/04/2022"}
                description={[TitleDetailConstant.foodpanda_instructions_tc, TitleDetailConstant.foodpanda_instructions_en, TitleDetailConstant.foodpanda_tandc_15_04_2022_tc, TitleDetailConstant.foodpanda_tandc_15_04_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'atpoints' && voucherName === 'foodpanda50-31032022' && qrcodeValue) {
            return <Template3
                productTitle={'foodpanda $50 Cash Voucher'}
                plainText={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/foodpanda.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"31/03/2022"}
                description={[TitleDetailConstant.foodpanda_instructions_tc, TitleDetailConstant.foodpanda_instructions_en, TitleDetailConstant.foodpanda_tandc_31_03_2022_tc, TitleDetailConstant.foodpanda_tandc_31_03_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'towngas' && voucherName === 'atp20_31122022' && qrcodeValue) {
            return <Template3
                productTitle={'Towngas: 20 AT Points 積分劵 20 AT Points Coupon'}
                qrcode={this.props.match.params.param3}
                isShowAtpLogo={true}
                moneyValue={"24.00"}
                expiryDate={"31/12/2022"}
                description={[TitleDetailConstant.atp_tac_tc, TitleDetailConstant.atp_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'atpoints' && voucherName === 'cafe10050-30042022' && qrcodeValue) {
            return <Template3
                productTitle={'百份百餐廳 $50 現金劵 Café 100% $50 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                isShowAtpLogo={false}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/cafe100.png'}
                moneyValue={"50.00"}
                expiryDate={"30/04/2022"}
                description={[TitleDetailConstant.cafe100_tandc_30_04_2022_tc, TitleDetailConstant.cafe100_tandc_30_04_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'bea' && voucherName === 'oceanpark-12052022' && qrcodeValue) {
            return <Template3
                productTitle={'香港海洋公園成人門票'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/oceanpark.png'}
                isShowAtpLogo={true}
                moneyValue={"996.00"}
                expiryDate={"12/05/2022"}
                description={[TitleDetailConstant.oceanpark_tandc_12052022_tc, TitleDetailConstant.oceanpark_tandc_12052022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
        />
        } else if(distributerName === 'jetco' && voucherName === 'deliveroo50-11112022' && qrcodeValue) {
            return <Template3
                productTitle={'Deliveroo $50 Cash Voucher'}
                plainText={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/deliveroo.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"11/11/2022"}
                description={[TitleDetailConstant.deliveroo_instructions_tc, TitleDetailConstant.deliveroo_instructions_en, TitleDetailConstant.deliveroo_tandc_11_11_2022_tc, TitleDetailConstant.deliveroo_tandc_11_11_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'atpoints' && voucherName === 'deliveroo100-11112022' && qrcodeValue) {
            return <Template3
                productTitle={'Deliveroo $100 Cash Voucher'}
                plainText={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/deliveroo.png'}
                isShowAtpLogo={false}
                moneyValue={"100.00"}
                expiryDate={"11/11/2022"}
                description={[TitleDetailConstant.deliveroo_instructions_tc, TitleDetailConstant.deliveroo_instructions_en, TitleDetailConstant.deliveroo_tandc_11_11_2022_tc, TitleDetailConstant.deliveroo_tandc_11_11_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'zinghouse' && voucherName === 'tenren25-09022022' && qrcodeValue) {
            return <Template3
                productTitle={'天仁茗茶 $25 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/tenren.png'}
                isShowAtpLogo={false}
                showAtpContact={true}
                moneyValue={"25.00"}
                expiryDate={"09/02/2022"}
                description={[TitleDetailConstant.tenren_tandc_28_02_2022_tc, TitleDetailConstant.tenren_tandc_28_02_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'atpoints-2' && voucherName === 'deliveroo100-11112022' && qrcodeValue) {
            return <Template3
                productTitle={'Deliveroo $100 Cash Voucher'}
                plainText={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/deliveroo.png'}
                isShowAtpLogo={false}
                moneyValue={"100.00"}
                expiryDate={"11/11/2022"}
                description={[TitleDetailConstant.deliveroo_instructions_tc, TitleDetailConstant.deliveroo_instructions_en, TitleDetailConstant.deliveroo_tandc_11_11_2022_tc, TitleDetailConstant.deliveroo_tandc_11_11_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'atpoints' && voucherName === 'haagendazs50-28022022' && qrcodeValue) {
            return <Template3
                productTitle={'Haagen Dazs $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/haagen-dazs.jpeg'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"28/02/2022"}
                description={[TitleDetailConstant.haagen_dazs_tc, TitleDetailConstant.haagen_dazs_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'atpoints' && voucherName === 'healthworks30-31032022' && qrcodeValue) {
            return <Template3
                productTitle={'HealthWorks $30 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/Healthworks_logo.png'}
                isShowAtpLogo={true}
                moneyValue={"30.00"}
                expiryDate={"31/03/2022"}
                description={[TitleDetailConstant.HealthWorks_tac_tc, TitleDetailConstant.HealthWorks_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'atpoints' && voucherName === 'tenren25-09022022' && qrcodeValue) {
            return <Template3
                productTitle={'天仁茗茶 $25 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/tenren.png'}
                isShowAtpLogo={true}
                moneyValue={"25.00"}
                expiryDate={"09/02/2022"}
                description={[TitleDetailConstant.tenren_tandc_28_02_2022_tc, TitleDetailConstant.tenren_tandc_28_02_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'wechat' && voucherName === 'tenren25-16122022' && qrcodeValue) {
            return <Template3
                productTitle={'天仁茗茶 $25 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/tenren.png'}
                isShowAtpLogo={true}
                moneyValue={"25.00"}
                expiryDate={"16/12/2022"}
                description={[TitleDetailConstant.tenren_tandc_28_02_2022_tc, TitleDetailConstant.tenren_tandc_28_02_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'towngas' && voucherName === 'fairwood20-28022022' && qrcodeValue) {
            return <Template3
                productTitle={'大快活 $20 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/fairwood.png'}
                isShowAtpLogo={true}
                moneyValue={"20.00"}
                expiryDate={"28/02/2022"}
                description={[TitleDetailConstant.fairwood_tandc_tc, TitleDetailConstant.fairwood_tandc_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'towngas' && voucherName === 'kingbakery20-15032022' && qrcodeValue) {
            return <Template3
                productTitle={'蛋撻王 $20 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/king_bakery.png'}
                isShowAtpLogo={true}
                moneyValue={"20.00"}
                expiryDate={"15/03/2022"}
                description={[TitleDetailConstant.king_bakery_tandc_15_03_2022_tc, TitleDetailConstant.king_bakery_tandc_15_03_2022_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'towngas' && voucherName === 'sthonore25-30-62022' && qrcodeValue) {
            return <Template3
                productTitle={'St Honore $25 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/st_honore.png'}
                isShowAtpLogo={true}
                moneyValue={"25.00"}
                expiryDate={"30/06/2022"}
                description={[TitleDetailConstant.st_honore_tandc_30_06_2022_tc, TitleDetailConstant.st_honore_tandc_30_06_2022_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'towngas-2' && voucherName === 'hft20-28022022' && qrcodeValue) {
            return <Template3
                productTitle={'鴻福堂 $20 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/HFT.png'}
                isShowAtpLogo={true}
                moneyValue={"20.00"}
                expiryDate={"28/02/2022"}
                description={[TitleDetailConstant.hft_tandc_28_02_2022_tc, TitleDetailConstant.hft_tandc_28_02_2022_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'atpoints' && voucherName === 'hft20-28022022' && qrcodeValue) {
            return <Template3
                productTitle={'鴻福堂 $20 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/HFT.png'}
                isShowAtpLogo={true}
                moneyValue={"20.00"}
                expiryDate={"28/02/2022"}
                description={[TitleDetailConstant.hft_tandc_28_02_2022_tc, TitleDetailConstant.hft_tandc_28_02_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'towngas' && voucherName === 'healthworks10-31032022' && qrcodeValue) {
            return <Template3
                productTitle={'HealthWorks $10 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/Healthworks_logo.png'}
                isShowAtpLogo={true}
                moneyValue={"10.00"}
                expiryDate={"31/03/2022"}
                description={[TitleDetailConstant.HealthWorks_10_tac_tc, TitleDetailConstant.HealthWorks_10_tac_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'atpointsdemo' && voucherName === 'hft20-28022022' && qrcodeValue) {
            return <Template3
                productTitle={'鴻福堂 $20 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/HFT.png'}
                isShowAtpLogo={true}
                moneyValue={"20.00"}
                expiryDate={"28/02/2022"}
                description={[TitleDetailConstant.hft_tandc_28_02_2022_tc, TitleDetailConstant.hft_tandc_28_02_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'atpoints' && voucherName === 'hft20-28022023' && qrcodeValue) {
            return <Template3
                productTitle={'鴻福堂 $20 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/HFT.png'}
                isShowAtpLogo={true}
                moneyValue={"20.00"}
                expiryDate={"28/02/2023"}
                description={[TitleDetailConstant.hft_tandc_28_02_2023_tc, TitleDetailConstant.hft_tandc_28_02_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'jetco' && voucherName === 'deliveroo50-11012023' && qrcodeValue) {
            return <Template3
                productTitle={'Deliveroo $50 Cash Voucher'}
                plainText={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/deliveroo.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"11/01/2023"}
                description={[TitleDetailConstant.deliveroo_instructions_tc, TitleDetailConstant.deliveroo_instructions_en, TitleDetailConstant.deliveroo_tandc_23_01_2023_tc, TitleDetailConstant.deliveroo_tandc_23_01_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'atrewards' && voucherName === 'cafedecoral-31012024' && qrcodeValue) {
            return <Template3
                productTitle={'大家樂 $20 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/cafedecoral.jpeg'}
                isShowAtpLogo={true}
                moneyValue={"20.00"}
                expiryDate={"31/01/2024"}
                description={[TitleDetailConstant.cafedecoral20_tandc_tc, TitleDetailConstant.cafedecoral20_tandc_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'atpoints' && voucherName === 'healthworks30-31032022' && qrcodeValue) {
            return <Template3
                productTitle={'HealthWorks $30 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/Healthworks_logo.png'}
                isShowAtpLogo={true}
                moneyValue={"30.00"}
                expiryDate={"31/03/2022"}
                description={[TitleDetailConstant.HealthWorks_tac_tc, TitleDetailConstant.HealthWorks_tac_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'atpoints' && voucherName === 'healthworks10-31032022' && qrcodeValue) {
            return <Template3
                productTitle={'HealthWorks $10 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/Healthworks_logo.png'}
                isShowAtpLogo={true}
                moneyValue={"10.00"}
                expiryDate={"31/03/2022"}
                description={[TitleDetailConstant.HealthWorks_10_tac_tc, TitleDetailConstant.HealthWorks_10_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'ccb' && voucherName === 'deliveroo50-11012023' && qrcodeValue) {
            return <Template3
                productTitle={'Deliveroo $50 Cash Voucher'}
                plainText={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/deliveroo.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"11/01/2023"}
                description={[TitleDetailConstant.deliveroo_instructions_tc, TitleDetailConstant.deliveroo_instructions_en, TitleDetailConstant.deliveroo_tandc_23_01_2023_tc, TitleDetailConstant.deliveroo_tandc_23_01_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'ccb' && voucherName === 'deliveroo100-11012023' && qrcodeValue) {
            return <Template3
                productTitle={'Deliveroo $100 Cash Voucher'}
                plainText={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/deliveroo.png'}
                isShowAtpLogo={false}
                moneyValue={"100.00"}
                expiryDate={"11/01/2023"}
                description={[TitleDetailConstant.deliveroo_instructions_tc, TitleDetailConstant.deliveroo_instructions_en, TitleDetailConstant.deliveroo_tandc_23_01_2023_tc, TitleDetailConstant.deliveroo_tandc_23_01_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'ccb' && voucherName === 'foodpanda50-31082022' && qrcodeValue) {
            return <Template3
                productTitle={'foodpanda $50 Cash Voucher'}
                plainText={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/foodpanda.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"31/08/2022"}
                description={[TitleDetailConstant.foodpanda_instructions_tc, TitleDetailConstant.foodpanda_instructions_en, TitleDetailConstant.foodpanda_tandc_31_08_2022_tc, TitleDetailConstant.foodpanda_tandc_31_08_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'ccb' && voucherName === 'foodpanda100-31082022' && qrcodeValue) {
            return <Template3
                productTitle={'foodpanda $100 Cash Voucher'}
                plainText={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/foodpanda.png'}
                isShowAtpLogo={false}
                moneyValue={"100.00"}
                expiryDate={"31/08/2022"}
                description={[TitleDetailConstant.foodpanda_instructions_tc, TitleDetailConstant.foodpanda_instructions_en, TitleDetailConstant.foodpanda_tandc_31_08_2022_tc, TitleDetailConstant.foodpanda_tandc_31_08_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'ccb' && voucherName === 'hft20-28022023' && qrcodeValue) {
            return <Template3
                productTitle={'鴻福堂 $20 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/HFT.png'}
                isShowAtpLogo={false}
                moneyValue={"20.00"}
                expiryDate={"28/02/2023"}
                description={[TitleDetailConstant.hft_tandc_28_02_2023_tc, TitleDetailConstant.hft_tandc_28_02_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'ccb' && voucherName === 'fresh50-31082022' && qrcodeValue) {
            return <Template3
                productTitle={'FRESH新鮮生活 $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/fresh.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"31/08/2022"}
                description={[TitleDetailConstant.fresh_tandc_50_tc, TitleDetailConstant.fresh_tandc_50_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'atpoints' && voucherName === '75950-30062023' && qrcodeValue) {
            return <Template3
                productTitle={'759阿信屋 $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/store759.png'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"30/06/2023"}
                description={[TitleDetailConstant.store759_tac_tc, TitleDetailConstant.store759_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        }else if (distributerName === 'atrewards' && voucherName === '759100-30062023' && qrcodeValue) {
            return <Template3
                productTitle={'759阿信屋 $100 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/store759.png'}
                isShowAtpLogo={true}
                moneyValue={"100.00"}
                expiryDate={"30/06/2023"}
                description={[TitleDetailConstant.store759_tac_tc, TitleDetailConstant.store759_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        }else if (distributerName === 'atpoints' && voucherName === 'paullafayette50-28032022' && qrcodeValue) {
            return <Template3
                productTitle={'Paul Lafayet $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/paul_lafayet.png'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"28/03/2022"}
                description={[TitleDetailConstant.paul_tandc_50_tc, TitleDetailConstant.paul_tandc_50_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'atpoints' && voucherName === 'healthworks10-31102022' && qrcodeValue) {
            return <Template3
                productTitle={'HealthWorks $10 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/Healthworks_logo.png'}
                isShowAtpLogo={true}
                moneyValue={"10.00"}
                expiryDate={"31/10/2022"}
                description={[TitleDetailConstant.HealthWorks_10_tac_tc, TitleDetailConstant.HealthWorks_10_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'atrewards' && voucherName === 'greencommon350-30082022' && qrcodeValue) {
            return <Template3
                productTitle={'Green Common $350現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/Green_Common_logo.jpg'}
                isShowAtpLogo={true}
                moneyValue={"350.00"}
                expiryDate={"30/08/2022"}
                description={[
                    TitleDetailConstant.green_common_tac_tc,
                    TitleDetailConstant.green_common_tac_en,
                ]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'atrewards' && voucherName === 'deliveroo50-05042023' && qrcodeValue) {
            return <Template3
                productTitle={'Deliveroo $50 Cash Voucher'}
                plainText={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/deliverooCoupon50.png'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"05/04/2023"}
                description={[TitleDetailConstant.deliveroo_instructions_tc, TitleDetailConstant.deliveroo_instructions_en, TitleDetailConstant.deliveroo_tandc_05_04_2023_tc, TitleDetailConstant.deliveroo_tandc_05_04_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'atrewards' && voucherName === 'hft20-30042023' && qrcodeValue) {
            return <Template3
                productTitle={'鴻福堂 $20 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/HFT.png'}
                isShowAtpLogo={true}
                moneyValue={"20.00"}
                expiryDate={"30/04/2023"}
                description={[TitleDetailConstant.hft_tandc_28_02_2023_tc, TitleDetailConstant.hft_tandc_28_02_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'atrewards' && voucherName === 'hft50-30042023' && qrcodeValue) {
            return <Template3
                productTitle={'鴻福堂 $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/HFT.png'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"30/04/2023"}
                description={[TitleDetailConstant.hft_tandc_28_02_2023_tc, TitleDetailConstant.hft_tandc_28_02_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'atrewards' && voucherName === 'sthRiceDumpling-01062022' && qrcodeValue) {
            return <Template3
                productTitle={'聖安娜端陽糭券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/St_Honore_Logo.jpg'}
                isShowAtpLogo={false}
                moneyValue={"155.00"}
                expiryDate={"01/06/2022"}
                description={[TitleDetailConstant.sthRiceDumping_tandc_01_06_2022_tc, TitleDetailConstant.sthRiceDumping_tandc_01_06_2022_en]}
                isShowSTHDescription={true}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'atrewards' && voucherName === 'cova50-17122022' && qrcodeValue) {
            return <Template3
                productTitle={'COVA HK$50 電子現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/cova.jpeg'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"17/12/2022"}
                description={[TitleDetailConstant.cova_tandc_17_12_2022_tc, TitleDetailConstant.cova_tandc_17_12_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'wechat' && voucherName === 'cova50-05082023' && qrcodeValue) {
            return <Template3
                productTitle={'COVA HK$50 電子現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/cova.jpeg'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"05/08/2023"}
                description={[TitleDetailConstant.cova_tandc_05_08_2023_tc, TitleDetailConstant.cova_tandc_05_08_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'atrewards' && voucherName === 'sthRiceDumpling155-01062022' && qrcodeValue) {
            return <Template3
                productTitle={'聖安娜端陽糭券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/St_Honore_Logo.jpg'}
                isShowAtpLogo={false}
                moneyValue={"155.00"}
                expiryDate={"01/06/2022"}
                // description={[TitleDetailConstant.sthRiceDumping_tandc_01_06_2022_tc, TitleDetailConstant.sthRiceDumping_tandc_01_06_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={true}
            />
        } else if(distributerName === 'atrewards' && voucherName === 'jhc50-31052022' && qrcodeValue) {
            // Jetco: 日本城
            return <Template3
                productTitle={'日本城 $50現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/JCH.png'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"31/05/2022"}
                description={[TitleDetailConstant.jhc_tandc_number_tc, TitleDetailConstant.jhc_tandc_number_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'atrewards' && voucherName === 'rgRiceDumpling-30052022' && qrcodeValue) {
            // Jetco: 日本城
            return <Template3
                productTitle={'尖沙咀帝苑酒店金腿鹹肉粽'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/01mall_logo.png'}
                isShowAtpLogo={false}
                moneyValue={""}
                expiryDate={""}
                redemptionPeriod={"由2022年5月20日至5月30日"}
                description={[]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
                isRgRiceDumpling={true}
            />
        } else if(distributerName === 'atrewards' && voucherName === 'icgsfRiceDumpling-03062022' && qrcodeValue) {
            // Jetco: 日本城
            return <Template3
                productTitle={'海景軒嘉興豚肉粽一隻'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/01mall_logo.png'}
                isShowAtpLogo={false}
                moneyValue={""}
                expiryDate={""}
                redemptionPeriod={"2022年5月25日至6月3日"}
                description={[]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
                isIcgsfRiceDumpling={true}
            />
        } else if(distributerName === 'atrewards' && voucherName === 'sth25-31052022' && qrcodeValue) {
            // Jetco: 日本城
            return <Template3
                productTitle={'聖安鄉 $25現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/St_Honore_Logo.jpg'}
                isShowAtpLogo={true}
                moneyValue={"25.00"}
                expiryDate={"30/06/2022"}
                description={[TitleDetailConstant.sth_tandc_number_tc, TitleDetailConstant.sth_tandc_number_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'chongHing' && voucherName === '75950-30062023' && qrcodeValue) {
            return <Template3
                productTitle={'759阿信屋 $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/store759.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"30/06/2023"}
                description={[TitleDetailConstant.store759_tac_tc, TitleDetailConstant.store759_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'chongHing' && voucherName === 'tenren2530042023' && qrcodeValue) {
            return <Template3
                productTitle={'天仁茗茶 $25 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/tenren.png'}
                isShowAtpLogo={true}
                moneyValue={"25.00"}
                expiryDate={"30/04/2023"}
                description={[TitleDetailConstant.tenren_tandc_28_02_2022_tc, TitleDetailConstant.tenren_tandc_28_02_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'liech' && voucherName === '75950-30062023' && qrcodeValue) {
            return <Template4
                productTitle={'759阿信屋 $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/store759.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"30/06/2023"}
                description={[TitleDetailConstant.store759_tac_tc, TitleDetailConstant.store759_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        }else if (distributerName === 'liech' && voucherName === '75950-31122023' && qrcodeValue) {
            return <Template3
                productTitle={'759阿信屋 $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/store759.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"31/12/2023"}
                description={[TitleDetailConstant.store759_tac_tc, TitleDetailConstant.store759_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'liech' && voucherName === 'sasa100-13012023' && qrcodeValue) {
            return <Template3
                productTitle={'莎莎 $100 電子現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/sasaLogo.png'}
                isShowAtpLogo={false}
                moneyValue={"100.00"}
                expiryDate={"13/01/2023"}
                description={[TitleDetailConstant.sasa_tac_tc, TitleDetailConstant.sasa_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'liech' && voucherName === 'shell100-310323' && qrcodeValue) {
            // Jetco: Shell
            return <Template3
                productTitle={'Shell $100現金券'}
                qrcode={this.props.match.params.param3}
                barcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/Shell+Logo.png'}
                isShowAtpLogo={false}
                moneyValue={"100.00"}
                expiryDate={"31/03/2023"}
                description={[
                    TitleDetailConstant.shell_title_tc,
                    TitleDetailConstant.shell_tandc_tc,
                    TitleDetailConstant.shell_precautions_31_03_2023_tc,
                    TitleDetailConstant.shell_title_en,
                    TitleDetailConstant.shell_tandc_en,
                    TitleDetailConstant.shell_precautions_31_03_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        }else if (distributerName === 'liech' && voucherName === 'deliveroo100-02082023' && qrcodeValue) {
            return <Template3
                productTitle={'Deliveroo $100 Cash Voucher'}
                plainText={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/deliveroo.png'}
                isShowAtpLogo={false}
                moneyValue={"100.00"}
                expiryDate={"02/08/2023"}
                description={[TitleDetailConstant.deliveroo_instructions_tc, TitleDetailConstant.deliveroo_instructions_en, TitleDetailConstant.deliveroo_tandc_02_08_2023_tc, TitleDetailConstant.deliveroo_tandc_02_08_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'liech' && voucherName === 'sthonore2531122023' && qrcodeValue) {
            return <Template3
                productTitle={'St Honore $25 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/st_honore.png'}
                isShowAtpLogo={false}
                moneyValue={"25.00"}
                expiryDate={"31/12/2023"}
                description={[TitleDetailConstant.st_honore_tandc_31_12_2023_tc, TitleDetailConstant.st_honore_tandc_31_12_2023_en]}
                showAtpContact={false}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'liech' && voucherName === 'hft2030042023' && qrcodeValue) {
            return <Template3
                productTitle={'鴻福堂 $20 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/HFT.png'}
                isShowAtpLogo={false}
                moneyValue={"20.00"}
                expiryDate={"30/04/2023"}
                description={[TitleDetailConstant.hft_tandc_28_02_2023_tc, TitleDetailConstant.hft_tandc_28_02_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'liech' && voucherName === 'cova5005082023' && qrcodeValue) {
            return <Template3
                productTitle={'COVA HK$50 電子現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/cova.jpeg'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"05/08/2023"}
                description={[TitleDetailConstant.cova_tandc_17_12_2022_tc, TitleDetailConstant.cova_tandc_17_12_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'liech' && voucherName === '7595030062023' && qrcodeValue) {
            return <Template3
                productTitle={'759阿信屋 $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/store759.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"30/06/2023"}
                description={[TitleDetailConstant.store759_tac_tc, TitleDetailConstant.store759_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'liech' && voucherName === '7595030072024' && qrcodeValue) {
            return <Template4
                productTitle={'759 阿信屋 $50 現金券'}
                qrcode={atob(String(this.props.match.params.param3))}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/store759.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"30/06/2024"}
                description={[TitleDetailConstant.store759_tac_tc, TitleDetailConstant.store759_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        }else if (distributerName === 'atrewards' && voucherName === 'godiva5030062022' && qrcodeValue) {
            return <Template3
                productTitle={'Godiva $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/godiva.jpg'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"30/06/2022"}
                description={[TitleDetailConstant.godiva_tac_tc, TitleDetailConstant.godiva_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        }else if(distributerName === 'atrewards' && voucherName === 'shell10031032023' && qrcodeValue) {
            return <Template3
                productTitle={'Shell $100現金券'}
                qrcode={this.props.match.params.param3}
                barcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/Shell+Logo.png'}
                isShowAtpLogo={false}
                moneyValue={"100.00"}
                expiryDate={"31/03/2023"}
                description={[
                    TitleDetailConstant.shell_title_tc,
                    TitleDetailConstant.shell_tandc_tc,
                    TitleDetailConstant.shell_precautions_31_03_2023_tc,
                    TitleDetailConstant.shell_title_en,
                    TitleDetailConstant.shell_tandc_en,
                    TitleDetailConstant.shell_precautions_31_03_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'wechat' && voucherName === 'pacificcoffee2520230418' && qrcodeValue){
			return <Template3
				productTitle={'WeChat: Pacific Coffee $25電子現金券'}
				qrcode={this.props.match.params.param3}
				merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/pacific_coffee.png'}
				isShowAtpLogo={true}
				moneyValue={"25.00"}
				expiryDate={"18/04/2023"}
				description={[TitleDetailConstant.pacific_coffee_tandc_18_04_2023_tc, TitleDetailConstant.pacific_coffee_tandc_18_04_2023_en]}
				isShowSTHDescription={false}
				isRiceDumplingDescription={false}
			/>
		} else if(distributerName === 'wechat' && voucherName === 'pacificcoffee2521022023' && qrcodeValue){
            return <Template3
                productTitle={'WeChat: Pacific Coffee $25電子現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/pacific_coffee.png'}
                isShowAtpLogo={true}
                moneyValue={"25.00"}
                expiryDate={"22/02/2023"}
                description={[TitleDetailConstant.pacific_coffee_tandc_22_02_2023_tc, TitleDetailConstant.pacific_coffee_tandc_22_02_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'wechat' && voucherName === 'starbucks3020220831' && qrcodeValue){
			return <Template3
				productTitle={'WeChat: Starbucks $30 電子現金禮券'}
				qrcode={this.props.match.params.param3}
				merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/starbucks_logo.png'}
				isShowAtpLogo={true}
				moneyValue={"30.00"}
				expiryDate={"31/08/2022"}
				description={[TitleDetailConstant.wechat_starbucks_tac_tc, TitleDetailConstant.wechat_starbucks_tac_en]}
				isShowSTHDescription={false}
				isRiceDumplingDescription={false}
			/>
		} else if(distributerName === 'atpoints' && voucherName === 'fairwood20-20230430' && qrcodeValue){
			return <Template3
				productTitle={'大快活 $20電子現金券'}
				qrcode={this.props.match.params.param3}
				merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/fairwood.png'}
				isShowAtpLogo={true}
				moneyValue={"20.00"}
				expiryDate={"30/04/2023"}
				description={[TitleDetailConstant.fairwood20220726_tac_tc, TitleDetailConstant.fairwood20220726_tac_en]}
				isShowSTHDescription={false}
				isRiceDumplingDescription={false}
			/>
		} else if(distributerName === 'wechat' && voucherName === 'goldenharvest5031052023' && qrcodeValue){
			return <Template3
				productTitle={'嘉禾院線 $50 現金券'}
				qrcode={this.props.match.params.param3}
				merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/GoldenHarvest.png'}
				isShowAtpLogo={false}
				moneyValue={"50.00"}
				expiryDate={"31/05/2023"}
				description={[TitleDetailConstant.goldenharvest_tac_tc, TitleDetailConstant.goldenharvest_tac_en]}
				isShowSTHDescription={false}
				isRiceDumplingDescription={false}
			/>
		}else if (distributerName === 'wechat' && voucherName === '7595022012023' && qrcodeValue) {
            return <Template3
                productTitle={'759阿信屋 $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/store759.png'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"22/01/2023"}
                description={[TitleDetailConstant.store759_tac_tc, TitleDetailConstant.store759_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'wechat' && voucherName === 'emperorcinemas13015112023' && qrcodeValue){
			return <Template3
				productTitle={'Emperor Cinemas $130 電子現金禮券'}
				qrcode={this.props.match.params.param3}
				merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/EmperorCinemasLogo.png'}
				isShowAtpLogo={true}
				moneyValue={"130.00"}
				expiryDate={"15/11/2023"}
				description={[TitleDetailConstant.emperor_cinemas_tac_tc, TitleDetailConstant.emperor_cinemas_tac_en]}
				isShowSTHDescription={false}
				isRiceDumplingDescription={false}
			/>
        } else if(distributerName === 'demo' && voucherName === 'goldenharvest100301012023' && qrcodeValue){
			return <Template3
				productTitle={'嘉禾院線 $100 現金券'}
				qrcode={this.props.match.params.param3}
				merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/GoldenHarvest.png'}
				isShowAtpLogo={true}
				moneyValue={"100.00"}
				expiryDate={"01/01/2023"}
				description={[TitleDetailConstant.demo_tac_tc, TitleDetailConstant.demo_tac_en]}
				isShowSTHDescription={false}
				isRiceDumplingDescription={false}
			/>
		} else if(distributerName === 'liech' && voucherName === 'fairwood2031102023' && qrcodeValue){
			return <Template3
                productTitle={'大快活 $20 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/fairwood.png'}
                isShowAtpLogo={false}
                moneyValue={"20.00"}
                expiryDate={"31/10/2023"}
                description={[TitleDetailConstant.fairwood_tandc_tc, TitleDetailConstant.fairwood_tandc_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
			/>
		} else if (distributerName === 'liech' && voucherName === 'cova5006122023' && qrcodeValue) {
            return <Template3
                productTitle={'COVA HK$50 電子現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/cova.jpeg'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"06/12/2023"}
                description={[TitleDetailConstant.cova_tandc_17_12_2022_tc, TitleDetailConstant.cova_tandc_17_12_2022_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'liech' && voucherName === 'fairwood2031122023' && qrcodeValue){
			return <Template3
                productTitle={'大快活 $20 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/fairwood.png'}
                isShowAtpLogo={false}
                moneyValue={"20.00"}
                expiryDate={"31/12/2023"}
                description={[TitleDetailConstant.fairwood_tandc_tc, TitleDetailConstant.fairwood_tandc_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
			/>
		} else if(distributerName === 'liech' && voucherName === 'fairwood2031032024' && qrcodeValue){
			return <Template3
                productTitle={'大快活 $20 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/fairwood.png'}
                isShowAtpLogo={false}
                moneyValue={"20.00"}
                expiryDate={"31/03/2024"}
                description={[TitleDetailConstant.fairwood_tandc_tc, TitleDetailConstant.fairwood_tandc_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
			/>
		}else if(distributerName === 'liech' && voucherName === 'sthonore2531122023' && qrcodeValue){
            return <Template3
                productTitle={'St Honore $25 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/st_honore.png'}
                isShowAtpLogo={false}
                moneyValue={"25.00"}
                expiryDate={"31/12/2023"}
                description={[TitleDetailConstant.st_honore_tandc_31_12_2023_tc, TitleDetailConstant.st_honore_tandc_31_12_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        }else if(distributerName === 'liech' && voucherName === 'sthonore2531122024' && qrcodeValue){
            return <Template3
                productTitle={'St Honore $25 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/st_honore.png'}
                isShowAtpLogo={false}
                moneyValue={"25.00"}
                expiryDate={"31/12/2024"}
                description={[TitleDetailConstant.st_honore_tandc_31_12_2024_tc, TitleDetailConstant.st_honore_tandc_31_12_2024_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'liech' && voucherName === 'hft2031122023' && qrcodeValue) {
            return <Template3
                productTitle={'鴻福堂$20 電子禮券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/HFT.png'}
                isShowAtpLogo={false}
                moneyValue={"20.00"}
                expiryDate={"31/12/2023"}
                description={[TitleDetailConstant.hft_tandc_28_02_2022_tc, TitleDetailConstant.hft_tandc_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'liech' && voucherName === 'sasa10014102023' && qrcodeValue) {
            return <Template3
                productTitle={'莎莎 $100 電子現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/sasaLogo.png'}
                isShowAtpLogo={false}
                moneyValue={"100.00"}
                expiryDate={"14/10/2023"}
                description={[TitleDetailConstant.sasa_tac_tc, TitleDetailConstant.sasa_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        }else if(distributerName === 'liech' && voucherName === 'haagendazs5031072024' && qrcodeValue) {
            return <Template3
                productTitle={'Haagen Dazs $50 Cash Voucher'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/haagen-dazs.jpeg'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"31/07/2024"}
                description={[TitleDetailConstant.haagen_dazs_tc, TitleDetailConstant.haagen_dazs_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        }else if(distributerName === 'liech' && voucherName === 'pacificcoffee2510082023' && qrcodeValue) {
            return <Template3
                productTitle={'Pacific Coffee $25 電子現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/pacific_coffee.png'}
                isShowAtpLogo={false}
                moneyValue={"25.00"}
                expiryDate={"10/08/2023"}
                description={[TitleDetailConstant.pacific_coffee_tandc_10_08_2023_tc, TitleDetailConstant.pacific_coffee_tandc_10_08_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        }else if(distributerName === 'liech' && voucherName === 'hktv5020122023' && qrcodeValue) {
            return <Template3
                productTitle={'HKTV Mall $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/hktvmall.jpg'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"20/12/2023"}
                description={[TitleDetailConstant.hktv_tandc_tc, TitleDetailConstant.hktv_tandc_en]}
                showHktvmallManual={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        }else if(distributerName === 'liech' && voucherName === 'hktv10020122023' && qrcodeValue) {
            return <Template3
                productTitle={'HKTV Mall $100 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/hktvmall.jpg'}
                isShowAtpLogo={false}
                moneyValue={"100.00"}
                expiryDate={"20/12/2023"}
                description={[TitleDetailConstant.hktv_tandc_tc, TitleDetailConstant.hktv_tandc_en]}
                showHktvmallManual={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        }
        else if(distributerName === 'liech' && voucherName === 'goldenharvest5031052023' && qrcodeValue){
			return <Template3
				productTitle={'嘉禾院線 $50 現金券'}
				qrcode={this.props.match.params.param3}
				merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/GoldenHarvest.png'}
				isShowAtpLogo={false}
				moneyValue={"50.00"}
				expiryDate={"31/05/2023"}
				description={[TitleDetailConstant.goldenharvest_tac_tc, TitleDetailConstant.goldenharvest_tac_en]}
				isShowSTHDescription={false}
				isRiceDumplingDescription={false}
			/>
		}else if(distributerName === 'coindragon' && voucherName === 'cafedecoral2031012024' && qrcodeValue) {
            return <Template3
                productTitle={'大家樂 $20 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/cafedecoral.jpeg'}
                isShowAtpLogo={true}
                moneyValue={"20.00"}
                expiryDate={"31/01/2024"}
                description={[TitleDetailConstant.cafedecoral20_tandc_tc, TitleDetailConstant.cafedecoral20_tandc_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        }   else if (distributerName === 'bea' && voucherName === 'godiva5030112023' && qrcodeValue) {
            return <Template3
                productTitle={'Godiva $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/godiva.jpg'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"30/11/2023"}
                description={[TitleDetailConstant.godiva_tac_tc_30112023, TitleDetailConstant.godiva_tac_en_30112023]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'bea' && voucherName === 'hft2030042023' && qrcodeValue) {
            return <Template3
                productTitle={'鴻福堂$20 電子禮券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/HFT.png'}
                isShowAtpLogo={false}
                moneyValue={"20.00"}
                expiryDate={"30/04/2023"}
                description={[TitleDetailConstant.hft_tandc_28_02_2022_tc, TitleDetailConstant.hft_tandc_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'bea' && voucherName === 'starbucks2530112023' && qrcodeValue){
			return <Template3
				productTitle={'Starbucks $25 電子現金禮券'}
				qrcode={this.props.match.params.param3}
				merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/starbucks_logo.png'}
				isShowAtpLogo={true}
				moneyValue={"25.00"}
				expiryDate={"30/11/2023"}
				description={[TitleDetailConstant.starbucks_tac_tc, TitleDetailConstant.starbucks_tac_en]}
				isShowSTHDescription={false}
				isRiceDumplingDescription={false}
			/>
		}else if(distributerName === 'cmhk' && voucherName === 'emperorcinemas13015112023' && qrcodeValue){
			return <Template3
				productTitle={'英皇戲院電影電子禮券'}
				qrcode={this.props.match.params.param3}
				merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/EmperorCinemasLogo.png'}
				isShowAtpLogo={true}
				expiryDate={"15/11/2023"}
                moneyValue={""}
				description={[TitleDetailConstant.emperor_cinemas_tac_tc, TitleDetailConstant.emperor_cinemas_tac_en]}
				isShowSTHDescription={false}
				isRiceDumplingDescription={false}
			/>
        }else if(distributerName === 'wechat' && voucherName === 'emperorcinemas13015112023' && qrcodeValue){
			return <Template3
				productTitle={'英皇戲院電影電子禮券'}
				qrcode={this.props.match.params.param3}
				merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/EmperorCinemasLogo.png'}
				isShowAtpLogo={true}
				expiryDate={"15/11/2023"}
                moneyValue={""}
				description={[TitleDetailConstant.emperor_cinemas_tac_tc, TitleDetailConstant.emperor_cinemas_tac_en]}
				isShowSTHDescription={false}
				isRiceDumplingDescription={false}
			/>
        }else if(distributerName === 'cmi' && voucherName === 'emperorcinemas13015112023' && qrcodeValue){
			return <Template3
				productTitle={'英皇戲院電影電子禮券'}
				qrcode={this.props.match.params.param3}
				merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/EmperorCinemasLogo.png'}
				isShowAtpLogo={true}
				expiryDate={"15/11/2023"}
                moneyValue={""}
				description={[TitleDetailConstant.emperor_cinemas_tac_tc, TitleDetailConstant.emperor_cinemas_tac_en]}
				isShowSTHDescription={false}
				isRiceDumplingDescription={false}
			/>
        }else if(distributerName === 'cmi' && voucherName === 'eugenebaby5030062023' && qrcodeValue) {
            return <Template3
                productTitle={'Eugenebaby $50 現金券'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/eugenebaby.png'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"30/06/2023"}
                description={[TitleDetailConstant.eugenebaby_tac_30062023_tc, TitleDetailConstant.eugenebaby_tac_30062023_en]}
                showAtpContact={true}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        }else if(distributerName === 'asiatop' && voucherName === 'emperorcinemas13015112023' && qrcodeValue){
			return <Template3
				productTitle={'英皇戲院電影電子禮券'}
				qrcode={this.props.match.params.param3}
				merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/EmperorCinemasLogo.png'}
				isShowAtpLogo={true}
				expiryDate={"15/11/2023"}
                moneyValue={""}
				description={[TitleDetailConstant.emperor_cinemas_tac_tc, TitleDetailConstant.emperor_cinemas_tac_en]}
				isShowSTHDescription={false}
				isRiceDumplingDescription={false}
			/>
        } else if (distributerName === 'dbs' && voucherName === 'godiva24pcs31012023' && qrcodeValue) {
            return <Template3
                productTitle={'Godiva片裝巧克力禮盒24片'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/godiva.jpg'}
                isShowAtpLogo={false}
                moneyValue={"339"}
                redemptionPeriod={"即日起至28/02/2023 (21-27/12/2022 ;15-21/01/2023; 13-14/02/2023 除外)"}
                redemptionPeriodEn={"Redemption Period Now till 28 February 2023 (Blackout period: 21-27 December 2022 & 15-21 January & 13-14 February 2023)"}
                description={[TitleDetailConstant.godiva_24pcs_tac_tc, TitleDetailConstant.godiva_24pcs_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
                showGodiva24Pcs={true}
                locations={[TitleDetailConstant.godiva_24pcs_tac_locations_en]}
            />
        } else if (distributerName === 'dbs' && voucherName === 'godiva24pcs31032023' && qrcodeValue) {
            return <Template3
                productTitle={'Godiva片裝巧克力禮盒24片'}
                qrcode={this.props.match.params.param3}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/godiva.jpg'}
                isShowAtpLogo={false}
                moneyValue={"339"}
                redemptionPeriod={"即日起至31/03/2023 (13-14/02/2023 除外)"}
                redemptionPeriodEn={"Redemption Period Now till 31 March 2023 (Blackout period: 13-14 February 2023)"}
                description={[TitleDetailConstant.godiva_24pcs_tac_tc, TitleDetailConstant.godiva_24pcs_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
                showGodiva24Pcs={true}
                locations={[TitleDetailConstant.godiva_24pcs_tac_locations_en]}
            />
        }else if(distributerName === 'dbs' && voucherName === 'haagendazs5031072024' && qrcodeValue) {
            return <Template3
                productTitle={'Haagen Dazs $50 Cash Voucher'}
                qrcode={atob(String(this.props.match.params.param3))}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3-ap-southeast-1.amazonaws.com/static/web_coupon_img/haagen-dazs.jpeg'}
                isShowAtpLogo={true}
                moneyValue={"50.00"}
                expiryDate={"31/07/2024"}
                description={[TitleDetailConstant.haagen_dazs_tc, TitleDetailConstant.haagen_dazs_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        }else if(distributerName === 'dbs' && voucherName === 'starbucks2531122023' && qrcodeValue){
			return <Template3
				productTitle={'Starbucks HK$25 電子現金禮券'}
				qrcode={atob(String(this.props.match.params.param3))}
				merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/starbucks_logo.png'}
				isShowAtpLogo={true}
				moneyValue={"25.00"}
				expiryDate={"31/12/2023"}
				description={[TitleDetailConstant.starbucks_tac_tc, TitleDetailConstant.starbucks_tac_en]}
				isShowSTHDescription={false}
				isRiceDumplingDescription={false}
			/>
		} else if(distributerName === 'dbs' && voucherName === 'pacificcoffee2510082023' && qrcodeValue) {
            return <Template3
                productTitle={'Pacific Coffee HK$25 電子現金券'}
                qrcode={atob(String(this.props.match.params.param3))}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/pacific_coffee.png'}
                isShowAtpLogo={false}
                moneyValue={"25.00"}
                expiryDate={"10/08/2023"}
                description={[TitleDetailConstant.pacific_coffee_tandc_10_08_2023_tc, TitleDetailConstant.pacific_coffee_tandc_10_08_2023_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if (distributerName === 'byon' && voucherName === 'enoteca10031122023' && qrcodeValue) {
            return <Template3
                productTitle={'Wine Shop ENOTECA HK$100 現金劵'}
                qrcode={atob(String(this.props.match.params.param3))}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/Enoteca.png'}
                isShowAtpLogo={false}
                moneyValue={"100"}
                description={[TitleDetailConstant.enoteca_tac_tc, TitleDetailConstant.enoteca_tac_en]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
                showGodiva24Pcs={false}
            />
        } else if (distributerName === 'byon' && voucherName === 'godiva5030112023' && qrcodeValue) {
            return <Template3
                productTitle={'Godiva $50 現金券'}
                qrcode={atob(String(this.props.match.params.param3))}
                merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/godiva.jpg'}
                isShowAtpLogo={false}
                moneyValue={"50.00"}
                expiryDate={"30/11/2023"}
                description={[TitleDetailConstant.godiva_tac_tc_30112023, TitleDetailConstant.godiva_tac_en_30112023]}
                isShowSTHDescription={false}
                isRiceDumplingDescription={false}
            />
        } else if(distributerName === 'byon' && voucherName === 'emperorcinemas13015112023' && qrcodeValue){
			return <Template3
				productTitle={'Emperor Cinemas $130 電子現金禮券'}
				qrcode={atob(String(this.props.match.params.param3))}
				merchantLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/web_coupon_img/EmperorCinemasLogo.png'}
				isShowAtpLogo={true}
				moneyValue={"130.00"}
				expiryDate={"15/11/2023"}
				description={[TitleDetailConstant.emperor_cinemas_tac_tc, TitleDetailConstant.emperor_cinemas_tac_en]}
				isShowSTHDescription={false}
				isRiceDumplingDescription={false}
			/>
        }
    // error redirection
    return <Redirect to="/error" />;
    }
}