/**
 * Path?d={distributor}&m={merchant}&i={info}&c={code}
 */
 import React, {useEffect, useState} from 'react';
 import '../../css/Template3.css';
 import TitleDetail from "../title_detail/TitleDetailProps";
 import TitleDetailComponent from "../title_detail/TitleDetailComponent";
 import CopyToClipboard from 'react-copy-to-clipboard';
 import 'bootstrap/dist/css/bootstrap.min.css';
 import { Button } from 'reactstrap';
 import {toast} from "../ToastManager";
 
 const QRCode = require('qrcode.react');
 
 const CouponQRCode: React.FC<CouponQRCodeProps> = (props) => {
 
     const screenSize = window.innerWidth
 
     const [state, setState] = useState<boolean>(false);
     const [couponCode, setCouponCode] = useState("");
     const [showResults, setShowResults] = useState(false);
     const [couponType, setCouponType] = useState("(成人/Adult)");

     const changeSelect = (newSelect: string): void => {
        setCouponType(newSelect)
      }
     
    const checkShowQrcode = () => setShowResults(true)


    function T_and_c1(){
        return(
            <div className="text-box" style={{marginLeft:10, marginRight:10}}>
            <p className="text"> 親愛的顧客， </p>
            <p className="text"> 1. 請選擇門票種類 (成人/小童)。<br/>
                                   2. 請輸入從中國移動Mylink兌換的海洋公園入場門票兌換碼換取登記門票。<br/>
                                   3. 點選＇確定＇。 </p>

            <p className="text"> Dear Customer, </p>
            <p className="text"> 1. Please select the type of ticket (adult/child). <br/>
                                   2. Please enter the Ocean Park admission ticket redemption code redeemed from China Mobile Mylink to exchange for the registration ticket.<br/>
                                   3. Click 'OK'. </p>
            </div>
        )
    }


    function T_and_c() {
        if(couponType === '(成人/Adult)'){
            return(
                <div className="text"> 
                    <p>
                    親愛的顧客,<br/>
                    請小心保存及截屏保管你的電子入場門票，如有遺失將不獲補發。<br/>
                    閣下可憑此門票（透過智能手機出示或憑 A4 列印本）及海洋公園預約二維碼於海洋公園入口處進場。此門票不得退款或退換及只可使用一次。<br/><br/>

                        1. 此為海洋公園入場門票。成人門票適用於 12 歲或以上之人士。<br />
                        2. 此門票包括享用園內的機動遊戲、設施及展館；但不適用於園內攤位遊戲、須用輔幣操作的遊戲、收費活動或設施等。此門票只供同一位遊客於有效期內使用。<br />
                        3. 海洋公園會因維修，保養，惡劣天氣或其他理由，在任何時間內關閉園內機動遊戲或景點而不作事先通知。遊客根據前述情況所作出的退款要求，不論全數或部份款項，將不作受理。此乃本園門票售賣條款之一。若有惡劣天氣，包括但不限於八號風球或以上、黑色暴雨等，海洋公園可在通知或不通知公眾的情況下關閉公園。<br />
                        4. 若公園在場人數達到最多能容納的水平，海洋公園會控制入場人數，閣下可能未獲接納入場。<br />
                        5. 入場須遵守公園入口處及/或其他適當位置所示之海洋公園附例、安全規則及公園守則。<br />
                        6. 海洋公園對公園內任何人士之財物的損失或損害概不負責。如有任何爭議，將以海洋公園之最後決定為準。 <br />
                        7. 請留意：請小心保管此門票，切勿損壞或弄濕，特別是入場時必須掃描的二維碼。此門票一經修改或損毀即作廢。海洋公園對遺失門票恕不負責。閣下在入場當日可能須要出示適當身份証明文件。<br />
                    </p> 
                    <p>
                    Ｄear customer,<br />
                    Please save and take screenshots of your e-ticket carefully. If it is lost, it will not be reissued.<br />
                    You can enter the venue at the entrance of Ocean Park with this ticket (shown via smartphone or A4 printout) and Ocean Park Reservation QR code. This ticket is non-refundable or exchangeable and can only be used once.<br /><br />

                        1. Daytime Ticket. Adult Ticket is for person aged 12 or above.<br />
                        2. This ticket entitles you to all operating rides, facilities and exhibits, except skill games, coin-operated games, special paid events and facilities being held in the Park. This ticket must be used by the same person during its period of validity. <br />
                        3. For repair, maintenance, inclement weather or other reason, Ocean Park may suspend the operation of any ride or close any attraction at any time without prior notice. It is a condition of sale of the Park’s admission ticket that any request for refund, whether partial or in full, for the tickets purchased by visitors will not be considered by the Park as a result of such ride suspension or closure of attraction. In case of inclement weather, including but without limitation to the hoisting of tropical cyclone signal no. 8 or above or black rainstorm warnings, Ocean Park may close the Park with or without prior notice to the public.<br />
                        4. In case the Park reaches its maximum capacity, Ocean Park may exercise admission control and you may not be allowed for entry. <br />
                        5. A condition of entry is to comply with Ocean Park Bylaw, safety rules and Park Rules and Regulations which are displayed at the entrances and / or other appropriate locations of the Park. <br />
                        6. Ocean Park shall not be responsible for any loss of any kind or any damage to any property of any person within the Park. In the event of dispute, the decision of Ocean Park will be final. <br />
                        7. Please note: Please protect this ticket, do not damage or wet it, especially the QR code which must be scanned at the entrance. This ticket will be void if altered or defaced. Ocean Park accepts no responsibility for any lost ticket. Suitable identification may need to be provided on the day of visit.<br />
                    </p>
               </div>
            )
        }else if(couponType === '(小童/Child)'){
            return(
                <div className="text"> 
                    <p>
                    親愛的顧客,<br/>
                    請小心保存及截屏保管你的電子入場門票，如有遺失將不獲補發。<br/>
                    閣下可憑此門票（透過智能手機出示或憑 A4 列印本）及海洋公園預約二維碼於海洋公園入口處進場。此門票不得退款或退換及只可使用一次。<br/><br />

                        1. 此為海洋公園入場門票。小童門票適用於 3-11歲之人士。<br />
                        2. 此門票包括享用園內的機動遊戲、設施及展館；但不適用於園內攤位遊戲、須用輔幣操作的遊戲、收費活動或設施等。此門票只供同一位遊客於有效期內使用。<br />
                        3. 海洋公園會因維修，保養，惡劣天氣或其他理由，在任何時間內關閉園內機動遊戲或景點而不作事先通知。遊客根據前述情況所作出的退款要求，不論全數或部份款項，將不作受理。此乃本園門票售賣條款之一。若有惡劣天氣，包括但不限於八號風球或以上、黑色暴雨等，海洋公園可在通知或不通知公眾的情況下關閉公園。<br />
                        4. 若公園在場人數達到最多能容納的水平，海洋公園會控制入場人數，閣下可能未獲接納入場。<br />
                        5. 入場須遵守公園入口處及/或其他適當位置所示之海洋公園附例、安全規則及公園守則。<br />
                        6. 海洋公園對公園內任何人士之財物的損失或損害概不負責。如有任何爭議，將以海洋公園之最後決定為準。 <br />
                        7. 請留意：請小心保管此門票，切勿損壞或弄濕，特別是入場時必須掃描的二維碼。此門票一經修改或損毀即作廢。海洋公園對遺失門票恕不負責。閣下在入場當日可能須要出示適當身份証明文件。<br />
                    </p> 
                    <p>
                    Ｄear customer,<br />
                    Please save and take screenshots of your e-ticket carefully. If it is lost, it will not be reissued.<br />
                    You can enter the venue at the entrance of Ocean Park with this ticket (shown via smartphone or A4 printout) and Ocean Park Reservation QR code. This ticket is non-refundable or exchangeable and can only be used once.<br /><br />

                    1. Daytime Ticket. Child Ticket is for person aged 3-11.<br />
                    2. This ticket entitles you to all operating rides, facilities and exhibits, except skill games, coin-operated games, special paid events and facilities being held in the Park. This ticket must be used by the same person during its period of validity. <br />
                    3. For repair, maintenance, inclement weather or other reason, Ocean Park may suspend the operation of any ride or close any attraction at any time without prior notice. It is a condition of sale of the Park’s admission ticket that any request for refund, whether partial or in full, for the tickets purchased by visitors will not be considered by the Park as a result of such ride suspension or closure of attraction. In case of inclement weather, including but without limitation to the hoisting of tropical cyclone signal no. 8 or above or black rainstorm warnings, Ocean Park may close the Park with or without prior notice to the public.<br />
                    4. In case the Park reaches its maximum capacity, Ocean Park may exercise admission control and you may not be allowed for entry. <br />
                    5. A condition of entry is to comply with Ocean Park Bylaw, safety rules and Park Rules and Regulations which are displayed at the entrances and / or other appropriate locations of the Park. <br />
                    6. Ocean Park shall not be responsible for any loss of any kind or any damage to any property of any person within the Park. In the event of dispute, the decision of Ocean Park will be final. <br />
                    7. Please note: Please protect this ticket, do not damage or wet it, especially the QR code which must be scanned at the entrance. This ticket will be void if altered or defaced. Ocean Park accepts no responsibility for any lost ticket. Suitable identification may need to be provided on the day of visit.<br />
                    </p>
               </div>
            )
        }else{
            return(
                <div></div>
            )
            
        }
    }

    //   const changeCouponType = (e) => {
    //     setCouponType(e.target.value);
    //   };


     let logoSize = 'normal';
 
     useEffect(() => {
         document.title = props.productTitle
     })
 
     return (
         <>
             {
                 props.isShowAtpLogo &&
                     <>
                     
                         <img src={require('../../images/coupon_logo.png').default} alt="en_version_image" className="logoImg"
                              width={screenSize < 450 ? "125px" : "230px"} style={{margin: "20px 0 0 0"}}/>
                         <img src={require('../../images/background.png').default} alt="en_version_image" className="bgImg"
                              width={screenSize < 450 ? "82px" : "150px"}/>
                     </>
             }
 
             {props.merchantLogoSrc &&
             <img src={props.merchantLogoSrc}
                  alt="en_version_image"
                  height={screenSize < 450 ? "100px" : "180px"}/>}

             {props.distributerLogoSrc && logoSize === 'smaller' &&
             <img src={props.distributerLogoSrc}
                  alt="en_version_image"
                  height={screenSize < 450 ? "80px" : "125px"}/>}
                  
            {props.distributerLogoSrc && logoSize === 'normal' &&
             <img src={props.distributerLogoSrc}
                  alt="en_version_image"
                  height={screenSize < 450 ? "100px" : "180px"}/>}
 
             {
                 props.qrcode && 
                 props.qrcode.map((qr) => 
                    <div className="qrCode-box">
                        <div className="text"> <h1 style={{marginTop:10}}>{props.productTitle} {couponType}</h1> </div>
                        <div style={{marginLeft:10}} className="text-box">
                             <div className="text"> <b>產品名稱: </b> {props.productTitle} </div>        
                                { showResults ?  
                                    <div>
                                        <b>門票類型: </b> {couponType }                         
                                    </div>
                                    :
                                    <div className="text"> <b>門票類型: </b> 
                                        <select onChange={(event) => changeSelect(event.target.value)} value={couponType} >
                                            <option value="(成人/Adult)">成人/Adult</option>
                                            <option value="(小童/Child)">小童/Child</option>
                                        </select> 
                                    </div>
                                }

                 {props.moneyValue && <div className="text"> <b>價值: </b> HKD{props.moneyValue} </div>}
                 <div className="text"> <b>到期日: </b> {props.expiryDate} </div>
             </div>
                        
                        { showResults ?       
                            <div>
                                <QRCode value={couponCode} style={screenSize < 450 ? { width: "250px", height: "250px"} : { width: "250px", height: "250px"}}/>
                                <p style={{fontSize:24}}>{couponCode}</p>
                            </div>                  
                            :
                            <div style={{display:"flex", }}>
                                <input onChange={e => setCouponCode(e.target.value)}  value={couponCode} placeholder="Please enter admission code" style={{marginTop:20, width: 300,}}/>
                                <Button onClick={checkShowQrcode} style={{marginTop:20}} name="確定">確定</Button>
                            </div>
                        }
                    </div>
                 )
             }
 
             {
                 props.plainText&& <div className="qrCode-box">
                     <h3> 券碼 Voucher Code</h3>
                     <h1>{ props.plainText }</h1>
                     <CopyToClipboard
                         text={props.plainText}
                         onCopy={() =>{
                             toast.show({
                                 title: '',
                                 content: "成功複製 Copy successfully",
                                 duration: 1600,
                             })
                             setState(true)
                         }}>
                         <Button variant="info">點擊以複製券碼 Click me to copy</Button>
                     </CopyToClipboard>
                 </div>
             }
 

                         { showResults ?    
                            <T_and_c></T_and_c>
                            : <T_and_c1></T_and_c1>
                        }
             {props.description?.map((it, index)=> <TitleDetailComponent key={index} title={it.title} details={it.details} style_index={it.style_index} />)}
         </>
     )
 };
 
 interface CouponQRCodeProps {
     productTitle: string,
     qrcode?: string[],
     plainText?: string,
     distributerLogoSrc?: string,
     merchantLogoSrc?: string,
     isShowAtpLogo: boolean,
     moneyValue?: string,
     expiryDate?: string
     description?: TitleDetail[]
 };
 
 export default CouponQRCode;