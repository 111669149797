interface TitleDetail {
    style_index?: IndexStyle,
    title?: string,
    details?: string[]
}

export default TitleDetail

export enum IndexStyle {
    none,
    numbering,
    bullets
}