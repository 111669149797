import {Component, useState} from "react";
import MultipleQr from "../../components/third_party_ui/MultipleQr";
import {RouteProps} from "../../App";
import * as TitleDetailConstant from "./constant";
import { Redirect } from 'react-router-dom';
import CouponQRCode from '../../components/third_party_ui/CouponQRCode';


export class CouponQRCodePage extends Component<RouteProps, any> {
    componentDidMount() {
        
    }
    
    render() {
        return (
        <div>
        <CouponQRCode
        productTitle={'海洋公園日間電子入場門票'}
        qrcode={["052235128622", ]}
        distributerLogoSrc={'https://asiatop-coupon-bucket.s3.ap-southeast-1.amazonaws.com/static/img/OceanPark_logo.png'}
        isShowAtpLogo={false}
        expiryDate={"20/11/2022"}
        description={[]}
        />
    </div>
    )
        
        // error redirection
        // return <Redirect to="/error" />;
    }
}