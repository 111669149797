import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from '../components/ToastManager'

const QRcode = require('qrcode.react');

interface ITemplate1Props {
    lang: string,
    desc1: string,
    desc2: string,
    desc3: string,
    desc4: string,
    // code: string,
    // actUrl: string,
    // actName: string,
    codeText: string,
    codeQR: string,
    codeBar: string,
    referralUrl: string,
    cssProps: ITemplate1CssProps
}

interface ITemplate1CssProps {
    textStyle: React.CSSProperties,
    mobileTextStyle: React.CSSProperties,
    rulesTitleStyle: React.CSSProperties,
    mobileRulesTitleStyle: React.CSSProperties,
    rulesTextStyle: React.CSSProperties,
    mobileRulesTextStyle: React.CSSProperties,
    btnStyle: React.CSSProperties,
    mobileBtnStyle: React.CSSProperties,
    codeStyle: React.CSSProperties,
    mobileCodeStyle: React.CSSProperties,
    bgColor: string,
}

// Bowtie CSS Style //
export const bowtieCssProps: ITemplate1CssProps = {
    textStyle: {
        fontSize: '32px',
        fontWeight: 'bolder',
        color: '#191357',
        margin: "15px 0 5px 0",
        padding: "10px 20px 10px 20px",
        whiteSpace: 'normal',        
    },
    mobileTextStyle: {
        fontSize: '20px',
        fontWeight: 'bolder',
        color: '#191357',
        margin: "15px 20px 5px 20px",
        padding: "5px 0 5px 0",
        whiteSpace: 'normal',     
    },

    rulesTitleStyle: {
        fontSize: '18px',
        fontWeight: 'bolder',
        color: '#191357',
        textAlign: "start",
        margin: "55px 0 5px 0",        
    },

    mobileRulesTitleStyle: {
        fontSize: '13px',
        fontWeight: 'bolder',
        color: '#191357',
        textAlign: "start",
        margin: "50px 0 5px 0",        
    },

    rulesTextStyle: {
        fontSize: '15px',
        fontWeight: 'bolder',
        color: '#191357',
        textAlign: "start",        
        margin: "0",        
    },

    mobileRulesTextStyle: {
        fontSize: '10px',
        fontWeight: 'bolder',
        color: '#191357',
        textAlign: "start",
        margin: "0",        
    },

    btnStyle: {
        fontSize: '34px',
        backgroundColor: '#FF0068',
        color: '#FFFFFF',
        width: "340px",
        height: "95px",
        // boxShadow: "4px 3px 3px #000000",
        borderRadius: "20px",
        border: "none",
        cursor: "pointer",
    },

    mobileBtnStyle: {
        fontSize: '28px',
        backgroundColor: '#FF0068',
        color: '#FFFFFF',
        width: "250px",
        height: "75px",
        // boxShadow: "4px 3px 3px #000000",
        borderRadius: "20px",
        border: "none",
        cursor: "pointer",
    },

    codeStyle: {
        fontSize: '45px',
        backgroundColor: '#FF0068',
        color: '#FFFFFF',
        width: "340px",
        height: "95px",
        // boxShadow: "4px 3px 3px #000000",
        borderRadius: "20px",
        border: "none",
        cursor: "pointer",
    },

    mobileCodeStyle: {
        fontSize: '32px',
        backgroundColor: '#FF0068',
        color: '#FFFFFF',
        width: "250px",
        height: "75px",
        // boxShadow: "4px 3px 3px #000000",
        borderRadius: "20px",
        border: "none",
        cursor: "pointer",
    },
    bgColor: "html { background-color: white; height: 100%; }",
}

// Cupping Room CSS Style //
export const cuppingCssProps: ITemplate1CssProps = {
    textStyle: {
        fontSize: '30px',
        color: '#000000',
        margin: "20px 0 10px 0",
        padding: "10px 0 10px 0",
    },
    mobileTextStyle: {
        fontSize: '20px',
        color: '#000000',
        margin: "15px 0 10px 0",
        padding: "5px 0 5px 0"
    },
    rulesTitleStyle: {
        fontSize: '20px',
        fontWeight: 'bolder',
        color: '#191357',
        textAlign: "start",
        margin: "15px 0 5px 0",
    },

    mobileRulesTitleStyle: {
        fontSize: '15px',
        fontWeight: 'bolder',
        color: '#191357',
        textAlign: "start",
        margin: "15px 0 5px 0",
    },
    rulesTextStyle: {
        fontSize: '20px',
        fontWeight: 'bolder',
        color: '#191357',
        textAlign: "start",
        margin: "0",
    },

    mobileRulesTextStyle: {
        fontSize: '15px',
        fontWeight: 'bolder',
        color: '#191357',
        textAlign: "start",
        margin: "0",
    },
    btnStyle: {
        fontSize: '30px',
        backgroundColor: '#919191',
        color: '#FFFFFF',
        width: "350px",
        height: "80px",
        boxShadow: "4px 3px 3px #000000",
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
    },
    mobileBtnStyle: {
        fontSize: '20px',
        backgroundColor: '#919191',
        color: '#FFFFFF',
        width: "260px",
        height: "60px",
        boxShadow: "4px 3px 3px #000000",
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
    },
    codeStyle: {
        fontSize: '30px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        boxShadow: "4px 3px 3px #A0A0A0",
        borderRadius: "10px",
        padding: "15px 25px 15px 25px",
        border: "none",
        cursor: "pointer",
    },
    mobileCodeStyle: {
        fontSize: '15px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        boxShadow: "4px 3px 3px #A0A0A0",
        borderRadius: "10px",
        padding: "15px 25px 15px 25px",
        border: "none",
        cursor: "pointer",
    },
    bgColor: "html { background-image: linear-gradient(#FFFFFF,#919191); height: 100%; }",
}

// Towngas CSS Style //
export const towngasCssProps: ITemplate1CssProps = {
    textStyle: {
        fontSize: '32px',
        fontWeight: 'bolder',
        color: '#18A0CD',
        margin: "15px 0 5px 0",
        padding: "10px 20px 10px 20px",
        whiteSpace: 'normal',        
    },
    mobileTextStyle: {
        fontSize: '20px',
        fontWeight: 'bolder',
        color: '#18A0CD',
        margin: "15px 20px 5px 20px",
        padding: "5px 0 5px 0",
        whiteSpace: 'normal',     
    },

    rulesTitleStyle: {
        fontSize: '18px',
        fontWeight: 'bolder',
        color: '#191357',
        textAlign: "start",
        margin: "55px 0 5px 0",        
    },

    mobileRulesTitleStyle: {
        fontSize: '13px',
        fontWeight: 'bolder',
        color: '#191357',
        textAlign: "start",
        margin: "50px 0 5px 0",        
    },

    rulesTextStyle: {
        fontSize: '15px',
        fontWeight: 'bolder',
        color: '#191357',
        textAlign: "start",        
        margin: "0",        
    },

    mobileRulesTextStyle: {
        fontSize: '10px',
        fontWeight: 'bolder',
        color: '#191357',
        textAlign: "start",
        margin: "0",        
    },

    btnStyle: {
        fontSize: '20px',
        backgroundColor: '#18A0CD',
        color: '#FFFFFF',
        width: "340px",
        height: "95px",
        // boxShadow: "4px 3px 3px #000000",
        borderRadius: "20px",
        border: "none",
        cursor: "pointer",
        margin: "20px 0 0 0",
    },

    mobileBtnStyle: {
        fontSize: '15px',
        backgroundColor: '#18A0CD',
        color: '#FFFFFF',
        width: "250px",
        height: "75px",
        // boxShadow: "4px 3px 3px #000000",
        borderRadius: "20px",
        border: "none",
        margin: "20px 0 0 0",
        cursor: "pointer",
    },

    codeStyle: {
        fontSize: '45px',
        backgroundColor: '#FF0068',
        color: '#FFFFFF',
        width: "340px",
        height: "95px",
        // boxShadow: "4px 3px 3px #000000",
        borderRadius: "20px",
        border: "none",
        cursor: "pointer",
    },

    mobileCodeStyle: {
        fontSize: '32px',
        backgroundColor: '#FF0068',
        color: '#FFFFFF',
        width: "250px",
        height: "75px",
        // boxShadow: "4px 3px 3px #000000",
        borderRadius: "20px",
        border: "none",
        cursor: "pointer",
    },
    bgColor: "html { background-color: white; height: 100%; }",
}


const Template1:React.FC<ITemplate1Props> = ({lang, desc1, desc2, desc3, desc4, 
        codeText, codeQR, codeBar, referralUrl, cssProps}) => {

    const screenWidth = window.innerWidth 
    const [copyText, setCopyText] = useState(false)
    const [showImage, setShowImage] = useState(false)

    const changePage = () => { 
        window.open(referralUrl)
    }

    if(copyText){
        console.log(copyText)
    }

    const imageOn = () => {
        if(!showImage) {
            setShowImage(true)
        } else {
            setShowImage(false)
        }
    }  

    return (
        <>            
            <div style={{textAlign: "center"}}>
                {/* set background color for activity */}                
                <Helmet>
                    <style>{cssProps.bgColor}</style>
                </Helmet>               
                {screenWidth > 450 && <p style={cssProps.textStyle}> {desc1} </p>}
                {screenWidth < 450 && <p style={cssProps.mobileTextStyle}> {desc1} </p>}
                {screenWidth > 450 && <p style={cssProps.textStyle}> {desc2} </p>}
                {screenWidth < 450 && <p style={cssProps.mobileTextStyle}> {desc2} </p>}

                {codeText &&
                    <> 
                        <p style={{margin: "5px 0 5px 0"}}>
                        {screenWidth > 450 && 
                        <>                            
                            <CopyToClipboard text={codeText} onCopy={()=> setCopyText(true)} >
                                <button style={cssProps.codeStyle} onClick={() => toast.show({
                                    title: 'Code',
                                    content: "Copy code: " + codeText,
                                    duration: 1600,
                                })}> {codeText} </button>                        
                            </CopyToClipboard>                    
                        </>
                        }
                        
                        {screenWidth < 450 && 
                        <>                            
                            <CopyToClipboard text={codeText} onCopy={()=> setCopyText(true)} >
                                <button style={cssProps.mobileCodeStyle} onClick={() => toast.show({
                                    title: 'Code',
                                    content: "Copy code: " + codeText,
                                    duration: 1600,
                                })}> {codeText} </button>
                            </CopyToClipboard>
                        </>                
                        }                                
                    </p>
                    {screenWidth > 450 && <p style={cssProps.textStyle}> {desc3} </p>}
                    {screenWidth < 450 && <p style={cssProps.mobileTextStyle}> {desc3} </p>}
                    {screenWidth > 450 && lang === 'en-US' && <button onClick={changePage} style={cssProps.btnStyle}> Click Here </button>}
                    {screenWidth < 450 && lang === 'en-US' && <button onClick={changePage} style={cssProps.mobileBtnStyle}> Click Here </button>} 
                    {screenWidth > 450 && lang === 'zh-TC' && <button onClick={changePage} style={cssProps.btnStyle}> 點擊跳轉 </button>}
                    {screenWidth < 450 && lang === 'zh-TC' && <button onClick={changePage} style={cssProps.mobileBtnStyle}> 點擊跳轉 </button>}
                    {screenWidth > 450 && lang === 'zh-CN' && <button onClick={changePage} style={cssProps.btnStyle}> 点击跳转 </button>}
                    {screenWidth < 450 && lang === 'zh-CN' && <button onClick={changePage} style={cssProps.mobileBtnStyle}> 点击跳转 </button>} 
                    </>
                }

                {codeQR && 
                    <>
                        <div>                        
                            {screenWidth > 450 && <QRcode value={codeQR} style={{width: "320px", height: "320px"}} />}
                            {screenWidth < 450 && <QRcode value={codeQR} style={{width: "250px", height: "250px"}} />}                            
                        </div>
                        <div style={{margin: "0 10px 30px 10px"}}>
                            {screenWidth > 450 && <button style={cssProps.btnStyle} onClick={imageOn} > {desc3} </button>}
                            {screenWidth < 450 && <button style={cssProps.mobileBtnStyle} onClick={imageOn} > {desc3} </button>}
                            <div style={{marginTop: "15px"}} >
                                {lang === 'zh-TC' && showImage && <img src={require('../images/towngas_redeem_cn.jpg').default} alt="cn_version_image" width="95%" height="95%" />}
                                {lang === 'zh-CN' && showImage && <img src={require('../images/towngas_redeem_cn.jpg').default} alt="cn_version_image" width="95%" height="95%" />}
                                {lang === 'en-US' && showImage && <img src={require('../images/towngas_redeem_en.jpg').default} alt="en_version_image" width="95%" height="95%"/>}
                            </div>                            
                        </div> 
                    </>
                }

                {screenWidth > 450 &&  lang === 'zh-TC' && <p style={cssProps.rulesTitleStyle} > 優惠詳請及細則: </p>}
                {screenWidth < 450 && lang === 'zh-TC' && <p style={cssProps.mobileRulesTitleStyle} > 優惠詳請及細則: </p>}
                {screenWidth > 450 &&  lang === 'zh-CN' && <p style={cssProps.rulesTitleStyle} > 优惠详请及细则: </p>}
                {screenWidth < 450 && lang === 'zh-CN' && <p style={cssProps.mobileRulesTitleStyle} > 优惠详请及细则: </p>}
                {screenWidth > 450 &&  lang === 'en-US' && <p style={cssProps.rulesTitleStyle} > Terms and Conditions: </p>}
                {screenWidth < 450 && lang === 'en-US' && <p style={cssProps.mobileRulesTitleStyle} > Terms and Conditions: </p>}                
                {screenWidth > 450 && <p style={cssProps.rulesTextStyle}> - {desc4} </p>}
                {screenWidth < 450 && <p style={cssProps.mobileRulesTextStyle}> - {desc4} </p>}
            </div>
        </>
    )
}

export default Template1