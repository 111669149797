import React from "react";
import '../../css/ICGSFRiceDumpling.css'

const ICGSFRiceDumpling: React.FC = () => {
    return (
        <>
            <div className="ICGSRiceDumpling-title"> － 使用說明 － </div>
            <div className="ICGSRiceDumpling-termBox">
                <div className="ICGSRiceDumpling-terms"> 換領時間：中午12:00 至 晚上10:00 </div>
                <div className="ICGSRiceDumpling-terms"> 換領地點：香港九龍尖沙咀東部麽地道 70 號海景嘉福洲際酒店B2層 - 海景軒 </div>
            </div>
            

            <div className="ICGSRiceDumpling-title"> － 條款及細則 － </div>
            <div className="ICGSRiceDumpling-termBox">
                <div className="ICGSRiceDumpling-terms"> 食店牌照：2161002231 </div>
                <div className="ICGSRiceDumpling-terms"> 所有價目只適用於自提 </div>
                <div className="ICGSRiceDumpling-terms"> 不能與任何其他推廣優惠同時使用，每封兌換信只可使用一次 </div>
                <div className="ICGSRiceDumpling-terms"> 所有訂單需視乎供應情況而定及以確認付款後為準 </div>
                <div className="ICGSRiceDumpling-terms"> 優惠只可兌換一次，訂單確認後不接受退款、更改或取消 </div>
                <div className="ICGSRiceDumpling-terms"> 圖片只供參考 </div>
                <div className="ICGSRiceDumpling-terms"> 如有查詢，請致電 (852) 2731 2883 或電郵至 hoikingheen@icgrandstanford.com 與海景軒聯絡 </div>
            </div>
            
        </>
    )
}

export default ICGSFRiceDumpling;