import React, { useState } from 'react'
import { Helmet } from 'react-helmet';

const QRcode = require('qrcode.react');

interface ITemplate2Props {
    lang: string,
    desc1: string,
    desc2: string,
    desc3: string,
    desc4: string,
    code: string,
    cssProps: ITemplate2CssProps,
}

interface ITemplate2CssProps {
    textStyle: React.CSSProperties,
    mobileTextStyle: React.CSSProperties,
    imgBtnStyle: React.CSSProperties,
    mobileImgBtnStyle: React.CSSProperties,
    bgColor: string,
}

export const tgCssProps: ITemplate2CssProps = {
    textStyle: {
        fontSize: '30px',
        color: '#FFFFFF',
        margin: "15px 0 15px 0",
        padding: "10px 0 10px 0"
    },
    mobileTextStyle: {
        fontSize: '20px',
        color: '#FFFFFF',
        margin: "10px 0 10px 0",
        padding: "5px 0 5px 0"
    },
    imgBtnStyle: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: "25px",     
        width: "auto",
        height: "auto",
        padding: "15px 30px 15px 30px",
        margin: "0 10px 0 10px",
		display: "table-cell",
        verticalAlign: "middle",
        boxShadow: "4px 3px 3px #666666",
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
    },
    mobileImgBtnStyle: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: "15px",     
        width: "auto",
        height: "auto",
        padding: "10px 20px 10px 20px",
        margin: "0 5px 0 5px",
		display: "table-cell",
        verticalAlign: "middle",
        boxShadow: "4px 3px 3px #A0A0A0",
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
    },
    bgColor: "html { background-image: linear-gradient(#18A0CD,#114D88); height: 100% }",
}

const Template2:React.FC<ITemplate2Props> = ({lang, desc1, desc2, desc3, desc4, code, cssProps}) => {

    const [showImage, setShowImage] = useState(false)
    const screenWidth = window.innerWidth 

    const imageOn = () => {
        if(!showImage) {
            setShowImage(true)
        } else {
            setShowImage(false)
        }
    }    

    return (        
        <div style={{textAlign: "center"}}>            
            <Helmet>
                <style>{cssProps.bgColor}</style>
            </Helmet>
            {/* <p style={textStyle}> {desc1} </p> */}
                            
            {screenWidth > 450 &&  <p style={cssProps.textStyle}> {desc1} </p>}
            {screenWidth < 450 &&  <p style={cssProps.mobileTextStyle}> {desc1} </p>}             
            {screenWidth > 450 && <button style={cssProps.imgBtnStyle} onClick={imageOn} > {desc3} </button>}
            {screenWidth < 450 && <button style={cssProps.mobileImgBtnStyle} onClick={imageOn} > {desc3} </button>}  
        
            <div style={{margin: "30px 10px 30px 10px"}}>
                {lang === 'zh-TC' && showImage && <img src={require('../images/towngas_redeem_cn.jpg').default} alt="cn_version_image" width="90%" height="90%" />}
                {lang === 'en-US' && showImage && <img src={require('../images/towngas_redeem_en.jpg').default} alt="en_version_image" width="60%" height="60%"/>}
            </div>                
            {screenWidth > 450 && <p style={cssProps.textStyle}> {desc2} </p>}
            {screenWidth < 450 && <p style={cssProps.mobileTextStyle}> {desc2} </p>}                    
            {screenWidth > 450 && <QRcode value={code} style={{width: "320px", height: "320px"}} />}
            {screenWidth < 450 && <QRcode value={code} style={{width: "250px", height: "250px"}} />}            
            {screenWidth > 450 && <p style={cssProps.textStyle}> {desc4} </p>}
            {screenWidth < 450 && <p style={cssProps.mobileTextStyle}> {desc4} </p>}                          
        </div>        
    )
}

export default Template2